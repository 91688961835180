/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { MyContext } from '../context';
import * as qs from 'query-string';
export default class TnC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }
    render() {
        const {
            isMobile
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.terms_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.terms_description} />
                        </Helmet>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">{context.state.language == "en" ? 'TERMS AND CONDITIONS' : 'الأحكام والشروط'}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                {
                                    context.state.language == "en" ?
                                        <div className="static-page-wrap">
                                            <p>By registering to participate in the Kooora Fantasy Fantasy (the "Game") you agree to be bound by and to abide by the following terms and conditions.</p>
                                            <b>Applications and Registration</b>
                                            <p>1. All applications to participate in the Game must be made online on Kooora Fantasy Fantasy website at www.alharrif.com(the "Site") or via the Kooora Fantasy Fantasy mobile application (the "App") and strictly in accordance with the relevant instructions. No applications made in any other manner will be accepted. Where your application is accepted, you have successfully "Registered" and will become a "Player" of the Game. Each Player is then able to create a team or mini-league.</p>
                                            <p>2. For the avoidance of doubt, all decisions in respect of the Game (including conditions of application or Registration, team or league names, deadlines within the Game, winners of Prizes or the Game Rules (as defined below)), shall be at the Kooora Fantasy Fantasy absolute discretion. All such decisions will be final and no correspondence will be entered into.</p>
                                            <p>3. Registration for the Game is open to both Saudi Arabia and non-Saudi Arabian residents. Employees (and immediate family members) of  (the " Kooora Fantasy Fantasy ") may enter the Game but are ineligible to win any Prizes (as defined below). If such person would otherwise win a Prize, the Prize shall be awarded to the next placed Player.</p>
                                            <p>4. Under 18s may Register to play the Game, however the consent of a parent or guardian is required. The eligibility of an under 18 to win a Prize is set out below</p>
                                            <p>5. No purchase of any item or service is necessary in order to enter the Game and no payment is required from you.</p>
                                            <p>6. Registrations must not be made by agents or third parties. No responsibility can be accepted for lost, delayed or incomplete Registrations or Registrations not received by the Kooora Fantasy Fantasy for any reason. Any such Registrations will be deemed void.</p>
                                            <p>7. There is no limit on the number of Registrations per household or organisation. However, only one Registration in to the Game per unique registered user of the Site or App is permitted. Individuals are not permitted to register multiple accounts on the Site or App.	</p>
                                            <p>8. While there is no closing date for Registration, and applications can be made throughout the Kooora Fantasy season, the Kooora Fantasy Fantasy will publish deadlines from time to time by which you must be Registered as a Player in order to qualify for points awarded in respect of any round of matches in the Kooora Fantasy Fantasy competition. Any such information on how and when to enter for the Game forms part of these terms and conditions and shall be binding on you.</p>
                                            <p>9. The Kooora Fantasy Fantasy reserves the right to suspend and delete Registrations that contain team or league names which are deemed to be inappropriate or offensive. Depending on the seriousness of the situation, the Player's account may be deleted in its entirety without notice. Any such decision to do so by the Kooora Fantasy Fantasy is final. Alternatively, the Kooora Fantasy Fantasy may reject such a name and require a new one to be selected within 7 days. A failure to do so, or in the event another inappropriate or offensive name is selected, the Player will be held to be in breach of these terms and conditions.</p>
                                            <p>10. In submitting a Registration and becoming a Player, you will be providing personal data to the Kooora Fantasy Fantasy. Any personal data which you do submit will be processed in accordance with the Kooora Fantasy Fantasy Privacy Policy, which can be found here: https://www.alharrif.com/privacy-policy.</p>
                                            <p>11. We will only share your data in accordance with our Privacy Policy and as set out in these terms and conditions. Where a Player is a winner of a Prize, the winner's name may be published on the Site or App in accordance to Kooora Fantasy Fantasy Privacy Policy.</p>
                                            <p>12. Both Saudi Arabia and non-Saudi Arabian Players Registering for the Game are subject to their teams being automatically entered into a "Country League". A Country League is a mini-league named after the country entered by the Player when submitting a Registration. In association with the  Kooora Fantasy Fantasy broadcaster in selected territories, the Kooora Fantasy Fantasy may choose (but will not guarantee) to offer a Prize to the overall winner of such Country Leagues. The nature of the Prize and the countries selected for this purpose are at the sole discretion of the Kooora Fantasy Fantasy. Entry into a Country League is automatic but participation in no way obligates the Player to receive marketing communications from the Kooora Fantasy Fantasy or broadcaster unless otherwise indicated during Registration to the Site or App.</p>
                                            <p>13. Players (and Players on behalf of others and organisations) can establish mini-leagues in accordance with the Game Rules. The Kooora Fantasy Fantasy does not condone Players charging for entry to mini-leagues they have created, strongly encourages Players not to join such leagues and cannot be held responsible for their management or any loss suffered through Players’ involvement in such leagues.</p>

                                            <b>The Game Rules</b>
                                            <p>14. The Game will be governed by the Game Rules (as amended from time to time) published on the Site (and accessible via the App) by the Kooora Fantasy Fantasy ("Game Rules"). The Kooora Fantasy  Fantasy reserves the right to alter, amend or supplement the Game Rules in its absolute discretion in the event of any circumstances beyond its reasonable control arising or where it considers it desirable to do so. You agree that no liability shall attach to the Kooora Fantasy Fantasy as a result of any such change and so are advised to check the Game Rules regularly. The Game Rules form part of these terms and conditions and by Registering for the Game, you accept the Game Rules.</p>
                                            <p>15. The Kooora Fantasy Fantasy will be the sole decision-maker of any matter of interpretation of the Game Rules and any aspect of the content of, or playing of, the Game. The Kooora Fantasy Fantasy will not enter into correspondence relating to such matters and its decision as to any matter arising out of or in connection with the Game Rules including but not limited to the allocation of points to any Player and/or the award of any Prize and/or any ranking or league table shall be final and conclusive.</p>

                                            <b>Prizes and Winner's Details</b>
                                            <p>16. The Kooora Fantasy Fantasy may choose to award prizes in respect of the Game ("Prizes"). Such Prizes will be available at the discretion of the Kooora Fantasy Fantasy and in accordance with criteria set by it. The Kooora Fantasy Fantasy reserves the right to alter and amend the Prizes or the criteria for winning a Prize, where circumstances beyond its reasonable control require it to do so.</p>
                                            <p>17. Prizes are not transferable and are non-exchangeable. Cash as an alternative will not be offered in any circumstances. The Kooora Fantasy Fantasy will make reasonable endeavours to accommodate any needs or requirements of Prize recipients and where a Prize includes travel or attendance at an event, the Kooora Fantasy Fantasy will seek to agree dates with the recipient. However, where the recipient is unavailable on the dates provided by the Kooora Fantasy Fantasy, the Prize may be forfeited, or another recipient selected.</p>
                                            <p>18. Subject to compliance with these terms and conditions, all Players (excluding those listed in terms 3) are eligible to win the Prizes. However, where a Player is under the age of 18, they will not be eligible to win any cash element of a Prize (if applicable) and parental or guardian consent must be obtained. Such consent must include confirmation that the parent/guardian consented to the Player's Entry to the Game, they consent to the Player being awarded a Prize and will accompany the Player during any travel connected to the Prize.</p>
                                            <p>19. The Kooora Fantasy Fantasy intends (but does not undertake) for the following Prizes to be available:</p>
                                            <p>20. The first Prize ("Winner's Prize") will be awarded to the Player who, according to the Kooora Fantasy Fantasy determination, is at the top of the leaderboard published by the Kooora Fantasy Fantasy at the end of the season.</p>
                                            <p>21. The second Prize ("Runner-up Prize") will be awarded to the Player who according to the Kooora Fantasy Fantasy determination finishes in second place in the overall leaderboard published by the Kooora Fantasy Fantasy at the end of the season.</p>
                                            <p>22. The third Prize ("Third Prize") will be awarded to the Player who according to the Kooora Fantasy Fantasy determination finishes in third place in the overall leaderboard published by the Kooora Fantasy Fantasy at the end of the season.</p>
                                            <p>23. Additionally, there will be a "Monthly Prize" awarded to the Player who according to the Kooora Fantasy Fantasy determination is at the top of the monthly leaderboard published by the Kooora Fantasy Fantasy at the end of each monthly period during the season. The exact timing of when to select a winner and award this Prize is at the discretion of the Kooora Fantasy Fantasy.</p>
                                            <p>24. The Kooora Fantasy Fantasy may also choose to use Players' names or team names for future promotional, marketing and publicity purposes of the Kooora Fantasy Fantasy or the Game in any and all media worldwide without notice to them and without any fee being paid. If you have an objection to the use of your name or team, please contact support@alharrif.com.</p>
                                            <p>25. The Kooora Fantasy Fantasy may wish to use the name and photograph of winners for promotional, marketing and publicity purposes in any and all media worldwide. The Kooora Fantasy Fantasy will contact winners to request their consent to their image being used. Where a Player is under 18, parental consent will also be required.</p>
                                            <b>Responsibility and Remedies</b>
                                            <p>26. The extent of the Kooora Fantasy Fantasy responsibility to you has been determined in the context of the following:</p>
                                            <ul className='no-list'>
                                                <li>a) the Game is provided to you free of charge;</li>
                                                <li>b) you are responsible for any action you do or do not take as a result of the Game and the information therein;</li>
                                                <li>c) you are responsible for ensuring that your equipment is enabled with appropriate up-to-date virus checking software.</li>
                                            </ul>
                                            <p>27. While the Kooora Fantasy  Fantasy will endeavour to ensure that the Game is available to you at all times and that the contents are correct and accurate, it cannot make any legal commitment to you that this will be the case. However, the Kooora Fantasy  Fantasy will exercise reasonable skill and care in providing any service to you.</p>
                                            <p>28. The Kooora Fantasy  Fantasy cannot accept any liability to you for any of the following types of loss which you may suffer as a result of your entry to the Game:</p>

                                            <ul className='no-list'>
                                                <li>a) loss which was not foreseeable to you and the Kooora Fantasy  Fantasy when you first applied for the Game (even if that loss results from the Kooora Fantasy  Fantasy failure to comply with these terms or its negligence);</li>
                                                <li>b) any business loss you may suffer, including loss of revenue, profits, or anticipated savings (whether those losses are the direct or indirect result of the Kooora Fantasy  Fantasy default);</li>
                                                <li>c) loss which you suffer other than as a result of our failure to comply with these terms or our negligence or breach of statutory duty;</li>
                                                <li>d) any loss which you may suffer as a result of or in connection with or arising out of any Prize.</li>
                                            </ul>

                                            <p>29. Nothing in these terms will limit the Kooora Fantasy  Fantasy liability for death or personal injury arising from its negligence or if they deliberately lied to you before you entered.</p>
                                            <p>30. Any breach by you of the Game Rules from time to time shall also be a breach of these terms and conditions.</p>
                                            <p>31. In the event of any breach by you of these terms and conditions the Kooora Fantasy  Fantasy reserves the right to:</p>
                                            <ul className='no-list'>
                                                <li>a) refuse entry to the Game;</li>
                                                <li>b) disqualify you from the Game;</li>
                                                <li>c) require any reasonable amendment to your application or entry.</li>
                                            </ul>
                                            <p>32. If you are barred or disqualified from being Registered as a Player, you shall not be eligible to participate in the Game under any other identity or team name. Any such decision by the Kooora Fantasy  Fantasy shall be final.</p>
                                            <p>33. Apple shall not be responsible for any claims by you or any third party relating to the application or your possession and/or use of the application.</p>
                                            <p>34. These Terms of Use are between you and Kooora Fantasy, and not with Apple. You acknowledge that Kooora Fantasy, and not Apple, is solely responsible for the Application and the content thereof, subject to the terms and conditions of these Terms. You agree that Apple and Apple’s subsidiaries are third party beneficiaries of these Terms and that upon your acceptance of the Terms of Use, Apple and its subsidiaries will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary hereof. You agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to the Terms.</p>
                                            <p>35. Through the downloading, installation and/or use of the Application, you are acquiring and Kooora Fantasy grants you a personal, limited, non-exclusive, and non-transferrable license to install and use the Application for your non-commercial use on a single, authorized mobile device solely as set forth in this License. If you download the Application from the Apples iTunes Store, you may install and use the Application only on an Apple, Inc. (“Apple”) device that runs the iOS operating system and that you own or control solely as permitted by the Usage Rules set forth in the Apple iTunes Store Terms and Conditions. Your acquired license rights are subject to your compliance with this License and these Terms. Any commercial use is prohibited. You are expressly prohibited from sub-licensing, renting, leasing, transferring or otherwise distributing the Application or rights to use the Application. The term of your license shall commence on the date that you download, install or otherwise use the Application, and shall end on the earlier of the date that you dispose of the Application or Kooora Fantasy terminates this License.</p>
                                            <p>36. Apple and any of Apple’s affiliates are not a sponsor of, or responsible for conducting, the promotion.</p>
                                            <p>37. If any of these terms are determined to be illegal, invalid, or otherwise enforceable then the remaining terms shall continue in full force and effect.</p>
                                        </div>
                                        :
<div className="static-page-wrap">
<p>من خلال الاشتراك في " Kooora Fantasy Fantasy " ( اللعبة )  فإنك توافق على الالتزام بالشروط والأحكام التالية.</p>
<b>التطبيقات والتسجيل</b>
<p>1. يجب نشر جميع طلبات الألعاب عبر الإنترنت على موقع Kooora Fantasy Fantasy على www.alharrif.com ("الموقع") أو من خلال تطبيق Kooora Fantasy Fantasy للهواتف المحمولة ("التطبيق") والتعليمات ذات الصلة بشكل صارم. لن يتم قبول أي طلبات بأي طريقة أخرى. إذا قبلت طلبك ، سيتم إكمال التسجيل بنجاح وستصبح "لاعبًا" في اللعبة. كل لاعب قادر على إنشاء فريق أو دوري صغير.</p>
<p>2. من أجل تجنب الشك ، جميع القرارات المتعلقة باللعبة (بما في ذلك شروط التطبيق ، التسجيل ، أسماء الفرق ، الدوريات ، المواعيد النهائية داخل اللعبة ، الفائزين بالجوائز أو قواعد اللعبة " كما هو موضح أدناه" ) فأنه يجب ان يكون لـ Kooora Fantasy Fantasy مطلق الحرية . جميع هذه القرارات ستكون نهائية ولن يتم تلقي أي مراسلات .</p>
<p>3. التسجيل في اللعبة مفتوح لكل من السعوديين ، و المقيمين الغير سعوديين . يجوز للمستخدمين (وأعضاء العائلة المباشرين) من " Kooora Fantasy Fantasy " الدخول إلى اللعبة ولكنهم غير مؤهلين للفوز بأي جوائز . فإذا فاز هذا الشخص بجائزة ، فسيتم منح الجائزة للاعب التالي.</p>
<p>4. يجوز للأشخاص الذين تقل أعمارهم عن 18 عامًا التسجيل للعب اللعبة ، ولكن يجب الحصول على موافقة الوالد أو الوصي. يتم تحديد الأهلية للأشخاص دون سن 18 عامًا للفوز بالجائزة أدناه</p>
<p>5. ليس من الضروري شراء أي سلع أو خدمات من أجل دخول اللعبة ولا يتطلب منك دفع أي مبلغ.</p>
<p>6. لا يجب أن يتم التسجيل من قبل الوكلاء أو الغير . لا يوجد أي مسؤولية عن أي تسجيلات أو تسجيلات مفقودة أو مؤجلة أو غير مكتملة لم تتلقها Kooora Fantasy Fantasy لأي سبب من الأسباب . تعتبر أيا من هذه التسجيلات غير صالحة .</p>
<p>7. لا يوجد حد لعدد التسجيلات لكل أسرة أو منظمة. ومع ذلك ، يتم السماح بتسجيل واحد فقط في اللعبة لكل مستخدم مسجل في الموقع أو التطبيق. لا يُسمح للأفراد بتسجيل حسابات متعددة على الموقع أو التطبيق..</p>
<p>8. في الوقت الذي لا يوجد فيه موعد للتسجيل ، ويمكن تقديم الطلبات طوال الموسم ، ستقوم Kooora Fantasy Fantasy بنشر مواعيد نهائية من وقت لآخر ، ويجب تسجيلها كلاعب للتأهل للنقاط الممنوحة لأي جولة في المسابقة. أي معلومات مثل كيفية ووقت دخول اللعبة جزء من هذه الشروط والأحكام تكون ملزمة لك.</p>
<p>9. يحتفظ Kooora Fantasy Fantasy بالحق في تعليق وحذف التسجيلات التي تحتوي على أسماء الفرق أو الدوريات التي تعتبر غير مناسبة أو مسيئة. تبعا لخطورة الوضع، قد يتم حذف حساب لاعب في مجملها دون سابق إنذار. أي قرار من هذا القبيل من قبل Kooora Fantasy Fantasy هو قرار نهائي . بدلاً من ذلك ، قد يرفض Kooora Fantasy Fantasy مثل هذا الاسم ويتطلب اختيار واحد جديد خلال 7 أيام. إن فشل في القيام بذلك ، أو في حالة اختيار اسم آخر غير مناسب أو مسيء ، سيعتبر اللاعب مخالفاً لهذه الشروط والأحكام.</p>
<p>10. عند تقديم التسجيل وتصبح لاعباً ، ستقوم بإرسال بياناتك الشخصية إلى Kooora Fantasy Fantasy. ستتم معالجة أي بيانات شخصية ترسلها وفقًا لسياسة خصوصية سياسة العميل ، والتي يمكن العثور عليها هنا: https://www.alharrif.com/privacy-policy .</p>
<p>11. سنقوم بمشاركة بياناتك فقط وفقًا لسياسة الخصوصية الخاصة بنا وكما هو موضح في هذه الشروط والأحكام. عندما يكون اللاعب الفائز في جائزة ، قد يتم نشر اسم الفائز على الموقع أو التطبيق وفقا لـ Kooora Fantasy Fantasy. </p>
<p>12. يخضع كل من اللاعبين السعوديين وغير السعوديين الذين يسجلون في اللعبة ان تدخل فرقهم تلقائياً في "دوري البلد". دوري البلد هو في الدوري المصغر الذي سمي على اسم البلد الذي تم إدخاله من قبل المستخدم عند تقديم التسجيل. بالتعاون مع مذيع " Kooora Fantasy Fantasy " في مناطق مختارة ، قد يختار " Kooora Fantasy Fantasy " (و لكن لا نضمن ذلك) تقديم جائزة للفائز العام بمثل هذه الدوريات الوطنية. طبيعة الجائزة والدول التي اختيرت لهذا الغرض هي في التقدير المطلق لشركة " Kooora Fantasy Fantasy". الدخول إلى Country League هو أمر تلقائي ، لكن المشاركة لا تلزم اللاعب بأي حال من الأحوال بتلقي الاتصالات التسويقية من Al Friary Fantasy أو هيئة الإذاعة ما لم يذكر غير ذلك أثناء التسجيل في الموقع أو التطبيق.</p>
<p>13. يمكن للاعبين (واللاعبين نيابة عن الآخرين والمنظمات) إنشاء اتحادات مصغرة وفقًا لقواعد اللعبة. لا يتغاضى Kooora Fantasy Fantasy عن اللاعبين الذين يدفعون رسومًا مقابل الدخول إلى الدوريات المصغرة التي قاموا بإنشائها ، ويشجعون بقوة اللاعبين على عدم الانضمام إلى مثل هذه البطولات ولا يمكن تحميلهم المسؤولية عن إدارتها أو أي خسارة تتعرض لها مشاركة اللاعبين في مثل هذه البطولات.</p>

<b>قواعد اللعبة</b>
<p>14. تخضع اللعبة للقواعد (كما يتم تعديلها من وقت لآخر) والتي يتم نشرها على الموقع (ويمكن الوصول إليها عبر التطبيق) من قبل Kooora Fantasy Fantasy ("Game Rules"). تحتفظ Kooora Fantasy Fantasy بالحق في تغيير أو تعديل أو استكمال قواعد اللعبة وفقًا لتقديرها المطلق في حالة حدوث أي ظروف خارجة عن سيطرتها أو عندما ترى أنها مرغوبة للقيام بذلك. أنت توافق على أنه لا توجد أية مسؤولية يجب أن ترتبط بـ " Kooora Fantasy Fantasy " نتيجة لأي تغيير من هذا القبيل ، لذا ننصح بمراجعة قواعد اللعبة بانتظام. تشكل قواعد اللعبة جزءًا من هذه الشروط والأحكام ومن خلال التسجيل في اللعبة ، فإنك تقبل قواعد اللعبة.</p>
<p>15. سوف يكون Kooora Fantasy Fantasy صانع القرار الوحيد لأي مسألة تتعلق بتفسير قواعد اللعبة وأي جانب من جوانب محتوى اللعبة أو لعبها. لن يدخل Kooora Fantasy Fantasy في مراسلات تتعلق بمثل هذه الأمور وقراره بشأن أي مسألة تنشأ عن أو تتعلق بقواعد اللعبة بما في ذلك على سبيل المثال لا الحصر توزيع النقاط على أي لاعب و / أو منح أي جائزة و / أو أي جدول ترتيب أو دوري يجب أن يكون نهائيًا وقاطعًا.
الجوائز وتفاصيل الفائز</p>
<p>16. قد تختار مؤسسة Kooora Fantasy Fantasy منح جوائز فيما يتعلق باللعبة . ستكون هذه الجوائز متاحة حسب تقدير مؤسسة Kooora Fantasy Fantasy وفق المعايير الموضوعة من قبلها. يحتفظ Kooora Fantasy Fantasy بالحق في تغيير وتعديل الجوائز أو معايير الفوز بجائزة ، حيث تتطلب ظروف خارجة عن سيطرته القيام بذلك.</p>
<p>17. لا يمكن تحويل الجوائز ولا يمكن استبدالها. لا يمكن الحصول على بديل نقدي للجوائز الغير مادية في أي ظرف من الظروف. سوف تبذل مؤسسة Kooora Fantasy Fantasy مساعي معقولة لاستيعاب أي احتياجات أو متطلبات للمتلقين من الجوائز وحيث تشمل الجائزة السفر أو الحضور في حدث ما ، سوف يسعى Kooora Fantasy Fantasy إلى توافق التواريخ مع المتلقي. ومع ذلك ، عندما يكون المتلقي غير متاح في التواريخ التي يقدمها Kooora Fantasy Fantasy ، قد يتم مصادرة الجائزة أو تحديد مستلم آخر .</p>
<p>18. مع مراعاة الامتثال لهذه الشروط والأحكام ، فإن جميع اللاعبين (باستثناء هؤلاء المدرجين في الشرط 3 )  مؤهلون للفوز بالجوائز. ومع ذلك ، إذا كان عمر اللاعب أقل من 18 عامًا ، فلن يكون مؤهلاً للفوز بأي عنصر نقدي في الجائزة (إن وجد) ويجب الحصول على موافقة الوالدين أو الوصي. يجب أن تتضمن هذه الموافقة تأكيدًا على موافقة ولي الأمر / الوصي على دخول اللاعب إلى اللعبة ، وأنهم يوافقون على منح اللاعب جائزة وانه سيتم مرافقة اللاعب أثناء أي رحلة متصلة بالجائزة.</p>
<p>19. Kooora Fantasy Fantasy تنوي (ولكن لا تتعهد) أن تكون الجوائز التالية متاحة:</p>
<p>20. الجائزة الأولى ("جائزة الفائز") ستُمنح للاعب الذي ، وفقاً لقرار " Kooora Fantasy Fantasy " ، يحتل مكان الصدارة في لائحة المتصدرين التي نشرها " Kooora Fantasy Fantasy " في نهاية الموسم.</p>
<p>21. الجائزة الثانية ("جائزة الوصيف") ستمنح للاعب الذي حسب تقرير " Kooora Fantasy Fantasy " ينهي في المركز الثاني في المتصدرين العام الذي نشره " Kooora Fantasy Fantasy " في نهاية الموسم.</p>
<p>22. سيتم منح الجائزة الثالثة ("الجائزة الثالثة") للاعب الذي حسب تقرير " Kooora Fantasy Fantasy " الذي يحتل المركز الثالث في قائمة المتصدرين الشاملة التي نشرها " Kooora Fantasy Fantasy " في نهاية الموسم.</p>
<p>23. بالإضافة إلى ذلك ، سيكون هناك "جائزة شهرية" تُمنح للاعب الذي يكون وفقاً للقرار " Kooora Fantasy Fantasy " في أعلى لائحة المتصدرين الشهرية التي تنشرها مؤسسة " Kooora Fantasy Fantasy " في نهاية كل فترة شهرية خلال الموسم.  و إن التوقيت المحدد لموعد اختيار فائز ومنح هذه الجائزة هو بحسب تقدير مؤسسة Kooora Fantasy Fantasy .</p>
<p>24. قد يختار Kooora Fantasy Fantasy أيضًا استخدام أسماء اللاعبين أو أسماء الفريق في أغراض ترويجية أو تسويقية أو ترويجية مستقبلية لـ " Kooora Fantasy Fantasy " أو "اللعبة" في جميع وسائل الإعلام في جميع أنحاء العالم دون إخطارهم ودون دفع أي رسوم. إذا كان لديك اعتراض على استخدام اسمك أو فريقك ، فيرجى الاتصال بـ support@alharrif.com.</p>
<p>25. قد ترغب " Kooora Fantasy Fantasy " في استخدام اسم وصورة الفائزين لأغراض الترويج والتسويق والدعاية في جميع وسائل الإعلام في جميع أنحاء العالم. سوف يتصل Kooora Fantasy Fantasy بالفائزين لطلب موافقتهم على استخدام صورتهم. إذا كان عمر اللاعب أقل من 18 عامًا ، فستكون موافقة الوالدين مطلوبة أيضًا.</p>

<b>المسؤولية والتعويضات</b>
<p>26. لقد تم تحديد مدى مسؤولية Kooora Fantasy Fantasy في سياق ما يلي:</p>
<ul className='no-list'>
    <li>أ) تقدم اللعبة لك مجاناً ؛</li>
    <li>ب) أنت مسؤول عن أي إجراء تقوم به أو تتخذه نتيجة للعبة والمعلومات الموجودة بها ؛</li>
    <li>ج) أنت مسؤول عن التأكد من أن أجهزتك مفعّلة بأحدث برامج فحص الفيروسات.</li>
</ul>

<p>27. في الوقت الذي يسعى فيه Kooora Fantasy Fantasy إلى ضمان إتاحة اللعبة لك في جميع الأوقات وأن تكون المحتويات صحيحة ودقيقة ، فإنه لا يمكن أن يقدم أي التزام قانوني لك للقيام بذلك. ومع ذلك ، فإن Kooora Fantasy Fantasy سوف تقدم كل الاهتمام  و المهارة في تقديم الخدمة لك .</p>
<p>28. لا يمكن لـ Kooora Fantasy Fantasy تحمل أي مسؤولية تجاهك عن أي نوع من أنواع الخسائر التالية التي قد تعاني منها نتيجة لدخولك إلى اللعبة:</p>
<ul className='no-list'>
    <li>أ) الخسارة التي لم تكن متوقعة بالنسبة لك و " Kooora Fantasy Fantasy " عندما تقدمت أولاً بطلب للحصول على اللعبة (حتى إذا كانت تلك الخسارة ناتجة عن إخفاق Kooora Fantasy Fantasy في الامتثال لهذه الشروط أو إهمالها ) ؛</li>
    <li>ب) أي خسارة تجارية قد تتعرض لها ، بما في ذلك خسارة الإيرادات أو الأرباح أو المدخرات المتوقعة (سواء كانت تلك الخسائر نتيجة مباشرة أو غير مباشرة لـ Kooora Fantasy Fantasy ) ؛</li>
    <li>(ج) الخسارة المتكبدة بخلاف عدم الامتثال لهذه الشروط أو الإهمال أو الإخلال بالواجب القانوني ؛</li>
    <li>د) أي خسارة قد تتكبدها نتيجة أو فيما يتعلق أو الناشئة عن أي جائزة..</li>
</ul>

<p>29. لا يوجد في هذه الشروط ما يعفي Kooora Fantasy Fantasy من المسؤولية  عن الوفاة أو الإصابة الشخصية الناجمة عن الإهمال أو إذا كذبت عمدا قبل الدخول.</p>
<p>30. أي خرق من جانبك لقواعد اللعبة من وقت لآخر يكون أيضًا خرقًا لهذه الشروط والأحكام.</p>
<p>31. في حالة حدوث أي خرق من جانبك لهذه الشروط والأحكام ، يحتفظ Kooora Fantasy Fantasy بالحق في:</p>
<ul className='no-list'>
    <li>أ) رفض الدخول إلى اللعبة ؛</li>
    <li>ب) استبعادك من اللعبة ؛</li>
    <li>ج) تتطلب أي تعديل معقول على طلبك أو دخولك.</li>
</ul>

<p>32. إذا تم منعك أو عدم أهليتك من التسجيل كمشغل ، فلن تكون مؤهلاً للمشاركة في اللعبة تحت أي هوية أو اسم فريق آخر. أي قرار من قبل Kooora Fantasy Fantasy يجب أن يكون نهائياً.</p>
<p>33. لن تكون Apple مسؤولة عن أي مطالبات تقدمها أنت أو أي طرف ثالث يتعلق بالتطبيق أو امتلاكك و / أو استخدام التطبيق.</p>
<p>34. شروط الاستخدام هذه بينك وبين شركة الحرّيف وليس مع شركة Apple. وانك تقر بأن الحريف ، وليس Apple، هو المسؤول الوحيد عن التطبيق ومحتواه، وفقًا لشروط وأحكام هذه الشروط. أنت توافق على أن Apple و الشركات التابعة ل  Appleهي مستفيدة من هذه الشروط من طرف ثالث وأنه عند قبولك لشروط الاستخدام ، سيكون لشركة Apple والشركات التابعة لها الحق (وسيُعتبر موافقًا على هذا الحق) لفرض هذه الشروط عليك. كمستفيد طرف ثالث من هذا. أنت توافق على أنه، باستثناء ما هو منصوص عليه صراحةً في هذه الشروط، لن يكون هناك أطراف ثالثة مستفيدة من هذه الشروط.</p>
<p>35. . من خلال تنزيل التطبيق و / أو تثبيته و / أو استخدامه ، فإنك تحصل على ، ويمنحك Kooora Fantasy ترخيصًا شخصيًا ومحدودًا وغير حصري وغير قابل للتحويل لتثبيت التطبيق واستخدامه بشكل غير تجاري على جهاز محمول واحد فقط على النحو المنصوص عليه في هذا الترخيص. إذا قمت بتنزيل التطبيق من Apples iTunes Store ، فيجوز لك تثبيت التطبيق واستخدامه فقط على جهاز Apple الذي يقوم بتشغيل نظام التشغيل iOS والذي تملكه أو تتحكم فيه فقط وفقًا لما تسمح به قواعد الاستخدام المنصوص عليها في شروط وأحكام متجر Apple iTunes. حقوق الترخيص المكتسبة تخضع لامتثالك لهذا الترخيص وهذه الشروط. يحظر أي استخدام تجاري. يُمنع صراحةً من الترخيص من الباطن أو التأجير أو التأجير أو نقل أو توزيع التطبيق أو حقوق استخدام التطبيق. تبدأ مدة الترخيص في تاريخ تنزيل التطبيق أو تثبيته أو استخدامه بطريقة أخرى ، وتنتهي في وقت مبكر من التاريخ الذي تتخلص فيه من التطبيق أو ينهي Kooora Fantasy هذا الترخيص.</p>
<p>36. لا تعد Apple وأي من الشركات التابعة لـ Apple راعية أو مسؤولة عن إجراء الترويج لهذا التطبيق.</p>
<p>37. اذا تم إعتبار أي من هذه الشروط بأنها غير قانونية أو غير صالحة أو قابلة للإنفاذ بأي طريقة أخرى ، فإن الشروط المتبقية ستظل سارية المفعول بالكامل .</p>
</div>
                                }
                            </Col>
                        </Row>
                    </div>

                )}
            </MyContext.Consumer>
        )
    }
}