import React, { Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { MyContext } from '../context';
import Img from '../components/images';

export default class ThankyouPickTeam extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false
        };
        console.log(this.props)
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleHide = () => {
        this.setState({ show: false });
    }

    getValidationState() {
        const length = this.state.FirstName.length;
        if (length > 10) return 'success';
        else if (length > 5) return 'warning';
        else if (length > 0) return 'error';
        return null;
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <Modal
                        show={this.props.ThankyouPickTeamModalShow}
                        onHide={() => this.props.modalHide('ThankyouPickTeamModalShow')}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body>
                            <div className="modal-header no-padding border-0">
                                <button className="close thanks-close" onClick={() => this.props.modalHide('ThankyouPickTeamModalShow')}><span aria-hidden="true">×</span></button>
                            </div>
                            
                            <div className="thank-content pickteam-content">
                                <figure className="graphic graphic-team">
                                    <img className="img-fluid" src={Img.DARK_KSAF_EN_LOGO} alt="" />
                                </figure>
                                {console.log(context.state.language !== "en")}
                                {
                                    context.state.language !== "en" ?
                                    <Fragment>
                                        <div className="title">شكرا لتسجيلك!</div>
                                        <div className="text-muted m-b-sm">يمكنك الآن تشكيل فريقك  واختيار لاعبيك المفضلين</div>
                                        <div className="text-muted">يرجى العلم بأن قائمة اللاعبين سوف تستمر في التحديث حتى بضعة أيام قبل بدء الدوري عندما يتم إغلاق باب الانتقالات.</div>
                                        <div className="text-muted alharrif-text m-t-20">كووورة فانتازي</div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div className="title">Thank you for registering!</div>
                                        <div className="text-muted m-b-sm">You can now build your team by choosing your favorite players.</div>
                                        <div className="text-muted">Please note that the players list will keep updating until a few days before the start of the league when the players transfers are closed in the league.</div>
                                        <div className="text-muted">Good luck!</div>
                                        <div className="text-muted alharrif-text m-t-20">Kooora Team</div>
                                    </Fragment>
                                }
                                

                                {/* <div className="text-muted m-t-20">
                                    <span className="for-more-details">{context.state.locale.ThankyouPickTeam.more_details}</span>
                                    <div className="support-alharrif">{context.state.locale.ThankyouPickTeam.more_details_contact}</div>
                                </div>

                                <div className="text-muted m-t-md">
                                    <span className="for-more-details">{context.state.locale.ThankyouPickTeam.team}</span>                                
                                    <div className="text-muted alharrif-text">{context.state.locale.ThankyouPickTeam.alharrif}</div>
                                </div> */}

                            </div>

                        </Modal.Body>
                        <Modal.Footer className='custom-modal-footer'>
                            {/* <Button bsStyle="primary" onClick={() => this.props.history.replace('/my-team')}>{context.state.locale.ThankyouPickTeam.Close_text}</Button> */}
                            <Button bsStyle="primary" onClick={() => this.props.modalHide('ThankyouPickTeamModalShow')}>{context.state.locale.ThankyouPickTeam.Close_text}</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}