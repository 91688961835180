/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */

import React, { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Img from '../components/images'
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Select from 'react-select';
import LeagueModal from '../Modals/league-modal';
import RosterLoader from '../views/RosterLoader';
import { notify } from 'react-notify-toast';
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import AdsContainer from '../components/AdsContainer';
import SimpleImageSlider from "ac-react-simple-image-slider";
import Images from '../components/images';
const LeagueType = [
    { value: 'all_league', label: 'All Leagues' },
    { value: 'global_league', label: 'Global Leagues' },
    { value: 'other_league', label: 'Private Leagues' },
    { value: 'active_monthly_league', label: 'Past Monthly Leagues' },
    { value: 'completed_monthly_league', label: 'Completed Special Leagues' },
];
var durationForBannerUpper = 8;
export default class Leagues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedLeague: LeagueType[0],
            LeagueModalShow: false,
            isLoaderShow: false,
            week_info: JSON.parse(localStorage.getItem('week_info')),
            PrivateLeaderboardList: [],
            GlobalLeaderboardList: [],
            MonthlyLeaderboardList: [],
            LeaderboardListArr: [],
        }
    }

    // API FATCH
    getLeaderboardList = (type) => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
        }
        let _API;
        let BoardState;
        switch (type) {
            case 1:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;

            case 2:
                _API = API.GET_USER_LEADERBOARD_LIST_GLOBAL;
                BoardState = 'GlobalLeaderboardList';
                break;

            case 3:
                _API = API.GET_ALL_MONTHLY_LEAGUE_LIST;
                BoardState = 'MonthlyLeaderboardList';
                break;

            default:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;
        }

        WSManager.Rest(_API, param).then((responseJson) => {
            this.setState({ isLoaderShow: false })
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                if (type == 3) {
                    this.setState({
                        [BoardState]: responseJson.monthly_standing
                    })
                } else {
                    this.setState({
                        [BoardState]: responseJson
                    })
                }
            }
        })

    }

    // Utility
    getLeagueCategoryName = (key, context) => {
        _.map(LeagueType, (item) => {

            switch (item.value) {
                case 'global_league':
                    item.label = context.global_league
                    break;
                case 'other_league':
                    item.label = context.other_league
                    break;
                case 'active_monthly_league':
                    item.label = context.active_monthly_league
                    break;
                case 'completed_monthly_league':
                    item.label = context.completed_monthly_league
                    break;
                default:
                    item.label = context.all_league
                    break;
            }

        })
        switch (key) {
            case 'global_league':
                key = context.global_league
                break;
            case 'other_league':
                key = context.other_league
                break;
            case 'active_monthly_league':
                key = context.active_monthly_league
                break;
            case 'completed_monthly_league':
                key = context.completed_monthly_league
                break;
            default:
                key = context.global_league
                break;
        }
        return key;
    }

    handleFilterLeague = (SelectedLeague, LeaderboardList) => {
        let LB_Object = {};
        _.filter(LeaderboardList, function (o, i) {
            if (SelectedLeague.value == i || SelectedLeague.value == 'all_league') {
                LB_Object[i] = o
            }
        });
        this.setState({
            SelectedLeague: SelectedLeague,
            LeaderboardListArr: LB_Object
        });
    }

    gotoDetails = (data, context, LeaderboardList) => {
        try {
            setTimeout(() => {
                console.log(LeaderboardList)
                if (Number(data.contest_info.joined_week) <= context.state.week_info.active_week) {
                    this.props.history.push({
                        pathname: '/leagues/details', state: {
                            ...data,
                            oops_msz: context.state.locale.Leagues.oops_msz,
                            LeaderboardList: LeaderboardList
                        }
                    })
                } else {
                    notify.show(context.state.locale.Leagues.oops_msz, "success", 1500);
                }
            }, 100);
        } catch (error) {
            console.log(error, 'error');
        }
    }



    componentWillMount() {
        var i = 1;
        do {
            this.getLeaderboardList(i);
            i++;
        }
        while (i < 4);
        this.getAdvBanner()

    }
    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }

    taskUpper = (data, i) => {
        let a = this.state.upperBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBannerUpper: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndexUpper();
                }.bind(this), durationForBannerUpper * 1000)

            }
        }.bind(this), durationForBannerUpper * 1000 * i);

    }
    /**
 * ADVERSTIGMENT BANNER SERVICE
 */


    getAdvBanner = (e) => {

        let s3_param = [
            API.GET_BANNER
        ]
        WSManager.https3( WSManager.s3UrlConvert(s3_param), API.GET_BANNER, {}).then((responseJson) => {
            // console.log('adverrrrrrr', responseJson);
            // WSManager.Rest(API.GET_BANNER, {}).then((responseJson) => {
            // console.log('adverrrrrrr', responseJson);
            if (responseJson.response_code === NC.successCode) {
                WSManager.setAdBanner(responseJson.data);
                let bannerData = responseJson.data.advertisements;
                let upperBanner = [];
                let lowerBanner = [];
                let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 12 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        upperBanner.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 13 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        lowerBanner.push(bannerData[k])
                    }
                }
                this.setState({
                    advertisements: responseJson.data.advertisements,
                    advertisementsPosition: responseJson.data.positions,
                    upperBanner: upperBanner,
                    lowerBanner: lowerBanner
                }, () => {
                    // this.getBannerIndex();
                    this.getBannerIndexUpper();
                })
            }
        })
    }


    getBannerIndexUpper = (e) => {
        for (let i = 0; i <= this.state.upperBanner.length - 1; i++) {
            this.taskUpper(this.state.upperBanner[i], i);
        }
    }
    getBannerClick = (from) => {
        if (from == 1) {
            window.open(this.state.currentBannerUpper);
        }
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }
    render() {
        const {
            SelectedLeague,
            LeagueModalShow,
            isLoaderShow,
            GlobalLeaderboardList,
            PrivateLeaderboardList,
            MonthlyLeaderboardList,
            LeaderboardListArr
        } = this.state;

        const LeagueModalProps = {
            ...this.props,
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            LeagueModalShow: LeagueModalShow
        }

        const LeaderboardBlock = ({ LeaderboardList, context, HideColCompletedSpecialLeagues }) => {
            return (
                _.map(LeaderboardList, (Leaderboard, idx) => {
                    return (
                        idx != 'current_week' &&
                        !_.isEmpty(Leaderboard) &&
                        <Fragment key={idx}>
                            <div className="title m"> {this.getLeagueCategoryName(idx, context.state.locale.Leagues)}</div>
                            <div className="content">
                                <div className="content-header">
                                    <div className="cell uppercase wlg">{context.state.locale.Leagues.league}</div>
                                    <div className="cell uppercase">
                                        {
                                            HideColCompletedSpecialLeagues != true &&
                                            context.state.locale.Fixtures.gameweek
                                        }
                                    </div>
                                    <div className="cell uppercase">
                                        {
                                            HideColCompletedSpecialLeagues != true &&
                                            context.state.locale.Leagues.current_rank
                                        }
                                    </div>
                                    <div className="cell uppercase">{context.state.locale.Leagues.last_rank}</div>
                                    <div className="cell uppercase">{context.state.locale.Leagues.total_points}</div>
                                    <div className="cell"></div>
                                </div>
                                {
                                    !_.isEmpty(Leaderboard) ?
                                        <div className="league-table">
                                            {
                                                _.map(Leaderboard, (item, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            {
                                                                !_.isEmpty(item) &&
                                                                <div className={`tbl-row ${item.is_country_set == 0 || item.is_club_set == 0 ? 'country-not-set pointer' : ''}`} data-line={item.is_country_set == 0 ? context.state.locale.Leagues.see_your_country_rank : (item.is_club_set == 0 ? context.state.locale.Leagues.see_your_club_rank : '')}
                                                                    onClick={() => (item.is_country_set == 0 || item.is_club_set == 0) ? this.props.history.push({ pathname: '/profile', state: item }) : null}>
                                                                    <span className='hide'>{item.league_type = idx}{item.module = index}</span>

                                                                    <div className="cell wlg">{!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}</div>
                                                                    <div className="cell">
                                                                        {
                                                                            HideColCompletedSpecialLeagues != true &&
                                                                            <Fragment>
                                                                                {LeaderboardList.current_week || '--'}
                                                                            </Fragment>
                                                                        }
                                                                    </div>
                                                                    <div className="cell">
                                                                        {
                                                                            HideColCompletedSpecialLeagues != true &&
                                                                            <Fragment>
                                                                                {item.rank || '--'}
                                                                            </Fragment>
                                                                        }
                                                                    </div>
                                                                    <div className="cell">{item.last_rank || '--'}</div>
                                                                    <div className="cell">{item.total_score || '--'}</div>
                                                                    <div className="cell">
                                                                        <a className='standing-link' onClick={() => this.gotoDetails(item, context, LeaderboardList)}>
                                                                            <img src={Img.STANDING_ICON} alt="" className="standing_icon" />
                                                                            <span>{context.state.locale.Leagues.standings}</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <h5 className='no-data-league'>{context.state.locale.Leagues.oops_msz}</h5>
                                }
                            </div>
                        </Fragment>
                    )
                })
            )
        }

        const LeaderboardListClone = {
            ...GlobalLeaderboardList,
            ...PrivateLeaderboardList,
            ...MonthlyLeaderboardList
        }
        const LeaderboardList = _.isEmpty(LeaderboardListArr) ? LeaderboardListClone : LeaderboardListArr

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        {/* <AdsContainer/> */}
                        <Row>
                            <Col md={1} sm={0}></Col>
                            <Col md={10} sm={12}>
                                <div className='"dashboard-banner right-margin cursor-p' onClick={(e) => { this.getBannerClick('1') }}>
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        duration={durationForBannerUpper}
                                        data={this.state.upperBanner}
                                    />
                                </div>
                            </Col>
                            <Col md={1} sm={0}></Col>
                        </Row>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.leagues_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.leagues_description} />
                        </Helmet>
                        <Row className='margin-leagues'>
                            {/* <Col md={1} sm={0} className='hide-sm'> <img src={Images.BANNER_LEFT_LEAGUES} alt='' className='leagues-sponsors'/> </Col> */}
                            <Col md={10} sm={12}>
                                <Row>
                                    <Col lg={10} className="auto-margin">
                                        <div className="league-heading m">
                                            <div className="title">{context.state.locale.Leagues.hero_title}</div>
                                            <div className="subtitle">{context.state.locale.Leagues.description}</div>
                                        </div>
                                        <Row>
                                            <Col md={12}>
                                                <div className='league-filter-wrap m-t row'>
                                                    <Col md={6}>
                                                        <Button onClick={() => this.modalShow('LeagueModalShow')} bsStyle="primary" block>{context.state.locale.Leagues.create_btn}</Button>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="league-filter">
                                                            <div className="title">{context.state.locale.Leagues.league_filter}</div>
                                                            <div className="league-filter-dropdown">
                                                                <Select className="select2" options={LeagueType} value={SelectedLeague} onChange={data => this.handleFilterLeague(data, LeaderboardListClone)} classNamePrefix="select" isSearchable={false} isClearable={false} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="league-details">
                                            {
                                                SelectedLeague.value != 'all_league' &&
                                                    _.isEmpty(LeaderboardList[SelectedLeague.value]) ?
                                                    <h5 className='no-data-league'>{context.state.locale.PlayerCard.no_record_msz}</h5>
                                                    :
                                                    <LeaderboardBlock LeaderboardList={LeaderboardList} context={context} />
                                            }
                                            {/* <LeaderboardBlock LeaderboardList={GlobalLeaderboardList} context={context} />
                                    <LeaderboardBlock LeaderboardList={PrivateLeaderboardList} context={context} />
                                    <LeaderboardBlock LeaderboardList={MonthlyLeaderboardList} context={context} HideColCompletedSpecialLeagues={true} /> */}
                                        </div>


                                        <div className={`m roster-filter-loader fade ${isLoaderShow ? 'in' : null}`}><RosterLoader /></div>

                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} className='hide-sm leagues-sponsors'>
                                <div className='"dashboard-banner right-margin cursor-p league-rt-banner' onClick={(e) => { this.getBannerClick('1') }}>
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        duration={durationForBannerUpper}
                                        data={this.state.lowerBanner}
                                    />
                                </div>
                                {/* <a href='http://pubads.g.doubleclick.net/gampad/clk?id=6524111939&iu=/22272936144'><img src={Images.BANNER_LEFT_LEAGUES} alt='' className='leagues-sponsors'/></a>  */}

                            </Col>
                        </Row>

                        <LeagueModal {...LeagueModalProps} />
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}