import _ from 'lodash';

export const ForMaintainance = false

const HostnameCollection = [
  'localhost',
  '3.132.128.5',
]
const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;

const surge = {
        FB_APP_ID: "25011983928447926",
        GOOGLE_CLIENT_ID: "284463820228-pdbvkk0hrq7qptq3fijkm2jrve0grgk9.apps.googleusercontent.com",
};
const dev = {
    s3: {
        BUCKET: "https://karmik-stg.s3.us-east-2.amazonaws.com/static/",
        BUCKET_DATA_PREFIX: "stg",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: {
         URL:  "http://3.132.128.5",
        //  URL:  "http://103.26.206.191/",
        // URL:  "https://fantasy.kooora.com",
    },
    cognito: {
        FB_APP_ID: "25011983928447926",
        GOOGLE_CLIENT_ID: "284463820228-pdbvkk0hrq7qptq3fijkm2jrve0grgk9.apps.googleusercontent.com",
    }
};

const prod = {
    s3: {
        BUCKET: "https://kooorafantasy-prod.s3.amazonaws.com/static/",
        BUCKET_DATA_PREFIX: "prd",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: {
        URL:  "https://fantasy.kooora.com",
    },
    cognito: {
        FB_APP_ID: "25011983928447926",
        GOOGLE_CLIENT_ID: "284463820228-pdbvkk0hrq7qptq3fijkm2jrve0grgk9.apps.googleusercontent.com",
    }
};

if(hostname === '3.132.128.5') {
    dev.cognito = surge
}
const config = _.includes(HostnameCollection, hostname) || hostname.match(regex) ? dev : prod;
// const config = prod;

export default {
    ...config
};

//Surge Domain: http://103.26.206.191/