import React, { Component } from 'react'

export default class AdsContainer extends Component {
    componentDidMount() {
        window.dataLayer.push({ event: 'onPageAds' })
    }
    render() {
        return (<></>
            // <div id={'Leaderboard'} className='sponsor-logo-bg '/>
        )
    }
}
