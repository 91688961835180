/* eslint-disable no-mixed-operators, eqeqeq */
import React, {Fragment} from 'react';
import { Col, Row } from 'react-bootstrap';

import { MyContext } from '../context';
import {Helmet} from "react-helmet";
import * as qs from 'query-string';

export default class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }
    render() {
        const {
            isMobile
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.support_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.support_description} />
                        </Helmet>
                        <Row className="">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">{context.state.locale.support.hero_title}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="static-page-wrap">
                                {
                                    context.state.language == 'en' &&
                                    <Fragment>
                                        <h6 className='text-bold'>{context.state.locale.support.p1}</h6>
                                        {/* <p>{context.state.locale.support.p21}{" "}<a href="mailto:support@alharrif.com">support@alharrif.com</a>{" "}{context.state.locale.support.p22}</p> */}
                                        <p>{context.state.locale.support.p21_1}{" "}<a target='_blank' rel="noopener noreferrer" href="https://twitter.com/kooora?lang=en">@Kooora</a>{" "}{context.state.locale.support.p22}</p>
                                        <h6 className='text-bold'>{context.state.locale.support.p3}</h6>
                                        <p>{context.state.locale.support.p41}{" "}<a href="mailto:marketing@alharrif.com">marketing@alharrif.com</a>{" "}{context.state.locale.support.p42}</p>
                                    </Fragment>
                                    ||
                                    <Fragment>
                                        <h6 className='text-bold'>{context.state.locale.support.p1}</h6>
                                        {/* <p>{context.state.locale.support.p2}{" "}<a href="mailto:support@alharrif.com">support@alharrif.com</a></p> */}
                                        <p>{context.state.locale.support.p2_1}{" "}<a target='_blank' rel="noopener noreferrer" href="https://twitter.com/kooora?lang=ar">Kooora@</a></p>
                                        <h6 className='text-bold'>{context.state.locale.support.p3}</h6>
                                        <p>{context.state.locale.support.p41}{" "}<a href="mailto:marketing@alharrif.com">marketing@alharrif.com</a>{" "}{context.state.locale.support.p42}</p>
                                    </Fragment>
                                }
                                        
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}