import React from 'react';
import {
   Col,
   Row,
   Table
} from 'react-bootstrap';
import { MyContext } from '../context';
import Images from '../components/images';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Drawer from 'react-motion-drawer';
import Slider from "react-slick";
import SimpleImageSlider from "ac-react-simple-image-slider";
var durationForBanner = 10;
var durationForBannerUpper = ''
// const data = [
//    {
//       id: 1,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 210
//    },
//    {
//       id: 2,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 138
//    },
//    {
//       id: 3,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 139
//    },
//    {
//       id: 4,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 140
//    },
//    {
//       id: 5,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 165
//    },
//    {
//       id: 6,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 175
//    },
//    {
//       id: 7,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 178
//    },
//    {
//       id: 8,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 180
//    },
//    {
//       id: 9,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 210
//    },
//    {
//       id: 10,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 224
//    },
//    {
//       id: 1,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 210
//    },
//    {
//       id: 2,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 138
//    },
//    {
//       id: 3,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 139
//    },
//    {
//       id: 4,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 140
//    },
//    {
//       id: 5,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 165
//    },
//    {
//       id: 6,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 175
//    },
//    {
//       id: 7,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 178
//    },
//    {
//       id: 8,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 180
//    },
//    {
//       id: 9,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 210
//    },
//    {
//       id: 10,
//       rank: 60,
//       username: "mark.zuker",
//       fullname: "mark zukerbrg",
//       point: 224
//    },
// ]
// const Questionlist = [
//    {
//       id: 1,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute",
//             points: 5,
//             point_text: "points",
//             check_icon: <i className='icn-tick'></i>
//          }
//       ]
//    }, {
//       id: 2,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option2: "10 minutes",
//             wrong_answer: "Correct answer - 30 minutes",
//             cross_icon: <i className='icn-icon-cross'></i>
//          }
//       ]
//    },
//    {
//       id: 3,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute",
//             points: 5,
//             point_text: "points",
//             check_icon: <i className='icn-tick'></i>
//          }
//       ]
//    },
//    {
//       id: 4,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option2: "10 minutes",
//             wrong_answer: "Correct answer - 30 minutes",
//             cross_icon: <i className='icn-icon-cross'></i>
//          }
//       ]
//    },
//    {
//       id: 5,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute",
//             points: 5,
//             point_text: "points",
//             check_icon: <i className='icn-tick'></i>
//          }
//       ]
//    },
//    {
//       id: 6,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute",
//             points: 5,
//             point_text: "points",
//             check_icon: <i className='icn-tick'></i>
//          }
//       ]
//    }

// ];
export default class TriviaLeaderboard extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         round: 1,
         leaderboard_details: '',
         own_leaderboard_details: '',
         scoring_weeks: '',
         SelectedWeek: '',
         leaderboard_user_count: '',
         user_id: WSManager.getProfile().user_id,
         week: '',
         question_answer: '',
         Questionlist: [],
         defaultRosterWidth: 991,
      }
   }
   handleChangeTabsports = (i) => {
      this.setState({
         round: i,
      });
   };

   componentDidMount() {
      this.getUserLeaderboard()

      window.addEventListener("resize", this.updateDimensions);
   }
   componentWillMount() {
      this.updateDimensions();
      this.getAdvBanner();
      // let advBanner = WSManager.getAdBanner();
      // if (advBanner && advBanner.advertisements != undefined) {
      //     this.callAdvBanner(advBanner);
      // }
   }
   
   /**
    * BANNER CLICK FOR LOWER BANNER 
    */
   getBannerIndex = (e) => {
      for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
         this.task(this.state.lowerBanner[i], i);
      }
   }
   task = (data, i) => {
      let a = this.state.lowerBanner.length - 1;
      setTimeout(function () {
         this.setState({
            currentBanner: data.target_url
         })
         if (a == i) {
            setTimeout(function () {
               this.getBannerIndex();
            }.bind(this), durationForBanner * 1000)

         }
      }.bind(this), durationForBanner * 1000 * i);

   }

   taskUpper = (data, i) => {
      let a = this.state.upperBanner.length - 1;
      setTimeout(function () {
         this.setState({
            currentBannerUpper: data.target_url
         })
         if (a == i) {
            setTimeout(function () {
               this.getBannerIndexUpper();
            }.bind(this), durationForBannerUpper * 1000)

         }
      }.bind(this), durationForBannerUpper * 1000 * i);

   }

   /***
   * BANNER CLICK 
   */

   getBannerClick = (from) => {
      if (from == 1) {
         window.open(this.state.currentBannerUpper);
      }
      if (from == 2) {
         window.open(this.state.currentBanner);
      }

   }


   /**
 * BANNER CLICK FOR UPPER BANNER 
 */
   getBannerIndexUpper = (e) => {
      for (let i = 0; i <= this.state.upperBanner.length - 1; i++) {
         this.taskUpper(this.state.upperBanner[i], i);
      }
   }
   //   callAdvBanner = (data) => {
   //       let bannerData = data.advertisements;
   //       let upperBanner = []
   //       let lowerBanner = [];
   //       let squareBanner = [];
   //       for (let k = 0; k < bannerData.length; k++) {
   //           if (bannerData[k].ad_position_id == 2) {
   //               bannerData[k]["src"] = bannerData[k].image_adsense
   //               bannerData[k]["id"] = bannerData[k].ads_unique_id
   //               lowerBanner.push(bannerData[k])
   //           }
   //           else if (bannerData[k].ad_position_id == 1) {
   //               bannerData[k]["src"] = bannerData[k].image_adsense
   //               bannerData[k]["id"] = bannerData[k].ads_unique_id
   //               squareBanner.push(bannerData[k])
   //           }
   //           else if (bannerData[k].ad_position_id == 3) {
   //              bannerData[k]["src"] = bannerData[k].image_adsense
   //              bannerData[k]["id"] = bannerData[k].ads_unique_id
   //              upperBanner.push(bannerData[k])
   //           }
   //         {

   //           }
   //       }
   //       this.setState({
   //           lowerBanner: lowerBanner,
   //           squareBanner: squareBanner,
   //           upperBanner: upperBanner
   //       }, () => {
   //           this.getBannerIndex();
   //           // console.log(this.state.squareBanner,'sdssdfsquareBanner')
   //       })
   //   }
   getAdvBanner = (e) => {

      let s3_param = [
         API.S3_GET_BANNER
      ]
      WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_BANNER, {}).then((responseJson) => {

         if (responseJson.response_code === NC.successCode) {
            WSManager.setAdBanner(responseJson.data);
            let bannerData = responseJson.data.advertisements;
            let upperBanner = []
            let lowerBanner = [];
            let squareBanner = [];
            let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
            for (let k = 0; k < bannerData.length; k++) {
               if (bannerData[k].ad_position_id == 18 && bannerData[k].league_id == sportsLg) {
                  bannerData[k]["src"] = bannerData[k].image_adsense
                  bannerData[k]["id"] = bannerData[k].ads_unique_id
                  lowerBanner.push(bannerData[k])
               }
               else if (bannerData[k].ad_position_id == 1) {
                  bannerData[k]["src"] = bannerData[k].image_adsense
                  bannerData[k]["id"] = bannerData[k].ads_unique_id
                  squareBanner.push(bannerData[k])
               }
               else if (bannerData[k].ad_position_id == 3) {
                  bannerData[k]["src"] = bannerData[k].image_adsense
                  bannerData[k]["id"] = bannerData[k].ads_unique_id
                  upperBanner.push(bannerData[k])
               }
               {

               }
            }
            this.setState({
               lowerBanner: lowerBanner,
               squareBanner: squareBanner,
               upperBanner: upperBanner
            }, () => {
               this.getBannerIndex();
               // console.log(this.state.squareBanner,'sdssdfsquareBanner')
            })
         }
      })
   }
   getBannerIndex = (e) => {
      for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
         this.task(this.state.lowerBanner[i], i);
      }
   }
   task = (data, i) => {
      let a = this.state.lowerBanner.length - 1;
      setTimeout(function () {
         this.setState({
            currentBanner: data.target_url
         })
         if (a == i) {
            setTimeout(function () {
               this.getBannerIndex();
            }.bind(this), durationForBanner * 1000)

         }
      }.bind(this), durationForBanner * 1000 * i);

   }
   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
   }

   getUserLeaderboard = () => {
      let param = {
         "sports_id": WSManager.getMultiLegData().sports_id,
         "league_id": WSManager.getMultiLegData().league_id,
      }
      WSManager.Rest(API.GET_TRIVIA_LEADERBOARD_USERS, param).then((responseJson) => {
         if (responseJson.response_code === NC.successCode) {
            // console.log(responseJson.data,"MMMMMM");

            this.setState({
               leaderboard_details: responseJson.data.leaderboard_details,
               own_leaderboard_details: responseJson.data.own_leaderboard_details,
               leaderboard_user_count: responseJson.data.leaderboard_user_count,
               week: responseJson.data.active_week
            }, () => {
               this.getLeaderboardInfo()
            })

         }
      })
   }

   getLeaderboardInfo = () => {
      const { user_id, week } = this.state
      let param = {
         "sports_id": WSManager.getMultiLegData().sports_id,
         "league_id": WSManager.getMultiLegData().league_id,
         "user_id": user_id,
         "week": week
      }
      WSManager.Rest(API.GET_USER_LEADERBOARD_INFO, param).then((responseJson) => {
         if (responseJson.response_code === NC.successCode) {
            console.log(responseJson.data, "MMMMMM");

            this.setState({
               user_info_data: responseJson.data,
               question_answer: responseJson.data.question_answer,
               scoring_weeks: responseJson.data.scoring_weeks,
               SelectedWeek: responseJson.data.question_answer.week
            }, () => {

            })

         }
      })
   }

   getLeaderboardData = (data) => {
      this.setState({
         user_id: data.user_id,
         week: data.last_week
      }, () => {
         this.getLeaderboardInfo()
      })

   }

   handlerChangeWeek = (week) => {
      this.setState({
         week: week,
      }, () => {
         this.getLeaderboardInfo()
      })
   }

   DrawerRosterTrigger = (DrawerRosterOpen) => {

      this.setState({
         DrawerRosterOpen: !DrawerRosterOpen
      })
   }

   updateDimensions = () => {
      this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
   }

   render() {
      const { own_leaderboard_details, leaderboard_details, user_info_data, scoring_weeks, SelectedWeek, leaderboard_user_count, question_answer, Questionlist, defaultRosterWidth, DrawerRosterOpen } = this.state
      var settings = {
         rtl: false,
         infinite: false,
         slidesToShow: 5,
         slidesToScroll: 3,
         centerMode: false,
         variableWidth: false,
         centerPadding: '0px',
         initialSlide: 0,
         responsive: [
            {
               breakpoint: 1200,
               settings: {
                  slidesToShow: 5,
               }
            },
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 4,
               }
            },
            {
               breakpoint: 991,
               settings: {
                  slidesToShow: 4,
               }
            },
            {
               breakpoint: 800,
               settings: {
                  slidesToShow: 4,
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 3,
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
               }
            },
         ]
      };
      const RosterDrawerProps = {
         ...this.props,
         overlayColor: "rgba(0,0,0,0.8)",
         width: "90%",
         right: true,
         fadeOut: true,
         open: DrawerRosterOpen,
         noTouchOpen: true,
         noTouchClose: true,
      };
      return (
         <MyContext.Consumer>
            {(context) => (
               <div className="container main-container">
                  {
                     this.state.lowerBanner && <div className='sponsor-logo-bg tttt' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                        <SimpleImageSlider
                           showArrows={false}
                           showDots={false}
                           data={this.state.lowerBanner}
                           duration={durationForBanner}
                        />
                     </div>
                  }
                  <div className="trivia-leaderboard-container">
                     <div className="trivia-leaderboard-view-div ">
                        <div className="trivia-leaderboard-back">
                           <i className='icn-background-prev-arrow text-danger' onClick={() => this.props.history.goBack()} />
                           <span onClick={() => this.props.history.goBack()}>back</span>
                        </div>
                        <div className="trivia-leaderboard-text">
                           <div className="leaderboard-text">Trivia Leaderboard</div>
                           <div className="members-text">({leaderboard_user_count} Members)</div>
                        </div>
                     </div>

                     <Row className="m-t-20 ">
                        <Drawer className='drawer-roster-wrapper' {...RosterDrawerProps}
                           onChange={open => this.setState({ DrawerRosterOpen: open })}>
                           {this.state.defaultRosterWidth > this.state.innerWidth ?
                              <div>
                                 <div className="players-rank-container">
                                    <Table borderless hover>
                                       <thead >
                                          <tr className='player-rank-name-pts-header'>
                                             <th>rank</th>
                                             <th>name</th>
                                             <th>pts</th>
                                          </tr>
                                       </thead>
                                       <tbody className="players-tbody">
                                          {
                                             _.map(own_leaderboard_details, (iw, iwx) => {
                                                return (
                                                   <tr className='player-data-view active' onClick={() => [this.getLeaderboardData(iw), this.DrawerRosterTrigger(true)]} key={iwx}>
                                                      <td className='rank-number'>{iw.position}</td>
                                                      <td className='player-details-container'>
                                                         <div className="user-images"> <img src={iw.user_image !== '' ? iw.user_image : Images.DEFAULT_USER} /></div>
                                                         <div className="username-and-name">
                                                            <div className="player-username">
                                                               {iw.first_name + ' ' + iw.last_name}
                                                            </div>
                                                            <div className="player-full-name">
                                                               {iw.user_name}
                                                            </div>
                                                         </div>
                                                      </td>
                                                      <td className="players-points">
                                                         <span>{iw.total_point}</span>
                                                      </td>
                                                   </tr>
                                                )
                                             })
                                          }
                                       </tbody>
                                       <tbody className="players-tbody">
                                          {
                                             _.map(leaderboard_details, (iu, iux) => {
                                                return (
                                                   <tr className='player-data-view' key={iux} onClick={() => [this.getLeaderboardData(iu), this.DrawerRosterTrigger(true)]}>
                                                      <td className='rank-number'>{iu.position}</td>
                                                      <td className='player-details-container'>
                                                         <div className="user-images"> <img src={iu.user_image !== '' ? iu.user_image : Images.DEFAULT_USER} /></div>
                                                         <div className="username-and-name">
                                                            <div className="player-username">
                                                               {iu.first_name + ' ' + iu.last_name}
                                                            </div>
                                                            <div className="player-full-name">
                                                               {iu.user_name}
                                                            </div>
                                                         </div>
                                                      </td>
                                                      <td className="players-points">
                                                         <span>{iu.total_point}</span>
                                                      </td>
                                                   </tr>
                                                )
                                             })
                                          }
                                       </tbody>
                                    </Table>







                                 </div>
                              </div>

                              : ''}
                        </Drawer>
                        {this.state.defaultRosterWidth <= this.state.innerWidth && <Col lg={4}>
                           <div className="players-rank-container">
                              <Table borderless hover>
                                 <thead >
                                    <tr className='player-rank-name-pts-header'>
                                       <th>rank</th>
                                       <th>name</th>
                                       <th>pts</th>
                                    </tr>
                                 </thead>
                                 <tbody className="players-tbody">
                                    {
                                       _.map(own_leaderboard_details, (iw, iwx) => {
                                          return (
                                             <tr className='player-data-view active' onClick={() => this.getLeaderboardData(iw)} key={iwx}>
                                                <td className='rank-number'>{iw.position}</td>
                                                <td className='player-details-container'>
                                                   <div className="user-images"> <img src={iw.user_image !== '' ? iw.user_image : Images.DEFAULT_USER} /></div>
                                                   <div className="username-and-name">
                                                      <div className="player-username">
                                                         {iw.first_name + ' ' + iw.last_name}
                                                      </div>
                                                      <div className="player-full-name">
                                                         {iw.user_name}
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="players-points">
                                                   <span>{iw.total_point}</span>
                                                </td>
                                             </tr>
                                          )
                                       })
                                    }
                                 </tbody>
                                 <tbody className="players-tbody">
                                    {
                                       _.map(leaderboard_details, (iu, iux) => {
                                          return (
                                             <tr className='player-data-view' key={iux} onClick={() => this.getLeaderboardData(iu)}>
                                                <td className='rank-number'>{iu.position}</td>
                                                <td className='player-details-container'>
                                                   <div className="user-images"> <img src={iu.user_image !== '' ? iu.user_image : Images.DEFAULT_USER} /></div>
                                                   <div className="username-and-name">
                                                      <div className="player-username">
                                                         {iu.first_name + ' ' + iu.last_name}
                                                      </div>
                                                      <div className="player-full-name">
                                                         {iu.user_name}
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="players-points">
                                                   <span>{iu.total_point}</span>
                                                </td>
                                             </tr>
                                          )
                                       })
                                    }
                                 </tbody>
                              </Table>







                           </div>
                        </Col>}
                        <Col lg={8} md={12} >
                           <span className="roster-drawer-trigger t-leader d-block d-sm-block d-md-block d-lg-none" onClick={() => this.DrawerRosterTrigger(false)}>
                              <span>
                                 <div className="label my-info-label">Leaderboard</div>
                              </span>
                           </span>
                           <div className="questions-view-container">
                              {/* <div className="slider-component-view">
                                 <div className="next-prev-arrows">
                                    <i className="icn-arrow-prev" />
                                 </div>
                                 <div className={this.state.round == 1 ? "active-rank-rounds" : "rank-rounds"}
                                    onClick={() => this.handleChangeTabsports(1)}>
                                    <div className="round-text">round 1</div>
                                    <div className="round-point">10 rank</div>
                                 </div>
                                 <div className={this.state.round == 2 ? "active-rank-rounds" : "rank-rounds"}
                                    onClick={() => this.handleChangeTabsports(2)}>
                                    <div className="round-text">round 1</div>
                                    <div className="round-point">10 rank</div>
                                 </div>
                                 <div className={this.state.round == 3 ? "active-rank-rounds" : "rank-rounds"}
                                    onClick={() => this.handleChangeTabsports(3)}>
                                    <div className="round-text">round 1</div>
                                    <div className="round-point">10 rank</div>
                                 </div>
                                 <div className={this.state.round == 4 ? "active-rank-rounds" : "rank-rounds"}
                                    onClick={() => this.handleChangeTabsports(4)}>
                                    <div className="round-text">round 1</div>
                                    <div className="round-point">10 rank</div>
                                 </div>
                                 <div className={this.state.round == 5 ? "active-rank-rounds" : "rank-rounds"}
                                    onClick={() => this.handleChangeTabsports(5)}>
                                    <div className="round-text">round 1</div>
                                    <div className="round-point">10 rank</div>
                                 </div>
                                 <div className="next-prev-arrows">
                                    <i className="icn-arrow-next" />
                                 </div>
                              </div> */}
                              {
                                 !_.isEmpty(scoring_weeks) ?
                                    <div className="weeklist-slider-wrap">
                                       {
                                          !_.isEmpty(scoring_weeks) &&
                                          <Slider className="weeklist-slider" ref={slider => (this.slider = slider)} {...settings}>
                                             {
                                                _.map(scoring_weeks, (week, idx) => {
                                                   console.log(week, "weekweek");
                                                   return (
                                                      //week.week <= week_info.active_week &&
                                                      <div className={`weeklist-slider-item ${week.week == SelectedWeek ? 'selected' : ''}`} key={idx} onClick={() => this.handlerChangeWeek(week.week)}>
                                                         <div>{WSManager.getWeekLabel(WSManager.getMultiLegData().week_label[this.state.week])}
                                                         </div>
                                                         <div className='rank-label'>{week.position} {context.state.locale.LeagueDetails.rank}</div>
                                                      </div>
                                                   )
                                                })
                                             }
                                          </Slider>
                                       }
                                    </div>
                                    :
                                    <div className='alert alert-warning text-center'>
                                       {context.state.locale.LeagueDetails.league_standing_msz}
                                    </div>
                              }

                              {!_.isEmpty(user_info_data) && user_info_data.first_name !== '' ? (
                                 <div className="question-currect-container">
                                    <div className="header-container">
                                       <div className="left-content">
                                          <div className="user-images"> <img src={user_info_data.user_image !== '' ? user_info_data.user_image : Images.DEFAULT_USER} /></div>
                                          <div className="userame-name-view">
                                             <div className="username">{user_info_data.first_name + ' ' + user_info_data.last_name}</div>
                                             <div className="fullname">{user_info_data.user_name}</div>
                                          </div>
                                       </div>
                                       <div className="right-content">
                                          <div className="rank-view">
                                             <div className="points">{user_info_data.position}</div>
                                             <div className="point-text">rank</div>
                                          </div>
                                          <div className="points-view">
                                             <div className="points">{user_info_data.total_point}</div>
                                             <div className="point-text">points</div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="round-view-container">
                                       <div className="round-text">Round {question_answer.week}</div>
                                       <div className="questions-text">{question_answer.total} questions</div>
                                    </div>

                                    {question_answer.result.map((item, index) => {
                                       return (
                                          <div className="currect-answer-view-container" key={index}>
                                             <div className="left-questions-view">
                                                <div className="qustions">{item.question}</div>
                                                <div className="corrext-wrond-answer">
                                                   {item.correct_anwser == '1' &&
                                                      <div className="corrext-answer">{item.selected_option}

                                                         <i className="icn-tick" />
                                                      </div>
                                                   }
                                                   {item.correct_anwser == '0' &&
                                                      <>
                                                         <div className="wrong-answer">{item.selected_option}
                                                            <i className="icn-icon-cross" />
                                                         </div>
                                                         <div className="correct-answer-text">
                                                            Correct answer - {item.answer}
                                                         </div>
                                                      </>
                                                   }



                                                </div>
                                             </div>
                                             <div className="right-questions-view">
                                                <div className="points-number">{item.winning_prize}</div>
                                                <div className="points-text">{"Points"}</div>
                                             </div>

                                          </div>
                                       )
                                    })}


                                 </div>
                              ) : null}

                           </div>
                        </Col>



                     </Row>



                  </div>


               </div>
            )}
         </MyContext.Consumer>
      )
   }
}

