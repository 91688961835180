const Images = {
  KSAF_AR_LOGO: require('./../assets/img/kleague.png'),
  KSAF_AR_GRAY_LOGO: require('./../assets/img/kleague.png'),
  KSAF_EN_LOGO: require('./../assets/img/kleague.png'),
  // KSAF_EN_GRAY_LOGO:require('./../assets/img/KSAFF-En-logo-grey.png'),
  KSAF_EN_GRAY_LOGO: require('./../assets/img/kleague.png'),

  DARK_KSAF_AR_LOGO: require('./../assets/img/kleague_dark.png'),
  DARK_KSAF_AR_GRAY_LOGO: require('./../assets/img/kleague_dark.png'),
  DARK_KSAF_EN_LOGO: require('./../assets/img/kleague_dark.png'),
  DARK_KSAF_EN_GRAY_LOGO: require('./../assets/img/Dark_KSAFF-En-logo-grey.png'),

  HEADER_LOGO: require('./../assets/img/header-logo.png'),
  DEFAULT_USER: require('./../assets/img/default_user.png'),
  FOOTER_LOGO: require('./../assets/img/footer-logo.png'),
  VINFOTECH_LOGO: require('./../assets/img/vinfotech-logo.png'),
  FACEBOOK_IC: require('./../assets/img/fb-ic.png'),
  GOOGLE_IC: require('./../assets/img/gp-ic.png'),
  THANKYOU_SIGNUP: require('./../assets/img/thankyou-logo.png'),
  OVEL_HOME: require('./../assets/img/ovel-shape.png'),
  MOBILE_FIELD: require('./../assets/img/app-img.png'),
  PLAY_STORE_BIG: require('./../assets/img/play-store-btn.png'),
  APP_STORE_BIG: require('./../assets/img/app-store-btn.png'),
  W1_HOME: require('./../assets/img/w1-img.png'),
  W2_HOME: require('./../assets/img/w2-img.png'),
  W3_HOME: require('./../assets/img/w3-img.png'),
  COINS_HOME: require('./../assets/img/coins.png'),
  COINS_HOME_ARABIC: require('./../assets/img/win-arabic.png'),
  HOME_FIX_BG: require('./../assets/img/home_fixture_bg.png'),
  HOME_FIX_L_ARROW: require('./../assets/img/home_fixture_left_arrow.png'),
  HOME_FIX_R_ARROW: require('./../assets/img/home_fixture_right_arrow.png'),
  HOME_FIX_BUTTON: require('./../assets/img/home_fixture_btn.png'),
  STRIP_HOME: require('./../assets/img/strip.png'),
  THANKYOU_IC: require('./../assets/img/thanks-ic.png'),
  CLUB_FLAG: require('./../assets/img/dummy-club-flag.png'),
  CLUB_FLAG_DEFAULT: require('./../assets/img/flag_default.jpg'),
  JERSEY: require('./../assets/img/jersey.png'),
  JERSEY_COLORED: require('./../assets/img/jersey-colored.png'),
  STANDING_ICON: require('./../assets/img/standing-ic.png'),
  SHAPPLAYERPOSITION: require('./../assets/img/shapPlayerPosition.png'),
  PRIZE_BG1: require('./../assets/img/prize-img1.png'),
  EDIT_WHITE_PENCIL: require('./../assets/img/edit-profile-ic.png'),
  FIRSTPRIZE: require('./../assets/img/firstPrize.png'),
  SECONDPRIZE: require('./../assets/img/secondPrize.png'),
  THIRDPRIZE: require('./../assets/img/thirdPrize.png'),
  FIRSTPRIZE_LARGE: require('./../assets/img/prize1-lg.png'),
  SECONDPRIZE_LARGE: require('./../assets/img/prize2-lg.png'),
  THIRDPRIZE_LARGE: require('./../assets/img/prize3-lg.png'),
  SCORING_SHAP: require('./../assets/img/scoringShap.png'),
  GW_R_PLAYER: require('./../assets/img/gwRPlayer.png'),
  GW_L_PLAYER: require('./../assets/img/gwLPlayer.png'),
  ARROW_IN: require('./../assets/img/arrow-small-in.png'),
  ARROW_OUT: require('./../assets/img/arrow-small-out.png'),
  DEFAULT_NATIONAL_FLAG: require('./../assets/img/default_national_flag.jpg'),
  DEFAULT_PLAYER_THUMBNAIL: require('./../assets/img/default_player_img.jpg'),
  ADD_PLAYER_BTN: require('./../assets/img/add-player-btn.png'),
  REMOVE_PLAYER_BTN: require('./../assets/img/remove-player-btn.png'),
  VICE_CAPTAIN_IC: require('./../assets/img/vice-captain-ic@2x.png'),
  CAPTAIN_IC: require('./../assets/img/captain-ic@2x.png'),
  LEAGUE_CODE_IC: require('./../assets/img/league-code-ic.png'),
  LOGOUT_IC: require('./../assets/img/logout-ic.png'),
  CAPTAIN_BOOSTER: require('./../assets/img/captain-booster.png'),
  BENCH_BOOSTER: require('./../assets/img/bench-booster.png'),
  APP_STORE: require('./../assets/img/app-store.png'),
  GOOGLE_PLAY: require('./../assets/img/google-play.png'),
  PLAYER_IMAGE: require('./../assets/img/player-img.jpg'),
  PADLOCK: require('./../assets/img/padlock.svg'),
  SPINER: require('./../assets/img/spiner.svg'),

  BANNER_1: require('./../assets/img/banner-1.png'),
  BANNER_2: require('./../assets/img/banner-2.png'),

  BANNER_AR_1: require('./../assets/img/banner-ar-1.png'),
  BANNER_AR_2: require('./../assets/img/banner-ar-2.png'),

  BANNER_POPUP_1: require('./../assets/img/banner-popup-1.png'),
  BANNER_POPUP_AR_1: require('./../assets/img/banner-popup-ar-1.png'),

  BANNER_POPUP_2: require('./../assets/img/banner-popup-2.png'),
  BANNER_POPUP_AR_2: require('./../assets/img/banner-popup-ar-2.png'),
  SPONSER_BANNER: require('./../assets/img/sponser-banner.png'),
  OVAL: require('./../assets/img/oval.png'),
  KUWAIT_LEAGUE_LOGO: require('./../assets/img/kleague.png'),
  // WHISTLE : require('./../assets/img/whistl.png'),
  WHISTLE: require('./../assets/img/whistl2.png'),
  CHATSHAP: require('./../assets/img/Shape.svg'),
  CHAT_BUBBLE: require('./../assets/img/chatbubble.png'),
  SIDE_DESIGN: require('./../assets/img/side-design.png'),
  LEG_IMG: require('./../assets/img/leg-img.png'),
  LEG_SWITCH: require('./../assets/img/switch.png'),
  SWITCH_LEG: require('./../assets/img/switch-leg.png'),
  PLAY_BTN: require('./../assets/img/play_btn.png'),
  PLAY_BTN_AR: require('./../assets/img/play_btn_ar.png'),

  transferError: require('./../assets/img/transferError.png'),
  transferWarning: require('./../assets/img/transferWarning.png'),

  maintainancePage: require('./../assets/img/maintainancePage.jpg'),
  TRIVIA_VIEW_IMG: require('./../assets/img/play-trivia-img.png'),
  CAS_ADS: require('./../assets/img/cas-ads.png'),
  Ad_pitch: require('./../assets/img/sponsor-banner.png'),
  BANNER_LEFT_LEAGUES : require('./../assets/img/banner_left_leagues.jpg'),
  AMAZON_SA_IC : require('./../assets/img/amazon_sa_ic.png')
  /**
   * Kooora Fantasy
   */
}
export default Images
