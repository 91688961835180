/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Img from '../components/images';
import { MyContext } from '../context';
import RosterLoader from '../views/RosterLoader';
import BannerModal from '../Modals/BannerModal';
import * as qs from 'query-string';
import { notify } from 'react-notify-toast';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
// import Images from '../components/images';
import SimpleImageSlider from "ac-react-simple-image-slider";
import SpeenWheelModal from '../Modals/SpeenWheelModal';
import CongratsWheelModal from '../Modals/CongratsWheelModal';
import ReactGA from 'react-ga';
import AdsContainer from '../components/AdsContainer';
ReactGA.initialize('UA-146072563-1');
ReactGA.pageview(window.location.pathname + window.location.search)


var durationForBanner = 10;
var durationForBannerUpper = 8;



export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topScoringPlayerList: [],
            leagueList: '',
            game_week_scores: '',
            LeaderboardList: [],
            isLoaderShow: false,
            firstPrizeDetail: '',
            secondPrizeDetail: '',
            thirdPrizeDetail: '',
            week_info: JSON.parse(localStorage.getItem('week_info')),
            your_team_overview: [],
            is_new_season_setup: null,
            LineupInfoLeagueCollaps: false,
            LineupInfoLeagueLoader: false,
            isBannerModalShow: false,
            BannerType: null,
            showTempBanner: false,
            active_week: '',
            showSpinWheel: false,
            claimed: '',
            showCongratsModal: false
        }
    }

    GetLeagueDetail = () => {
        let s3_param = [
            API.S3_GET_LEAGUE_DETAIL,
            WSManager.getMultiLegData().league_id
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_LEAGUE_DETAIL, {
            // "league_id": NC.leagueId
            "league_id": WSManager.getMultiLegData().league_id
        }).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    // is_new_season_setup: responseJson.data.data.is_new_season_setup
                    // leagueList: responseJson.data.data,
                    is_new_season_setup: responseJson.data.data

                })
            }
        })
    }

    pickTeamConfirm = (context) => {

        const { is_new_season_setup } = this.state;
        if (is_new_season_setup) {
            context.modalShow('ThankyouPickTeamModalShow');
        } else {
            this.props.history.push({ pathname: '/my-team' });
        }
    }

    YourTeamOverviewFilter = (data, block) => {
        let _filter = _.filter(data, item => {
            return item.block == block;
        })
        if (block == 1 && _filter.length > 0) {
            let arr = new Array(8)
            _.map(arr, (item, idx) => {
                return arr[idx] = _filter[idx]
            })
            return arr;
        } else {
            return _filter;
        }
    }
    componentWillMount() {
        if (WSManager.getMultiLegData()) {
            if (WSManager.getMultiLegData().league_uid == 225 && WSManager.getMultiLegData().league_name == 'Saudi League') {
                this.setState({
                    showTempBanner: true
                })
            } else {
                this.setState({
                    showTempBanner: false
                })
            }
            let param = {
                "sports_id": WSManager.getMultiLegData() ? WSManager.getMultiLegData().sports_id : "",
                "league_id": WSManager.getMultiLegData() ? WSManager.getMultiLegData().league_id : "",
                is_top_players_req: 0,
                is_match_list_req: 0
            }
            this.setState({ isLoaderShow: true })
            WSManager.Rest(API.GET_HOME_DATA, param).then((responseJson) => {
                this.setState({ isLoaderShow: false })
                try {
                    if (responseJson.response_code === NC.successCode) {
                        this.GetTopScoringPlayers();
                        this.setState({
                            active_week: responseJson.data.active_week,
                            game_week_scores: responseJson.data.game_week_scores,
                            //  leagueList: responseJson.data.league_list?responseJson.data.league_list:'',
                            your_team_overview: responseJson.data.your_team_overview
                        })


                        if (responseJson.data.winner_prizes.length > 2) {
                            this.setState({
                                firstPrizeDetail: responseJson.data.winner_prizes[0],
                                secondPrizeDetail: responseJson.data.winner_prizes[1],
                                thirdPrizeDetail: responseJson.data.winner_prizes[2]
                            })
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            })

            const { location, history } = this.props;
            const parsed = qs.parse(location.search);
            if (!_.isEmpty(parsed) && !_.isUndefined(parsed.activation_key)) {
                WSManager.Rest(API.ACTIVATE_ACCOUNT, {
                    "key": parsed.activation_key
                }).then((responseJson) => {
                    if (responseJson.response_code == NC.successCode) {
                        notify.show(responseJson.message, "success", 1500);
                    }
                    history.push({ pathname: '/' });
                })
            }
            this.getAdvBanner();
        }
        this.setLineupMasterId();
    }

    componentDidMount() {
        this.getWheelData()
    }

    getWheelData = () => {
        let param = {

        }
        WSManager.Rest(API.GET_SPINTHEWHEEL, param).then((responseJson) => {
            console.log(responseJson, "111111111111111111");
            this.setState({
                spinWheelData: responseJson.data,
                claimed: responseJson.data.claimed
            })
        })
    }

    setLineupMasterId = () => {

        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
        }
        WSManager.Rest(API.MY_CURRENT_LINEUP, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                if (responseJson.data.user_join_count == 1) {
                    localStorage.setItem('user_join_count', 1);
                } else {
                    this.GetLeagueDetail()
                }
                this.setState({
                    leagueList: responseJson.data.user_join_count
                })
            }
        })

    }


    /**
     * ADVERSTIGMENT BANNER SERVICE
     */


    getAdvBanner = (e) => {

        let s3_param = [
            API.S3_GET_BANNER
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_BANNER, {}).then((responseJson) => {
            // console.log('adverrrrrrr', responseJson);
            // WSManager.Rest(API.GET_BANNER, {}).then((responseJson) => {
            // console.log('adverrrrrrr', responseJson);
            if (responseJson.response_code === NC.successCode) {
                WSManager.setAdBanner(responseJson.data);
                let bannerData = responseJson.data.advertisements;
                let homeBn1 = [];
                let homeBn2 = [];
                let homeBn3 = [];
                let homeBn4 = [];
                let spinBnr = [];
                let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 5 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        homeBn1.push(bannerData[k])
                        // console.log(bannerData[k],'bannerData[k]')
                    }
                    if (bannerData[k].ad_position_id == 6 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        homeBn2.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 7 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        homeBn3.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 8 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        homeBn4.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 17 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        spinBnr.push(bannerData[k])
                    }
                }
                this.setState({
                    advertisements: responseJson.data.advertisements,
                    advertisementsPosition: responseJson.data.positions,
                    homeBn1: homeBn1,
                    homeBn2: homeBn2,
                    homeBn3:homeBn3,
                    homeBn4:homeBn4,
                    spinBnr:spinBnr
                }, () => {
                    this.getBannerIndex();
                    // this.getBannerIndexUpper();
                })
            }
        })
    }

    /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.homeBn2.length - 1; i++) {
            this.task(this.state.homeBn2[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.homeBn2.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }


    /**
  * BANNER CLICK FOR UPPER BANNER 
  */
    getBannerIndexUpper = (e) => {
        for (let i = 0; i <= this.state.homeBn1.length - 1; i++) {
            this.taskUpper(this.state.homeBn1[i], i);
        }
    }

    taskUpper = (data, i) => {
        let a = this.state.homeBn1.length - 1;
        setTimeout(function () {
            this.setState({
                currentBannerUpper: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndexUpper();
                }.bind(this), durationForBannerUpper * 1000)

            }
        }.bind(this), durationForBannerUpper * 1000 * i);

    }

    /***
     * BANNER CLICK 
     */

    getBannerClick = (from) => {
        // console.log(from[0].target_url,'dsadsad')
        if (from == 1) {
            window.open(this.state.currentBannerUpper);
        }
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }

    GetTopScoringPlayers = () => {
        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
        }
        let s3_param = [
            API.S3_TOP_SCORING_PLAYERS,
            WSManager.getMultiLegData().league_id,

        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.TOP_SCORING_PLAYERS, param).then((responseJson) => {
            // WSManager.Rest(API.TOP_SCORING_PLAYERS, param).then((responseJson) => {
            try {
                console.log("=====", responseJson)
                this.setState({
                    topScoringPlayerList: responseJson.data.overall_top_scorer
                })
            } catch (error) {
                console.log(error)
            }
        })


        // let s3_param = [
        //     API.S3_GET_LEAGUE_DETAIL,
        //     WSManager.getMultiLegData().league_id
        // ]
        // WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_LEAGUE_DETAIL, {
        //     // "league_id": NC.leagueId
        //     "league_id" :WSManager.getMultiLegData().league_id
        // }).then((responseJson) => {
        //     if (responseJson.response_code === NC.successCode) {
        //         this.setState({
        //             // is_new_season_setup: responseJson.data.data.is_new_season_setup
        //            // leagueList: responseJson.data.data,
        //             is_new_season_setup: responseJson.data.data

        //         })
        //     }
        // })
    }

    // GET_USER_LEADERBOARD_LIST
    getUserLeaderboardList = (type) => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
        }
        let _API;
        let BoardState;
        switch (type) {
            case 1:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;

            case 2:
                _API = API.GET_USER_LEADERBOARD_LIST_GLOBAL;
                BoardState = 'GlobalLeaderboardList';
                break;

            case 3:
                _API = API.GET_ALL_MONTHLY_LEAGUE_LIST;
                BoardState = 'MonthlyLeaderboardList';
                break;

            default:
                _API = API.GET_USER_LEADERBOARD_LIST_PRIVATE;
                BoardState = 'PrivateLeaderboardList';
                break;
        }

        this.setState({ LineupInfoLeagueLoader: true });
        WSManager.Rest(_API, param).then((responseJson) => {
            try {
                if (responseJson.response_code === NC.successCode) {
                    responseJson = responseJson.data;
                    if (type == 3) {
                        this.setState({
                            [BoardState]: responseJson.monthly_standing
                        }, () => {
                            const { PrivateLeaderboardList, GlobalLeaderboardList, MonthlyLeaderboardList } = this.state
                            this.setState({
                                LeaderboardList: {
                                    ...GlobalLeaderboardList,
                                    ...PrivateLeaderboardList,
                                    ...MonthlyLeaderboardList
                                }
                            })
                        })
                    } else {
                        this.setState({
                            [BoardState]: responseJson
                        }, () => {
                            const { PrivateLeaderboardList, GlobalLeaderboardList, MonthlyLeaderboardList } = this.state
                            this.setState({
                                LeaderboardList: {
                                    ...GlobalLeaderboardList,
                                    ...PrivateLeaderboardList,
                                    ...MonthlyLeaderboardList
                                }
                            })
                        })
                    }

                }
            } catch (error) {
                console.error(error)
            }
            this.setState({ LineupInfoLeagueLoader: false });
        })
    }

    getLeagueCategoryName = (key, context) => {
        switch (key) {
            case 'global_league':
                key = context.global_league
                break;
            case 'other_league':
                key = context.other_league
                break;
            case 'active_monthly_league':
                key = context.active_monthly_league
                break;
            case 'completed_monthly_league':
                key = context.completed_monthly_league
                break;
            default:
                key = context.global_league
                break;
        }
        return key;
    }

    gotoDetails = (data, context) => {
        const { LeaderboardList } = this.state
        // data.oops_msz = context.state.locale.Leagues.oops_msz
        //  data.LeaderboardList = this.state.LeaderboardList;
        setTimeout(() => {
            if ((data.contest_info.joined_week || 0) <= context.state.week_info.active_week) {
                this.props.history.push({
                    pathname: '/leagues/details', state: {
                        ...data,
                        oops_msz: context.state.locale.Leagues.oops_msz,
                        LeaderboardList: LeaderboardList
                    }
                })
            } else {
                notify.show(context.state.locale.Leagues.oops_msz, "success", 1500);
            }
        }, 100);
    }


    LineupInfoLeagueCollapsToggle = () => {

        this.setState({ LineupInfoLeagueCollaps: !this.state.LineupInfoLeagueCollaps }, () => {
            if (this.state.LineupInfoLeagueCollaps && !this.state.LineupInfoLeagueLoader) {
                var i = 1;
                do {
                    this.getUserLeaderboardList(i);
                    i++;
                }
                while (i < 4);
            }
        })
    }

    BannerModalToggle = (BannerType) => {
        this.setState({
            BannerType: BannerType,
            isBannerModalShow: !this.state.isBannerModalShow
        })
    }

    ShowWheelModal = () => {
        this.setState({
            showSpinWheel: !this.state.showSpinWheel
        })
    }

    ShowModalCong = () => {
        this.setState({
            showCongratsModal: !this.state.showCongratsModal
        }, () => {
            this.getWheelData()
        })
    }

    render() {
        const {
            isLoaderShow,
            game_week_scores,
            topScoringPlayerList,
            // leagueList,
            LeaderboardList,
            // firstPrizeDetail,
            // secondPrizeDetail,
            // thirdPrizeDetail,
            your_team_overview,
            LineupInfoLeagueCollaps,
            LineupInfoLeagueLoader,
            isBannerModalShow,
            BannerType,
            active_week,
            showSpinWheel,
            spinWheelData,
            claimed,
            showCongratsModal,
            homeBn3
        } = this.state;
        console.log(active_week, "active_week");
        const my_team_overview = this.YourTeamOverviewFilter(your_team_overview, 1);
        const available_boosters = this.YourTeamOverviewFilter(your_team_overview, 0);


        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerPadding: '0px',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        arrows: false
                    }
                },

            ]
        };

        const BannerModalProps = {
            isOpen: isBannerModalShow,
            BannerType: BannerType,
            toggle: this.BannerModalToggle
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        <div id={'Leaderboard'} className='sponsor-logo-bg container'>
                            {
                                this.state.homeBn1 && <div className='sponsor-logo-bg w-fix-banner' onClick={(e) => { this.getBannerClick(this.state.homeBn1) }} id="slider">
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        data={this.state.homeBn1}
                                        duration={durationForBanner}
                                    />
                                </div>
                            }
                        </div>
                        <AdsContainer />
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.dashboard_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.dashboard_description} />
                        </Helmet>


                        <div className={`roster-filter-loader fade ${isLoaderShow ? 'in' : null}`}><RosterLoader /></div>
                        <Row className="DashBoardStyle">
                            {
                                context.state.language == 'ar' ? settings.rtl = true : null
                            }
                            {
                                this.state.showTempBanner && <div className='temp-banner'>
                                    {context.state.locale.Dashboard.temp_title}
                                </div>
                            }
                            {
                                this.state.leagueList == 0 && <div className="welcomeContainer row">
                                    <div className="col-md-9 row">
                                        <div className="col-md-3 text-center">
                                            <img src={context.state.language == 'en' ? Img.KSAF_EN_LOGO : Img.KSAF_AR_LOGO} alt="" className="imgLogo" />
                                        </div>
                                        <div className="col-md-9 Welcomeleague-heading">
                                            <div className="title">
                                                <span>{context.state.locale.Dashboard.hero_title_start}</span>
                                                <span className='hero_title_end'>{context.state.locale.Dashboard.hero_title_end}</span>
                                            </div>
                                            {/* <div className="subtitle">{context.state.locale.Dashboard.description}</div> */}
                                        </div>
                                    </div>
                                    {
                                        !WSManager.loggedIn() && <div className="col-md-3 auto-margin text-center">
                                            <div>
                                                <Button bsStyle="primary" className='btnStyle' onClick={() => context.modalShow('LoginModalShow')} >{context.state.locale.Dashboard.login}</Button>
                                            </div>
                                            <div className="NotaUser">{context.state.locale.Dashboard.not_user} <a onClick={() => context.modalShow('SignupModalShow')}>{context.state.locale.Dashboard.signup}</a></div>
                                        </div>
                                    }

                                    {
                                        WSManager.loggedIn() && <div className="col-md-3 auto-margin text-center">
                                            <div>
                                                {/* <Button bsStyle="primary" className='btnStyle lg' onClick={() => this.props.history.push({ pathname: '/my-team' })}>{context.state.locale.Dashboard.pick_team}</Button> */}
                                                <Button bsStyle="primary" className='btnStyle lg'
                                                    onClick={() => this.pickTeamConfirm(context)}
                                                >{context.state.locale.Dashboard.pick_team}</Button>
                                            </div>
                                            <div className="NotaUser">{context.state.locale.Dashboard.not_you} <a onClick={() => { WSManager.logout(); this.props.history.push({ pathname: '/' }); window.location.reload(); }}>{context.state.locale.Dashboard.logout}</a></div>
                                        </div>
                                    }

                                </div>
                            }


                            {
                                // leagueList.length > 0 && leagueList[0].user_join_count == 1 && <div className="GameweekContainer">
                                this.state.leagueList == 1 && <div className="GameweekContainer">

                                    <div className="">
                                        {/* <img src={context.state.language == 'en' ? Img.KSAF_EN_LOGO : Img.KSAF_AR_LOGO} alt="" className="imgLogo-GW" /> */}
                                        <img src={context.state.language == 'en' ? Img.AMAZON_SA_IC : Img.AMAZON_SA_IC} alt="" className="imgLogo-GW" />
                                    </div>
                                    <div className="GW-heading">
                                        <div className="title">{WSManager.getWeekLabel(WSManager.getMultiLegData().week_label[active_week])} {context.state.locale.Dashboard.points}</div>
                                        <a className="btnMyTeam" onClick={() => this.props.history.push({ pathname: '/my-team' })} >{context.state.locale.Dashboard.go_team}</a>
                                    </div>
                                    <div className="GWScoring-heading col-md-9 col-sm-12 col-xs-12 row">
                                        {/* <img src={Img.SCORING_SHAP} alt="" className="scoringShap" /> */}
                                        <div className="auto-margin col-xs-4 col-sm-4 col-md-4">
                                            <div className="avgValueScore">
                                                {game_week_scores.avg_score || '--'}
                                            </div>
                                            <div className="avgHeadingScore">{context.state.locale.Dashboard.average}</div>
                                        </div>
                                        <div className="auto-margin col-xs-4 col-sm-4 col-md-4">
                                            <div className="hr-line"></div>
                                            <div className="YourScoreVAlue">
                                                {game_week_scores.user_score || '--'}
                                            </div>
                                            <div className="avgHeadingScore">{context.state.locale.Dashboard.your_score}</div>
                                            <div className="hr-line-r"></div>
                                        </div>
                                        <div className="auto-margin col-xs-4 col-sm-4 col-md-4">

                                            <div className="avgValueScore">
                                                {game_week_scores.high_score || '--'}
                                            </div>
                                            <div className="avgHeadingScore">{context.state.locale.Dashboard.highest}</div>
                                        </div>
                                        <img src={Img.GW_L_PLAYER} alt="" className="gw-left-player-image" />
                                        <img src={Img.GW_R_PLAYER} alt="" className="gw-right-player-image" />
                                    </div>
                                </div>
                            }
                            <Col xs={12}>
                                <div className='trivia-banner-wrap'>
                                    <div className='trivia-banner'>
                                        <h4>{context.state.locale.Dashboard.Play_Trivia}</h4>
                                        <button className='btn btn-primary' onClick={() => this.props.history.push('/play-trivia')}>{context.state.locale.Dashboard.Play_Trivia_title}</button>
                                    </div>
                                    <div className='wheel-banner'>
                                        <h4>{context.state.locale.Dashboard.spinWheel}</h4>
                                        <button className='btn btn-primary' disabled={claimed == 1 ? true : false} onClick={() => this.ShowWheelModal()}>{context.state.locale.Dashboard.spin_title}</button>
                                    </div>
                                </div>
                            </Col>

                            <div className="topScoringContainer">

                                <div className={`topScoring-heading row ${this.state.leagueList == 1 ? 'topScoring-padding-top' : ''}`}>
                                    <div className="titleScore col-md-6">{context.state.locale.Dashboard.top_scoring_players}</div>
                                    <div className="viewAlleScore col-md-6" onClick={() => this.props.history.push({ pathname: '/player-stats' })}><a>{context.state.locale.Dashboard.view_player_stats}</a></div>
                                </div>
                                <Slider className="scoringPlayer_slider" {...settings}>
                                    {
                                        _.map(topScoringPlayerList, (item, idx) => {
                                            return (
                                                <div key={idx} className="scoringPlayerCard" onClick={() => context.playerCardModalInit('PlayerCardModalShow', item)}>
                                                    <div alt="" className="rectangle">
                                                        <div className="positionName-update">{item.position}</div>
                                                    </div>
                                                    <img src={item.flag} alt="" className="imgFlag" />
                                                    {/* <span className="positionName" >{item.position}</span> */}
                                                    <img src={item.jersey} alt="" className="playerImage" />
                                                    <div className="playerScoreDetail">
                                                        <div className="playerFNAme">{item.full_name}</div>
                                                        {/* <div className="playerLNAme">{item.last_name}</div> */}
                                                        <div className="points-value">{item.top_score}</div>
                                                        <div className="points-label">{context.state.locale.Dashboard.points}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            {/* {
                                leagueList.length > 0 && leagueList[0].user_join_count == 0 &&
                                <div className="prizeContainer pointer row" onClick={()=>this.props.history.push({ pathname: '/prizes' })}>
                                    <div className="col-md-12 row m">
                                        <div className="col-md-5 prize-heading">
                                            <div className="titleWin">{context.state.locale.Dashboard.win}</div>
                                            <div className="subtitleWin">{context.state.locale.Dashboard.cash_prizes}</div>
                                        </div>
                                        <div className="col-md-7 auto-margin">
                                            <div className="second-prize">
                                                <div className="prize-detail">
                                                    <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                    <div className="money">{secondPrizeDetail.prize_money}</div>
                                                </div>
                                            </div>

                                            <div className="first-prize">
                                                <div className="prize-detail">
                                                    <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                    <div className="money">{firstPrizeDetail.prize_money}</div>
                                                </div>
                                            </div>


                                            <div className="third-prize">
                                                <div className="prize-detail">
                                                    <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                    <div className="money">{thirdPrizeDetail.prize_money}</div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="viewAllePrize">
                                        <Link to={'/prizes'}>{context.state.locale.Dashboard.view_prizes}</Link>
                                    </div>

                                </div>
                            } */}

                            {/* May 2019 */}
                            {


                                WSManager.loggedIn() && 

                                <Fragment className='sponsor-bg-small-parent'>
                                    <Col md={6} className='sponsor-bg-small'>
                                        <div className='"dashboard-banner right-margin cursor-p' onClick={(e) => { this.getBannerClick(this.state.homeBn2) }}>
                                            <SimpleImageSlider
                                                showArrows={false}
                                                showDots={false}
                                                duration={durationForBannerUpper}
                                                data={this.state.homeBn2}
                                            />
                                        </div>


                                    </Col>

                                    <Col md={6} className='sponsor-bg-small hide-sm'>
                                        <div className='"dashboard-banner right-margin cursor-p' onClick={(e) => { this.getBannerClick(this.state.homeBn3) }}>
                                            <SimpleImageSlider
                                                showArrows={false}
                                                showDots={false}
                                                duration={durationForBannerUpper}
                                                data={this.state.homeBn3}
                                            />
                                        </div>

                                    </Col>
                                </Fragment>

                            }


                            {
                                WSManager.loggedIn() &&
                                <Fragment>
                                    {
                                        my_team_overview.length != 0 &&
                                        <Col md={available_boosters.length == 0 ? 12 : 6} className=''>
                                            <div className={`stats-container ${available_boosters.length == 0 ? '' : 'right-margin'}`}>
                                                <div className="title">
                                                    {context.state.locale.Dashboard.my_team_overview}
                                                </div>
                                                <div className="team-overview-grid">
                                                    {
                                                        _.map(my_team_overview, (item, idx) => {
                                                            return (
                                                                _.isUndefined(item) ?
                                                                    <div className="cell" key={idx}></div>
                                                                    :
                                                                    <div className="cell" key={idx}>
                                                                        <div className="value">{item.value || '--'}</div>
                                                                        <div className="text_label">{item.display_label}</div>
                                                                    </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    }

                                    {
                                        available_boosters.length != 0 &&
                                        <Col md={my_team_overview.length == 0 ? 12 : 6} className=''>
                                            <div className={`stats-container ${my_team_overview.length == 0 ? '' : 'left-margin'}`}>
                                                <div className="title">
                                                    {context.state.locale.Dashboard.available_boosters}
                                                </div>
                                                <div className="booster-overview-tbl">
                                                    {
                                                        _.map(available_boosters, (item, idx) => {
                                                            return (
                                                                <div className="booster-row" key={idx}>
                                                                    <div className="cell text-bold">{item.display_label}</div>
                                                                    {
                                                                        context.state.language == 'ar' ?
                                                                            <div className={`cell ${item.value != 'متاح' ? 'teal-color' : ''}`}>
                                                                                {item.value}
                                                                            </div>
                                                                            :
                                                                            <div className={`cell ${item.value != 'Available' ? 'teal-color' : ''}`}>
                                                                                {item.value}
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </Col>
                                    }
                                </Fragment>
                            }
                            {/* End */}


                            {
                                this.state.leagueList == 1 &&

                                <div className="LeaguesContainer row">

                                    <div className="col-xs-12">
                                        <div className="lineup-info-league m-0 collaps" >
                                            <div className="your-joined-leagues" onClick={() => this.LineupInfoLeagueCollapsToggle()}>
                                                {context.state.locale.Dashboard.your_joined_leagues}
                                                <i className={`icon-nextArrow ${LineupInfoLeagueCollaps ? 'up' : ''}`}></i>
                                            </div>
                                            {
                                                LineupInfoLeagueCollaps &&
                                                <div className="leagueScrollView">
                                                    {
                                                        LineupInfoLeagueLoader &&
                                                        <img src={Img.SPINER} alt="" className='spiner' />
                                                    }
                                                    {
                                                        _.map(LeaderboardList, (Leaderboard, idx) => {
                                                            return (
                                                                !_.isEmpty(Leaderboard) ?
                                                                    <div className="lineup-league" key={idx}>
                                                                        <div className="league-list-title">
                                                                            <span>{this.getLeagueCategoryName(idx, context.state.locale.Dashboard)}</span>
                                                                        </div>

                                                                        <ul className="lineup-league-list">
                                                                            <li className='lineup-league-item title'>
                                                                                <div className="cell">{context.state.locale.Dashboard.rank}</div>
                                                                                <div className="cell lg">{context.state.locale.Dashboard.league}</div>
                                                                                <div className="cell"></div>
                                                                            </li>
                                                                            {
                                                                                !_.isEmpty(Leaderboard) ?
                                                                                    <div>
                                                                                        {
                                                                                            _.map(Leaderboard, (item, index) => {
                                                                                                return (
                                                                                                    !_.isEmpty(item) &&
                                                                                                    <li key={index} className={`lineup-league-item tbl-row ${item.is_country_set == 0 || item.is_club_set == 0 ? 'country-not-set pointer' : ''}`}
                                                                                                        data-line={item.is_country_set == 0 ? context.state.locale.Leagues.see_your_country_rank : (item.is_club_set == 0 ? context.state.locale.Leagues.see_your_club_rank : '')}
                                                                                                        onClick={() => (item.is_country_set == 0 || item.is_club_set == 0) ? this.props.history.push({ pathname: '/profile', state: item }) : null}>
                                                                                                        <div className="cell">{item.rank || '--'}</div>
                                                                                                        <div className="cell lg">{!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}</div>
                                                                                                        <div className="cell">
                                                                                                            <span className='standing-ic' onClick={() => this.gotoDetails(item, context)}></span>
                                                                                                        </div>
                                                                                                        <span className='hide'>
                                                                                                            {item.league_type = idx}
                                                                                                            {item.module = index}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <h5 className='no-data-league'>{context.state.locale.Dashboard.oops_msz}</h5>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    : ''
                                                            )
                                                        })
                                                    }
                                                </div>

                                            }



                                        </div>




                                    </div>

                                </div>
                            }

                            {/* {
                                leagueList.length > 0 && leagueList[0].user_join_count == 1 && <div className="LeaguesContainer row">
                                    <div className="col-md-6">
                                        <div className="lineup-info-league marginRight" >
                                            <div className="your-joined-leagues ">{context.state.locale.Dashboard.your_joined_leagues}</div>
                                            <div className="leagueScrollView">
                                                {
                                                    _.map(LeaderboardList, (Leaderboard, idx) => {
                                                        return (
                                                            !_.isEmpty(Leaderboard) ?
                                                            <div className="lineup-league" key={idx}>
                                                                <div className="league-list-title">
                                                                    <span>{this.getLeagueCategoryName(idx, context.state.locale.Dashboard)}</span>
                                                                </div>
                                                                
                                                                <ul className="lineup-league-list">
                                                                    <li className='lineup-league-item title'>
                                                                        <div className="cell">{context.state.locale.Dashboard.rank}</div>
                                                                        <div className="cell lg">{context.state.locale.Dashboard.league}</div>
                                                                        <div className="cell"></div>
                                                                    </li>
                                                                    {
                                                                        !_.isEmpty(Leaderboard) ?
                                                                            <div>
                                                                                {
                                                                                    _.map(Leaderboard, (item, index) => {
                                                                                        return (

                                                                                            !_.isEmpty(item) &&
                                                                                            <li key={index} className={`lineup-league-item tbl-row ${item.is_country_set == 0 || item.is_club_set == 0 ? 'country-not-set pointer' : ''}`} data-line={item.is_country_set == 0 ? context.state.locale.Leagues.see_your_country_rank : (item.is_club_set == 0 ? context.state.locale.Leagues.see_your_club_rank : '')}
                                                                                    onClick={() => (item.is_country_set == 0 || item.is_club_set == 0) ? this.props.history.push({ pathname: '/profile', state: item }) : null}>
                                                                                                <div className="cell">{item.rank || '--'}</div>
                                                                                                <div className="cell lg">{!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}</div>
                                                                                                <div className="cell">
                                                                                                    <span className='standing-ic' onClick={() => this.gotoDetails(item, context)}></span>
                                                                                                </div>
                                                                                                <span className='hide'>
                                                                                                    {item.league_type = idx}
                                                                                                    {item.module = index}
                                                                                                </span>
                                                                                            </li>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <h5 className='no-data-league'>{context.state.locale.Dashboard.oops_msz}</h5>
                                                                    }
                                                                </ul>
                                                            </div>
                                                            : ''
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-md-6">
                                        <div className="league-prize-container" onClick={()=>this.props.history.push({ pathname: '/prizes' })}>
                                            <div className="auto-margin">
                                                <div className="prize-heading">
                                                    <div className="titleWin">{context.state.locale.Dashboard.win}</div>
                                                    <div className="subtitleWin">{context.state.locale.Dashboard.cash_prizes}</div>
                                                </div>
                                                <div className="auto-margin">
                                                    <div className="second-prize">
                                                        <div className="prize-detail">
                                                            <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                            <div className="money">{secondPrizeDetail.prize_money}</div>
                                                        </div>
                                                    </div>

                                                    <div className="first-prize">
                                                        <div className="prize-detail">
                                                            <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                            <div className="money">{firstPrizeDetail.prize_money}</div>
                                                        </div>
                                                    </div>


                                                    <div className="third-prize">
                                                        <div className="prize-detail">
                                                            <div className="sar">{context.state.locale.Dashboard.usd_currency_label}</div>
                                                            <div className="money">{thirdPrizeDetail.prize_money}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="viewAllePrize">
                                            <Link to={'/prizes'}>{context.state.locale.Dashboard.view_prizes}</Link></div>
                                        </div>
                                    </div>
                                
                                </div>
                            } */}
                            {
                                this.state.homeBn4 && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick(this.state.homeBn4) }} id="slider">
                                    <SimpleImageSlider
                                        showArrows={false}
                                        showDots={false}
                                        data={this.state.homeBn4}
                                        duration={durationForBanner}
                                    />
                                </div>
                            }

                            {/* <div id = {'Leaderboard'} className='sponsor-logo-bg '></div> */}


                        </Row>
                        {
                            isBannerModalShow &&
                            <BannerModal {...BannerModalProps} />
                        }
                        {showSpinWheel &&
                            <SpeenWheelModal
                                {...this.props} preData={{
                                    data: spinWheelData,
                                    showCongratsModal: showCongratsModal,
                                    ShowModal: this.ShowModalCong,
                                    mHide: this.ShowWheelModal,
                                    showSpinWheel: this.ShowWheelModal,
                                    //    updateUserBal: this.getUserBal,
                                    //  succSpinWheel: this.succSpinWheel
                                }}
                                getBannerClick={this.getBannerClick()}
                                btmBanner={this.state.spinBnr}

                            />
                        }
                        {
                            showCongratsModal &&
                            <CongratsWheelModal
                                toggle={this.ShowModalCong}
                                showModal={showCongratsModal}
                            />
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}