/* eslint-disable eqeqeq */
import React from 'react';
import { Row } from 'react-bootstrap';

// import Images from '../components/images';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { MyContext } from '../context';
// import Slider from "react-slick";
// import { notify } from 'react-notify-toast';
// import * as qs from 'query-string';
// import { Item } from 'react-bootstrap/lib/Breadcrumb';
import SimpleImageSlider from "ac-react-simple-image-slider";
import AdsContainer from '../components/AdsContainer';
var durationForBanner = 10;

export default class MultiLeg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        this.getAdvBanner()
    }
    getAdvBanner = (e) => {

        let s3_param = [
            API.S3_GET_BANNER
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_BANNER, {}).then((responseJson) => {
 
            if (responseJson.response_code === NC.successCode) {
                WSManager.setAdBanner(responseJson.data);
                let bannerData = responseJson.data.advertisements;
                let Landing1 = [];
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 4) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        Landing1.push(bannerData[k])
                    }
                    // if (bannerData[k].ad_position_id == 2) {
                    //     bannerData[k]["src"] = bannerData[k].image_adsense
                    //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                    //     Landing2.push(bannerData[k])
                    // }
                    // if (bannerData[k].ad_position_id == 3) {
                    //     bannerData[k]["src"] = bannerData[k].image_adsense
                    //     bannerData[k]["id"] = bannerData[k].ads_unique_id
                    //     Landing3.push(bannerData[k])
                    // }

                }
                this.setState({
                    advertisements: responseJson.data.advertisements,
                    advertisementsPosition: responseJson.data.positions,
                    Landing1:Landing1
                }, () => {
                    this.getBannerIndex();
                    // this.getBannerIndexUpper();
                })
            }
        })
    }
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.Landing1.length - 1; i++) {
            this.task(this.state.Landing1[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.Landing1.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner* 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }
    componentWillMount() {
        WSManager.Rest(API.SPORTS_LEAG_LIST, {}).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    leagueList: responseJson.data.result
                })

            }
        })
    }

    getLeagueDetail = (item) => {
        if (item.status == 0) {
            return;
        }
        WSManager.setMultiLeg(false);
        WSManager.setMultiLegData(item);
        this.setLineupMasterId(item);
    }

    setLineupMasterId = (item) => {
        let param = {
            "sports_id": item.sports_id,
            "league_id": item.league_id,
        }
        WSManager.Rest(API.MY_CURRENT_LINEUP, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    leagueList: responseJson.data
                })
                localStorage.setItem('user_join_count', responseJson.data.user_join_count);
            }
        })
        this.props.history.push('/home');
        setTimeout(() => {
            window.location.reload();
        }, 500);


    }

    getBannerClick = (from) => {
        if (from == '1') {
            window.open(this.state.currentBannerUpper);
        }
        if (from == '2') {
            window.open(this.state.currentBanner);
        }

    }
    render() {


        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                    <div id = {'Leaderboard'} className='sponsor-logo-bg container'>
                        {
                            this.state.Landing1 && <div className='sponsor-logo-bg image-ht' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                <SimpleImageSlider
                                    showArrows={false}
                                    showDots={false}
                                    // data={this.state.Landing1}
                                    data={this.state.Landing1}
                                    duration={durationForBanner}
                                />
                            </div>
                            }
                        </div>
                        <AdsContainer />

                        <div className='center-data'>
                            {
                                this.state.leagueList && <div className={this.state.leagueList.length == 1 ? 'center-holder-2' : 'center-holder'}>
                                    <text className='select-a-league'>{context.state.locale.multi_league.select_leg}</text><text className='select-a-league-l'>{context.state.locale.multi_league.league}</text>
                                    <Row>
                                        {
                                            _.map(this.state.leagueList, (item) => {
                                                return <div className='col-sm'>
                                                    <div className='inner-holder'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='left-data'>
                                                                <div className='switch-title-div'>
                                                                    <text className='leg-title'>
                                                                        {item.league_name}
                                                                    </text>
                                                                </div>

                                                                <div className={item.status == 1 ? "playbtn" : "playbtn-disable"} onClick={() => { this.getLeagueDetail(item) }}>
                                                                    <text className='lets-play-text'>{item.status == 1 ? context.state.locale.multi_league.lets_play : context.state.locale.multi_league.comming_soon}</text>
                                                                </div>

                                                            </div>
                                                            <div className='right-image'>
                                                                <img src={item.image} className='icon-sports' alt='' />

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            })
                                        }

                                    </Row>


                                </div>
                            }


                        </div>

                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}