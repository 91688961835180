/* eslint-disable */

import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import * as qs from 'query-string';

export default class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }
    render() {
        const {
            isMobile
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.aboutus_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.aboutus_description} />
                        </Helmet>
                        <Row className="">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">{context.state.locale.about_us.hero_title}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="static-page-wrap">
                                    <p>{context.state.locale.about_us.p1}</p>
                                    <p>{context.state.locale.about_us.p2}</p>
                                    {
                                        context.state.language == 'en' &&
                                        <Fragment>
                                            {/* <p>{context.state.locale.about_us.p31}{" "}</p> */}
                                            <p>{context.state.locale.about_us.p31_1}{" "}</p>
                                            <p><a target='_blank' rel="noopener noreferrer" href="https://twitter.com/kooora?lang=en">@Kooora</a></p>
                                        </Fragment>
                                        ||
                                        <Fragment>
                                            {/* <p>{context.state.locale.about_us.p31}{" "}{context.state.locale.about_us.p32}</p> */}
                                            <p>{context.state.locale.about_us.p31_1}{" "}{context.state.locale.about_us.p32}</p>
                                            <p><a target='_blank' rel="noopener noreferrer" href="https://twitter.com/kooora?lang=ar">Kooora@</a></p>
                                        </Fragment>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}