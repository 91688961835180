/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { MyContext } from '../context';
import * as qs from 'query-string';
export default class Scoring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }

    render() {
        const {
            isMobile
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (
                    context.state.language == 'en' ? <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.scoring_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.scoring_description} />
                        </Helmet>
                        <Row className="">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">RULES AND SCORING</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="static-page-wrap">
                                    <p>During the season, your fantasy football players will be allocated points based on their performance </p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th width='70%'>Action</th>
                                                <th width='30%'>Points</th>
                                            </tr>
                                            <tr>
                                                <td>For playing up to 60 minutes</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>For playing 60 minutes or more (excluding injury time)</td>
                                                <td>2</td>
                                            </tr>
                                            <tr>
                                                <td>For each goal scored by a goalkeeper or defender</td>
                                                <td>6</td>
                                            </tr>
                                            <tr>
                                                <td>For each goal scored by a midfielder</td>
                                                <td>5</td>
                                            </tr>
                                            <tr>
                                                <td>For each goal scored by a forward</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td>For each goal assist</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>For a clean sheet by a goalkeeper or defender</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td>For a clean sheet by a midfielder</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>For every 3 shot saves by a goalkeeper</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>For each penalty save</td>
                                                <td>5</td>
                                            </tr>
                                            <tr>
                                                <td>For each penalty miss</td>
                                                <td>-2</td>
                                            </tr>
                                            <tr>
                                                <td>For every 2 goals conceded by a goalkeeper or defender</td>
                                                <td>-1</td>
                                            </tr>
                                            <tr>
                                                <td>For each yellow card</td>
                                                <td>-1</td>
                                            </tr>
                                            <tr>
                                                <td>For each red card</td>
                                                <td>-3</td>
                                            </tr>
                                            <tr>
                                                <td>For each own goal</td>
                                                <td>-2</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='m-t'>in the Kooora Fantasy.</p>
                                    <b>Red Cards</b>
                                    <p>If a player receives a red card, they will continue to be penalised for goals conceded by their team</p>
                                    <p>Red card deductions include any points deducted for yellow cards</p>
                                    <b>Assists</b>
                                    <p>Assists are awarded to the player from the goal scoring team, who makes the final pass before a goal is scored. An assist is awarded whether the pass was intentional (that it actually creates the chance) or unintentional (that the player had to dribble the ball or an inadvertent touch or shot created the chance).</p>
                                    <p>If an opposing player touches the ball after the final pass before a goal is scored, significantly altering the intended destination of the ball, then no assist is awarded. Should a touch by an opposing player be followed by a defensive error by another opposing outfield player then no assist will be awarded. If the goal scorer loses and then regains possession, then no assist is awarded.</p>
                                    <b>Own Goals</b>
                                    <p>If a player shoots or passes the ball and forces an opposing player to put the ball in his own net, then an assist is awarded.</p>
                                    
                                    <b>Penalties</b>
                                    <p>In the case of a penalty, an assist is calculated for the player earning the penalty if a goal is scored. But not if the player played it himself, in that case the assist is not counted.</p>

                                    <b>Auto Substitution:</b>
                                    <p>Kooora will automatically replace your players who didn’t play in a gameweek.
Auto substitution is processed at the end of each gameweek.</p>
                                    <p>If any of your players plays 0 minutes in the gameweek, he will be substituted by the highest priority substitute bench player who played in that gameweek and does not break the original formation rule of creating teams.
In this way the points scored by your bench players during that gameweek will be added to your score.</p>

                                    <b>Rules and Information:</b>
                                    <p>
                                        1. There is no bonus system in Kooora Fantasy. <br/>
                                        2. If a player wins a penalty kick or a free kick that has been converted into a goal that does not receive Assist points. <br/>
                                        3. If an attacker causes the opponent's defender to score a goal, the player does not receive Assist points. <br/>
                                        4. Players 'prices do not increase or decrease depending on the users' willingness to buy and sell them. <br/>
                                    </p>
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
                        :
                        <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                            <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                                <title>{context.state.locale.MetaTag.scoring_title}</title>
                                <meta name="description" content={context.state.locale.MetaTag.scoring_description} />
                            </Helmet>
                            <Row className="">
                                <Col lg={9} className="auto-margin">
                                    <div className="league-heading m">
                                        <div className="title">القواعد والنقاط</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={9} className="auto-margin">
                                    <div className="static-page-wrap">
                                        <p>خلال الموسم، سوف يتم احتساب نقاط لاعبيك  بحسب الجدول التالي:</p>                                    <table>
                                            <tbody>
                                                <tr>
                                                    <th width='70%'>الفعل</th>
                                                    <th width='30%'>النقاط</th>
                                                </tr>
                                                <tr>
                                                    <td>اللعب حتى 60 دقيقة</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>اللعب 60 دقيقة أو أكثر (غير الوقت بدل الضائع)</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل هدف يسجله حارس مرمى أو مدافع</td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل هدف يسجله لاعب وسط ملعب</td>
                                                    <td>5</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل هدف يسجله مهاجم</td>
                                                    <td>4</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل صناعة هدف</td>
                                                    <td>3</td>
                                                </tr>
                                                <tr>
                                                    <td>للشباك النظيفة لكل مدافع أو حارس مرمى</td>
                                                    <td>4</td>
                                                </tr>
                                                <tr>
                                                    <td>للشباك النظيفة لكل لاعب وسط ملعب</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل 3 تسديدات يتصدى لهم الحارس</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل ضربة جزاء يتصدى لها الحارس</td>
                                                    <td>5</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل ضربة جزاء ضائعة</td>
                                                    <td>-2</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل هدفان يتلقاهم المدافع أو حارس المرمى</td>
                                                    <td>-1</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل بطاقة صفراء</td>
                                                    <td>-1</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل بطاقة حمراء</td>
                                                    <td>-3</td>
                                                </tr>
                                                <tr>
                                                    <td>لكل هدف عكسي </td>
                                                    <td>-2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <p className='m-t'>في الدوري السعودي.</p> */}
                                        <b>البطاقات الحمراء</b>

                                        <p>إذا تلقى لاعب بطاقة حمراء، سوف يستمر في الحصول على غرامة في النقاط للأهداف التي يتلقاها فريقه.</p>
                                        <p>تتضمن نقاط الخصم بسبب البطاقة الحمراء النقاط المستقطعة بداعي البطاقة الصفراء.</p>
                                        <b>صناعة الأهداف</b>

                                        <p>يتم احتساب صناعة الأهداف إلى اللاعب الذي يقوم بالتمريرة الأخيرة قبل تسجيل هدف. يتم احتساب النقاط سواء كانت هذه التمريرة مقصودة (لتصنع فرصة) أو غير مقصودة (اذا كان اللاعب يتحرك بالكرة أو إذا كانت لمسة غير مقصودة أو تسديدة هي التي صنعت الفرصة).</p>

                                        <p>إذا قام لاعب منافس بلمس الكرة بعد التمريرة النهائية قبل تسجيل الهدف مما أدى إلى تغيير الاتجاه المقصود للكرة بشكل كبير، لا يتم احتساب صناعة الهدف. إذا تلت لمسة من لاعب منافس خطأ دفاعي من لاعب منافس آخر ، لا يتم احتساب صناعة الهدف. إذا خسر مسجل الهدف الكرة ثم استرجعها مرة أخرى، لا يتم احتساب صناعة الهدف.</p>

                                       

                                        <b>الأهداف العكسية</b>

                                        <p>إذا سدد أو مرر لاعب الكرة وأرغم اللاعب المنافس على وضع الكرة في مرماه، يتم احتساب صناعة هدف.</p>

                                        {/* <b>ضربات الجزاء والركلات الحرّة</b>
                                        <p>في حالة ضربة جزاء أو ركلة حرة، يتم احتساب صناعة هدف للاعب المتسبب في ضربة الجزاء أو الركلة الحرة إذا تم تسجيل هدف بشكل مباشر، ولكن ليس إذا قام اللاعب بلعبها بنفسه، في هذه الحالة لا يتم احتساب صناعة هدف.</p> */}

                                        <b>ضربات الجزاء</b>
                                        <p>في حالة ضربة جزاء يتم احتساب صناعة هدف للاعب المتسبب في ضربة الجزاء إذا تم تسجيل هدف بشكل مباشر، ولكن ليس إذا قام اللاعب بلعبها بنفسه، في هذه الحالة لا يتم احتساب صناعة هدف.</p>

                                        <b>التبديلات التلقائية:</b>
                                        <p>يتم التبديل التلقائي للاعبين الاساسيين الذين لم يشاركوا خلال الجولة على الشكل التالي:
اي لاعب من تشكيلتك الاساسية لم يشارك خلال الجولة سيتم استبداله بأحد لاعبي الإحتياط الذين شاركوا في الجولة بحسب الأفضلية وبدون ان يكسر في اي وقت القواعد الأساسية لتأسيس الفرق. (حارس مرمى، ثلاثة مدافعين، مهاجم)
بهذه الطريقة ستضاف النقاط التي أحرزتها عن طريق لاعبي دكة البدلاء في الجولة إلى مجموع نقاطك.
يتم الإستبدال التلقائي بعد نهاية كل جولة.</p> 

                                        <b>القواعد و المعلومات</b>
                                        <p>
                                        ١-لا يوجد نظام للبونص في كووورة فانتازي. <br/>
                                        ٢-إذا ربح لاعب ركلة جزاء أو ركلة حرة تم تحويلها إلى هدف لا يحصل على نقاط أسيست. <br/>
                                        ٣-إذا تسبب مهاجم في إحراز مدافع الخصم لهدف في مرماه، لا يحصل اللاعب على نقاط أسيست. <br/>
                                        ٤-أسعار اللاعبين لا تزيد أو تقل تبعا لإقبال المستخدمين على شرائهم وبيعهم. <br/>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                )}
            </MyContext.Consumer>

        )
    }
}