/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import WSManager from "./helper/WSManager";
import { API } from './helper';
import * as NC from "./helper/NetworkingConstants";
import _ from "lodash";

import * as translationEN from './locale/en'
import * as translationAR from './locale/ar_AE'

import Login from './Modals//login';
import Signup from './Modals/signup';
import Forgot from './Modals/forgot-password';
import ThankyouSignup from './Modals/thankyou-signup';

import ThankyouForgot from './Modals/thankyou-forgot';

import PlayerCard from './Modals/PlayerCard';
import JoinLeagueCode from './Modals/JoinLeagueCode';
import InviteFriend from './Modals/InviteFriend';
import { notify } from 'react-notify-toast';


import ThankyouPickTeam from './Modals/thankyou-pickteam';


let createHistory = require("history").createBrowserHistory
const history = createHistory();
const pathToRegexp = require('path-to-regexp');
const location = history.location;

const queryString = require('query-string');
const parsed = queryString.parse(location.search);

// Create new context
export const MyContext = React.createContext()

const user_join_count = localStorage.getItem('user_join_count')
// Then create a Provider Component
export class MyProvider extends Component {

    setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getLanguageInit = () => {
        let val = null
        if (parsed.lang != undefined || parsed.isMobile != undefined) {
            val = parsed.lang == 'ar' ? 'ar' : 'en';
        } else {
            val = this.getCookie('lang') === '' ? 'ar' : this.getCookie('lang');
        }
        return val;
    }

    state = {
        language: this.getLanguageInit(),
        locale: '',
        format: 'MMM D,ddd, h:mm a',
        loggedInStatus: WSManager.loggedIn(),
        userInfo: WSManager.getProfile(),
        user_join_count: _.isUndefined(user_join_count) ? 0 : user_join_count,
        joined_week: '',
        week_info: {},
        
        // Modal(s)
        LoginModalShow: false,
        SignupModalShow: false,
        ForgotModalShow: false,
        ThankyouSignupModalShow: false,
        ThankyouForgotModalShow: false,
        
        ThankyouPickTeamModalShow: false,

        PlayerCardModalShow: false,
        isPlayerCardFieldView: false,
        JoinLeagueCodeShow: false,
        InviteFriendShow: false,

        BucketData: {},
        PlayerData: [],
        join_code: ''
    }

    modalShow = (Modal, BucketData) => {
        this.setState({
            [Modal]: true,
            BucketData: BucketData || {}
        });
    }
    modalHide = (Modal, BucketData) => {
        this.setState({
            [Modal]: false,
            BucketData: BucketData || {}
        }, () => {
            if(Modal == 'ThankyouPickTeamModalShow') {
                history.push('/my-team')
                setTimeout(() => {
                    history.go()
                }, 10)
            }
        });
    }
    loggedInStatusUpdate = () => {
        this.setState({
            loggedInStatus: WSManager.loggedIn(),
            userInfo: WSManager.getProfile()
        })
        if (this.state.join_code != '') {
            this.checkEligibility(this.state.join_code);
        }
    }
    _displayPosition = (pos, type, CONTEXT, single) => {
        let display_pos = type != 'NAME' ? NC.DISPLAYNAME_GOALKEEPER : NC.DISPLAYNAME_GOALKEEPER_FULL
        switch (pos) {
            case NC.DEFENDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_DEFENDER : NC.DISPLAYNAME_DEFENDER_FULL
                break;
            case NC.MIDFIELDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_MIDFIELDER : NC.DISPLAYNAME_MIDFIELDER_FULL
                break;
            case NC.FORWARDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_FORWARDER : NC.DISPLAYNAME_FORWARDER_FULL
                break;
            default:
                break;
        }
        if (CONTEXT) {
            display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_GOALKEEPER : CONTEXT.DISPLAYNAME_GOALKEEPER_FULL
            switch (pos) {
                case NC.DEFENDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_DEFENDER : CONTEXT.DISPLAYNAME_DEFENDER_FULL
                    break;
                case NC.MIDFIELDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_MIDFIELDER : CONTEXT.DISPLAYNAME_MIDFIELDER_FULL
                    break;
                case NC.FORWARDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_FORWARDER : CONTEXT.DISPLAYNAME_FORWARDER_FULL
                    break;
                default:
                    break;
            }            
        }
        if (single) {
            display_pos = display_pos.slice(0, -1);
        }
        return display_pos;
    }
    playerCardModalInit = (Modal, PlayerData, isPlayerCardFieldView) => {
        this.setState({
            [Modal]: true,
            PlayerData: PlayerData || {},
            isPlayerCardFieldView: isPlayerCardFieldView || false
        });
    }


    // LOCALE METHOD(S)
    selectedLocale = (language) => {
        let lang = '';
        switch (language) {
            case 'en':
                lang = translationEN.default;
                break;

            default:
                lang = translationAR.default;
                break;
        }
        return lang;
    }

    changeLanguage = (e) => {
        document.documentElement.setAttribute("lang", e);
        document.documentElement.setAttribute("dir", e == 'ar' ? 'rtl' : 'ltr');
        this.setCookie('lang', e, 365);
        localStorage.setItem("lang", e);
        this.setState({
            language: e,
            locale: this.selectedLocale(e)
        })
        // NC.Language.update(this.selectedLocale(e));
        history.go();
    }

    changeDocumentTitle = (title) => {
        if (title) {
            document.title = title + ' | AGL';
        } else {
            document.title = "Kooora Fantasy";
        }
    }

    LineupMasterIdUpdate = (responseJson) => {
    
        localStorage.setItem('user_join_count', responseJson.data.user_join_count);
        localStorage.setItem('week_info', JSON.stringify(responseJson.data.week_info));
        
        this.setState({
           
            user_join_count: responseJson.data.user_join_count,
            joined_week: responseJson.data.team_info.joined_week,
            week_info: responseJson.data.week_info,
        })
        if (!_.isUndefined(responseJson.data.team_info)) {
            var lineup_master_id = responseJson.data.team_info.lineup_master_id;
            localStorage.setItem('lineup_master_id', lineup_master_id);
        }
    }

    // FOR GET LINEUP_MASTER_ID
    setLineupMasterId = () => {
        if(WSManager.getMultiLegData() && WSManager.getMultiLegData().sports_id)
        {
         
             let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
        }
        WSManager.Rest(API.MY_CURRENT_LINEUP, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                if (responseJson.data.user_join_count == 1) {
                   this.LineupMasterIdUpdate(responseJson)
                }
            }
        })
        }
    }

    // LIFECYLE METHOD(s)
    componentWillMount = () => {
        document.documentElement.setAttribute("lang", this.state.language);
        document.documentElement.setAttribute("dir", this.state.language == 'ar' ? 'rtl' : 'ltr');
        this.setCookie('lang', this.state.language, 365);
        this.setState({
            locale: this.selectedLocale(this.state.language)
        })
        NC.Language.update(this.selectedLocale(this.state.language));
        
        if (WSManager.loggedIn()) {
            
            this.setLineupMasterId()
        }

        const re = pathToRegexp('/home/invite/:join_code')
        const invite_result = re.exec(window.location.pathname)
        if (invite_result) {
            this.setState({
                join_code: invite_result[1]
            })
            this.checkEligibility(invite_result[1])
        }
        
    }

    checkEligibility(code) {
        try{
            let param = {
                "league_id": WSManager.getMultiLegData().league_id,
                "join_code": code
            }
            WSManager.Rest(API.CHECK_ELIGIBILITY, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    let data = responseJson.data;
                    if (!_.isNull(data.global_lineup_master_id)) {
                        let join_game_param = {
                            // "league_id": NC.leagueId,
                            // "sports_id": NC.sportsId,
                            "sports_id": WSManager.getMultiLegData().sports_id,
                            "league_id": WSManager.getMultiLegData().league_id,
                            "contest_id": data.contest_id,
                            "lineup_master_id": data.global_lineup_master_id
                        }
                        WSManager.Rest(API.JOIN_CONTEST, join_game_param).then((responseJson) => {
                            if (responseJson.response_code === NC.successCode) {
                                notify.show(responseJson.message, "success", 5000);
                                history.push('/home')
                            }
                        })
                    }
    
                } else if (responseJson.response_code === NC.AUTHENTICATE_REQUIRE_CODE) {
                    setTimeout(() => {
                        this.setState({ LoginModalShow: true });
                    }, 1000);
                }
            })
        }catch(e){
console.log('Error inside checkEligibility');
        }
        
    }

    render() {

        // Modal(s) Props
        const LoginProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            LoginModalShow: this.state.LoginModalShow,
            BucketData: this.state.BucketData,
            loggedInStatusUpdate: this.loggedInStatusUpdate,

        }
        const SignupProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            SignupModalShow: this.state.SignupModalShow,
            BucketData: this.state.BucketData,
            loggedInStatusUpdate: this.loggedInStatusUpdate
        }
        const ForgotProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            ForgotModalShow: this.state.ForgotModalShow,
            BucketData: this.state.BucketData
        }
        const ThankyouSignupProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            ThankyouSignupModalShow: this.state.ThankyouSignupModalShow,
            BucketData: this.state.BucketData
        }
        const JoinLeagueCodeProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            JoinLeagueCodeShow: this.state.JoinLeagueCodeShow,
            BucketData: this.state.BucketData
        }
        const InviteFriendProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            InviteFriendShow: this.state.InviteFriendShow,
            BucketData: this.state.BucketData
        }
        const ThankyouForgotProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            ThankyouForgotModalShow: this.state.ThankyouForgotModalShow,
            BucketData: this.state.BucketData
        }

        const ThankyouPickTeamProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            ThankyouPickTeamModalShow: this.state.ThankyouPickTeamModalShow,
            BucketData: this.state.BucketData
        }


        const PlayerCardProps = {
            modalHide: this.modalHide,
            _displayPosition: this._displayPosition,
            PlayerCardModalShow: this.state.PlayerCardModalShow,
            PlayerData: this.state.PlayerData,
            isPlayerCardFieldView: this.state.isPlayerCardFieldView,
            LineupUpdate: function () { },
            rosterContainsObject: function () { },
            AddPlayerToLineup: function () { },
            RemovePlayerFromLineup: function () { }
        }
        const {
            LoginModalShow,
            SignupModalShow,
            ForgotModalShow,
            ThankyouSignupModalShow,
            ThankyouForgotModalShow,
            
            ThankyouPickTeamModalShow,

            PlayerCardModalShow,
            JoinLeagueCodeShow,
            InviteFriendShow
        } = this.state;


        // Context Props
        const ContextProps = {
            state: this.state,
            locale: this.state.locale,

            changeLanguage: this.changeLanguage,
            changeDocumentTitle: this.changeDocumentTitle,

            modalShow: this.modalShow,
            playerCardModalInit: this.playerCardModalInit
        }
        
        return (
            <MyContext.Provider value={ContextProps}>
                {this.props.children}

                {/* MODALS */}
               
                {LoginModalShow && <Login {...LoginProps}/>}
                {SignupModalShow && <Signup {...SignupProps} />}
                {ForgotModalShow && <Forgot {...ForgotProps} />}
                {ThankyouSignupModalShow && <ThankyouSignup {...ThankyouSignupProps} />}
                {JoinLeagueCodeShow && <JoinLeagueCode {...JoinLeagueCodeProps} />}
                {InviteFriendShow && <InviteFriend {...InviteFriendProps} />}
                {ThankyouForgotModalShow && <ThankyouForgot {...ThankyouForgotProps} />}

                {ThankyouPickTeamModalShow && <ThankyouPickTeam {...ThankyouPickTeamProps} />}
                
                {PlayerCardModalShow && <PlayerCard locale={this.state.locale} language={this.state.language} {...PlayerCardProps}/>}

            </MyContext.Provider>
        )
    }
}