/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';
import { Row, Col, Tab, NavItem, Nav } from 'react-bootstrap';

import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { notify } from 'react-notify-toast';
import Alert from 'react-s-alert';
import Drawer from 'react-motion-drawer';

import { MyContext } from '../context';

import DeadlineClock from '../views/DeadlineClock';
import PitchViewField from '../views/PitchView';
import PitchViewLoader from '../views/PitchViewLoader';
import ListViewField from '../views/ListViewField';
import Boosters from '../views/Boosters';
import FieldControls from '../views/FieldControls';
import Roster from '../views/Roster';
import LineupInfo from '../views/LineupInfo';

import PlayerCard from '../Modals/PlayerCard';
import CreateTeam from '../Modals/create-team';
import FixtureModal from '../Modals/fixture';
import FreehitModal from '../Modals/FreehitModal';
import TransferUpdateModal from '../Modals/TransferUpdateModal';
import ThankyouCreateTeamModal from '../Modals/thankyou-create-team';
// import Images from '../components/images';


import RosterLoader from '../views/RosterLoader';
// import { Helmet } from "react-helmet";
import SimpleImageSlider from "ac-react-simple-image-slider";


import ReactGA from 'react-ga'; 
import AdsContainer from '../components/AdsContainer';
ReactGA.initialize('UA-177410945-1');
ReactGA.pageview(window.location.pathname + window.location.search);

var durationForBanner = 10;
// var durationForBanner

 




export default class MyTeam extends React.Component {

    // Methods
    // ------------------------------

    // GET_ALL_TEAM
    getAllTeam = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            
        }
        let s3_param = [
            API.S3_GET_ALL_TEAM,
            // NC.sportsId,
            // NC.leagueId
            WSManager.getMultiLegData().sports_id,
            WSManager.getMultiLegData().league_id,
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                const AllTeam = {
                    "label": this.props.locale.MyTeam.all_teams,
                    "value": ""
                }
                let result = []
                responseJson.data.result.map((data, key) => {
                    if (data.is_eliminate != 1)
                    {
                        data.label = data.team_name;
                        data.value = data.team_id;
                        // return data;
                        result.push(data)
                    }

                    return data
                })

                // const team_list = _.clone(responseJson.data.result);
                // const team_list_club = _.clone(responseJson.data.result);
                const team_list = _.clone(result);
                const team_list_club = _.clone(result);


                this.setState({
                    ClubTeamList: team_list_club
                });


                team_list.unshift(AllTeam);
                this.setState({
                    TeamList: team_list,
                });

            }
        })
    }

    // GET_LINEUP_MASTER_DATA
    getLineupMasterData = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            
        }
        WSManager.Rest(API.GET_LINEUP_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {

                let AllPosition = {
                    "value": "",
                    "label": this.props.locale.MyTeam.all_players,
                    "max_player_per_position": "15"
                }
                responseJson.data.all_position.map((data, key) => {
                    data.label = data.position_display_name;
                    data.value = data.position;
                    return data;
                })

                responseJson.data.all_position.unshift(AllPosition)
                this.setState({
                    LineupMasterData: responseJson.data,
                    AllPositionList: responseJson.data.all_position,
                    LineupFormationList: responseJson.data.lineup_formation,
                    AlreadyLineupCreate: responseJson.data.lineup_master_id,
                    AllPositionArr : [{
                    
                        max_player_per_position: Number(responseJson.data.team_player_count) + Number(responseJson.data.lineup_formation.lineup_bench_size),
                        position: ""
                      }, ...responseJson.data.all_position]
                });
                sessionStorage.setItem('SelectedFormation', responseJson.data.default_formation);
                if (WSManager.loggedIn()) {
                    this.getManageTeamMaster();
                } else {
                    if (_.isNull(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'))) {
                        this.LineupCreate(this.state.LineupFormationList)
                    } else {
                        var lineup = JSON.parse(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'));
                        var selected_formation = responseJson.data.lineup_formation.formation[this.state.SelectedFormation];
                        this.LineupCreate(selected_formation, lineup);
                    }
                    this.setState({ isLoaderShow: false });
                }
            }
        })

    }

    // GET_MANAGE_TEAM_MASTER_DATA
    getManageTeamMaster = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
             "is_performance_required": 1,
        }
        WSManager.Rest(API.GET_MANAGE_TEAM_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    user_join_count: responseJson.data.user_join_count,
                    my_performance: responseJson.data.my_performance,
                    active_week: responseJson.data.active_week,
                    SelectedWeek: responseJson.data.next_week,
                    next_week: responseJson.data.next_week,
                    next_week_start_time: responseJson.data.next_week_start_time,
                    contest_id: responseJson.data.contest_id,
                    lineup_master_id: responseJson.data.lineup_master_id
                }, () => this.getAllTeam())
                this._isFullFieldViewMode();
                const { AlreadyLineupCreate } = this.state;

                localStorage.setItem('lineup_master_id', AlreadyLineupCreate);
                localStorage.setItem('user_join_count', responseJson.data.user_join_count);

                if (AlreadyLineupCreate != '' && responseJson.data.user_join_count == 0) {
                    let join_game_param = {
                        // "league_id": NC.leagueId,
                        // "sports_id": NC.sportsId,
                        "sports_id" : WSManager.getMultiLegData().sports_id,
                        "league_id" : WSManager.getMultiLegData().league_id,
                        "contest_id": responseJson.data.contest_id,
                        "lineup_master_id": AlreadyLineupCreate
                    }
                    WSManager.Rest(API.JOIN_CONTEST, join_game_param).then((responseJson) => {
                        if (responseJson.response_code === NC.successCode) {
                            localStorage.setItem('user_join_count', 1);
                        }
                    })
                } else {
                    if (responseJson.data.user_join_count != 0) {
                        this.getUserLineupDetail({
                            contest_id: responseJson.data.contest_id,
                            lineup_master_id: responseJson.data.lineup_master_id,
                            active_week: responseJson.data.next_week
                        })
                    } else {
                        if (_.isNull(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'))) {
                            this.LineupCreate(this.state.LineupFormationList)
                        } else {
                            var lineup = JSON.parse(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'));
                            var selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                            this.LineupCreate(selected_formation, lineup);
                        }
                    }
                }
            }
        })
    }

    getUserLineupDetail = (options) => {
        const { contest_id, active_week, lineup_master_id } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_LINEUP_DETAIL, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                var selected_formation = !_.isEmpty(this.state.LineupFormationList) ? this.state.LineupFormationList.formation[data.formation] : {};
                this.setState({
                    team_name: data.team_name,
                    default_formation: data.default_formation,
                    manage_team_name: data.team_name,
                    SelectedFormation: data.formation
                }, () => {
                    sessionStorage.setItem('SelectedFormation', data.formation);
                    _.map(responseJson.data.lineup, (player) => {
                        player.display_position = this._displayPosition(player.position)
                    })
                    this.LineupCreate(selected_formation, data.lineup);
                    this.getUserTeamInfo(options)
                })
            }
        })
    }

    getUserTeamInfo = (options) => {
        const { contest_id, active_week, lineup_master_id } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_TEAM_INFO, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                this.setState({
                    team_info: data.team_info,
                    boosterApplied: data.team_info.booster_applied,
                    is_editable: data.team_info.is_editable,
                    show_transfer: data.team_info.show_transfer,
                }, () => {
                    this._isFullFieldViewMode();
                    this.getSelectedBooster(data.team_info);
                })
            }
        })

    }

    // GET_AUTOFILL_LINEUP_DATA
    AutoCompleteLineupCreate = () => {
        let filterLineup = _.filter(this.state.LineupsList, function (item) {
            return item.player_uid
        })
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "lineup": filterLineup
        }
        this.setState({
            FieldLoader: true,
            PitchViewLoaderInit: true
        });
        WSManager.Rest(API.GET_AUTOFILL_LINEUP_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                // let selected_formation = sessionStorage.SelectedFormation == '4-4-2' ? responseJson.data.selected_formation : sessionStorage.SelectedFormation;
                let selected_formation = responseJson.data.selected_formation;
                let selected_formation_Obj = this.state.LineupFormationList.formation[selected_formation];

                _.map(responseJson.data.lineup_player, (player) => {
                    player.display_position = this._displayPosition(player.position);

                })
                /* for auto pick new functionality */
                if (this.state.LineupsList.length > 0) {
                    const globalthis = this;
                    _.map(responseJson.data.lineup_player, function (item) {
                        globalthis.AddPlayerToLineup(item)
                    });
                } else {
                    this.LineupCreate(selected_formation_Obj, responseJson.data.lineup_player);
                }
                /* ------- */
                this.setState({
                    FieldLoader: false,
                    SelectedFormation: selected_formation,
                    PitchViewLoaderInit: false
                });
                sessionStorage.setItem('SelectedFormation', selected_formation);
            }
        })
    }
    // LINEUP CREATE
    rosterContainsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list[x].player_uid == obj.player_uid) {
                return true;
            }
        }
        return false;
    }
    containsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list.hasOwnProperty(x) && list[x] === obj) {
                return true;
            }
        }
        return false;
    }
    isLineupView = () => {
        let param_lineup_master_id = this.props.match.params.lineup_master_id;
        let isOwnLineupView = param_lineup_master_id ? localStorage.getItem('lineup_master_id') == param_lineup_master_id : true;
        this.setState({
            param_lineup_master_id: param_lineup_master_id,
            isOwnLineupView: isOwnLineupView
        });
    }
    _isFullFieldViewMode = () => {
        this.setState({
            isFullFieldViewMode: (this.state.is_editable == 1 && this.state.show_transfer == 0 && this.state.user_join_count == 0)
        })
    }
    _availableBudget = (LineupsList) => {
        let Budget = _.sumBy(LineupsList, function (o) { return parseFloat(o.salary, 10); })
        return WSManager.getMultiLegData().salary_cap[this.state.active_week] - Budget;
    }
    isSelectAnyPlayer = (data) => {
        if (_.isEmpty(data)) return 0;
        var result = [];
        result = data.filter(function (obj) {
            return obj.player_uid != undefined;
        })
        return result.length;
    }
    SelectedPositionCount = (data, val) => {
        var result = [];
        if (val.position == undefined) {
            result = data.filter(function (obj) {
                return obj.player_uid != undefined
            })
        } else {
            result = data.filter(function (obj) {
                return obj.position == val.position && obj.player_uid != undefined
            })
        }
        return result.length;
    }
    _displayPosition = (pos, type, CONTEXT, single) => {
        let display_pos = type != 'NAME' ? NC.DISPLAYNAME_GOALKEEPER : NC.DISPLAYNAME_GOALKEEPER_FULL
        switch (pos) {
            case NC.DEFENDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_DEFENDER : NC.DISPLAYNAME_DEFENDER_FULL
                break;
            case NC.MIDFIELDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_MIDFIELDER : NC.DISPLAYNAME_MIDFIELDER_FULL
                break;
            case NC.FORWARDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_FORWARDER : NC.DISPLAYNAME_FORWARDER_FULL
                break;
            default:
                break;
        }
        if (CONTEXT) {
            display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_GOALKEEPER : CONTEXT.DISPLAYNAME_GOALKEEPER_FULL
            switch (pos) {
                case NC.DEFENDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_DEFENDER : CONTEXT.DISPLAYNAME_DEFENDER_FULL
                    break;
                case NC.MIDFIELDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_MIDFIELDER : CONTEXT.DISPLAYNAME_MIDFIELDER_FULL
                    break;
                case NC.FORWARDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_FORWARDER : CONTEXT.DISPLAYNAME_FORWARDER_FULL
                    break;
                default:
                    break;
            }
        }
        if (single) {
            display_pos = display_pos.slice(0, -1);
        }
        return display_pos;
    }
    _getMaxPlayerPosition = (pos) => {
        let max_pos = '';
        switch (pos) {
            case NC.DEFENDER:
                max_pos = NC.DF
                break;
            case NC.MIDFIELDER:
                max_pos = NC.MF
                break;
            case NC.FORWARDER:
                max_pos = NC.FW
                break;
            case NC.GOALKEEPER:
                max_pos = NC.GK
                break;
            default:
                break;
        }
        return max_pos;
    }
    _randomString = (length, chars) => {
        var mask = '';
        if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
        if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (chars.indexOf('#') > -1) mask += '0123456789';
        if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        var result = '';
        for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
        return result;
    }
    _addPlayer = (Obj) => {
        let LineupObj = {
            "player_id": this._randomString(5, '#aA'),
            "position": Obj.position,
            "salary": 0,
            "display_position": this._displayPosition(Obj.position),
            "is_bench_player": Obj.is_bench_player,
            "max_player_per_position": this._getMaxPlayerPosition(Obj.position)
        }
        return LineupObj;
    }
    _handleShowPlayerAlert = (playerName, type) => {
        let addedText = this.props.language == 'en' ? 'has been added to your roster' : 'تمت إضافته إلى قائمتك';
        let RemovedText = this.props.language == 'en' ? 'has been removed from your roster' : 'تمت إزالة من قائمة';
        switch (type) {
            case 'success':
                Alert.success('<b>' + playerName + '</b> ' + addedText);
                break;
            case 'error':
                Alert.error('<b>' + playerName + '</b> ' + RemovedText);
                break;
            case 'warning':
                Alert.warning('<b>' + playerName + '</b> ' + RemovedText);
                break;
            default:
                Alert.info('<b>' + playerName + '</b> ' + RemovedText);
                break;
        }
    }
    _handleShowAlert = (message, type) => {
        switch (type) {
            case 'success':
                Alert.success(message);
                break;
            case 'error':
                Alert.error(message);
                break;
            case 'warning':
                Alert.warning(message);
                break;
            default:
                Alert.info(message);
                break;
        }
    }
    playerNameSort = (name) => {
        if (!name) return
        var str = name.split(' ');
        str[0] = str[0].charAt(0)
        return str[0] + '. ' + str[str.length - 1]
    }
    _selectRandomCaptain = (Player, Lineuplist) => {
        var filterArr = Lineuplist.filter(function (item) {
            return item.position == Player.position && Player.is_bench_player == 0
        })
        filterArr[0].player_role = '1';
    }
    LineupCreate = (FormationList, Lineuplist, isFormationProcess) => {
        this._isFullFieldViewMode()
        const Lineup = [];
        // console.log("FormationList", JSON.stringify(FormationList))
        if (Lineuplist != undefined) {
            let CurrentFormation = FormationList;
            if (CurrentFormation != null) {
                for (let i = 0; i < CurrentFormation.length; i++) {
                    const element = CurrentFormation[i];
                    var filterArr = Lineuplist.filter(function (item) {
                        return item.position == element.position
                    })
                    _.map(filterArr, (Player, idx) => {

                        if (isFormationProcess) {
                            Player.is_bench_player = '0'
                        }

                        if (idx < element.number_of_players) {
                            Lineup.push(Player);
                        } else {
                            if (isFormationProcess) {
                                if (Player.player_role == 1) {
                                    this._selectRandomCaptain(Player, Lineup)
                                    Player.player_role = '0'
                                }
                                Player.is_bench_player = '1'
                            }
                            Lineup.push(Player);
                        }
                    })
                }
            }

        } else {
            let CurrentFormation = FormationList.formation[this.state.SelectedFormation]
            for (let i = 0; i < CurrentFormation.length; i++) {
                const element = CurrentFormation[i];
                element.is_bench_player = '0';
                if (this.state.isFullFieldViewMode) {
                    for (let j = 0; j < CurrentFormation[i].max_player_per_position; j++) {
                        Lineup.push(this._addPlayer(element));
                    }
                } else {
                    for (let j = 0; j < CurrentFormation[i].number_of_players; j++) {
                        Lineup.push(this._addPlayer(element));
                    }
                    for (let j = 0; j < CurrentFormation[i].number_of_bench_player; j++) {
                        element.is_bench_player = '1';
                        Lineup.push(this._addPlayer(element));
                    }
                }
            }
        }
        sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(Lineup));
        this.setState({
            LineupsList: Lineup,
            isLoaderShow: false,
            isRosterViewShow: true,
            PitchViewLoaderInit: false,
            lineup_lastupdate: new Date().valueOf()
        }, () => {
            const { LineupsList } = this.state;
            let RuleViolatePlayer = _.filter(LineupsList, (o) => o.is_rule_violate != 0)
            let ClubChangePlayer = _.filter(LineupsList, (o) => o.is_club_change != 0)
            let ClubRemovePlayer = _.filter(LineupsList, (o) => o.is_club_remove != 0)
            // this.setState({
            //     TransferUpdateModalShow: RuleViolatePlayer.length > 0,
            // })
            
            this.setState({
                isRulesViolates: RuleViolatePlayer.length > 0,
                isClubChanged: ClubChangePlayer.length > 0,
                isClubRemoved: ClubRemovePlayer.length > 0
            })
        });
        if (_.isEmpty(this.state.LineupsListClone)) {
            this.setState({
                LineupsListClone: _.cloneDeep(Lineup)
            });
        }
    }
    LineupUpdate = (lineup, isSwap = false) => {
        let SelectedFormation = this.getPosibleFormation()
        var SelectedFormationObj = this.state.LineupFormationList.formation[SelectedFormation];
        this.LineupCreate(SelectedFormationObj, lineup);
        if (isSwap) {
            this.setState({ isSwapped: true })
        }
    }
    UndoMychanges = () => {
        this.setState({
            SelectedBooster: '',
            PitchViewLoaderInit: true
        });
        setTimeout(() => {
            this.setState({
                LineupsList: _.cloneDeep(this.state.LineupsListClone),
                PitchViewLoaderInit: false
            });
        }, 500);
        this.getSelectedBooster(this.state.team_info);
    }

    ResetChanges = (LineupFormationList) => {
        this.LineupCreate(LineupFormationList)
        this.setState({
            PitchViewLoaderInit: true
        });
        setTimeout(() => {
            this.setState({
                PitchViewLoaderInit: false
            });
        }, 500);
    }

    getPosibleFormation = () => {
        let LineupsList = this.state.LineupsList;
        let DEFENDER_FILTER = _.filter(LineupsList, (o) => { return o.position == NC.DEFENDER && o.is_bench_player == 0 }).length;
        let MIDFIELDER_FILTER = _.filter(LineupsList, (o) => { return o.position == NC.MIDFIELDER && o.is_bench_player == 0 }).length;
        let FORWARDER_FILTER = _.filter(LineupsList, (o) => { return o.position == NC.FORWARDER && o.is_bench_player == 0 }).length;

        return DEFENDER_FILTER + '-' + MIDFIELDER_FILTER + '-' + FORWARDER_FILTER;
    }



    AddPlayerToLineup = (Player) => {
        this.setState({ isPlayerPosting: false });
        let LineupsList = this.state.LineupsList;
        let TransferPlayerIn = this.state.TransferPlayerIn;
        let i = 0;

        if (this._availableBudget(LineupsList) < parseInt(Player.salary, 10)) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_BUDGET_INSUFFICIENT, "warning");
            return false;
        }
        if (this.isSelectAnyPlayer(LineupsList) == LineupsList.length) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_LINEUP_FULL, "warning");
            return false;
        }
        var filterArr = LineupsList.filter(function (item) {
            return item.team_league_id == Player.team_league_id
        })
        if (filterArr.length == this.state.LineupMasterData.max_player_per_team) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_MAX_PLAYER_PER_TEAM, "warning");
            return false;
        }
        if (this.SelectedPositionCount(LineupsList, Player) == NC[Player.position]) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_MAX_POSITION_SELECTED, "warning");
            return false;
        }

        while (i < LineupsList.length) {
            if (LineupsList[i].position == Player.position && !LineupsList[i].player_uid) {
                Player.display_position = LineupsList[i].display_position;
                let selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];

                let filterPosFormation = _.filter(selected_formation, function (o) {
                    return o.position == Player.position
                })

                if (this.SelectedPositionCount(LineupsList, Player) + 1 > filterPosFormation[0]['number_of_players']) {
                    if (this.state.isFullFieldViewMode) {

                    } else {
                        LineupsList[i].is_bench_player = 1
                    }
                }
                Player.is_bench_player = LineupsList[i].is_bench_player;

                if (this.containsObject(Player, LineupsList)) return

                if (this.state.isMakeTransferActive && Player.is_transfer_player) {
                    TransferPlayerIn.push(Player)
                }

                LineupsList[i] = Player;
                let Player_short_name = this.playerNameSort(Player.full_name);
                this._handleShowPlayerAlert(Player_short_name, 'success');
                this.setState({ isPlayerPosting: true });
                break;
            } else {
                this.setState({ isPlayerPosting: true });
            }
            i++;
        }
        this.setState({ LineupsList: LineupsList });
        sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(LineupsList));
    }
    RemovePlayerFromLineup = (Player) => {
        let TransferPlayerOut = this.state.TransferPlayerOut;
        let TransferPlayerIn = this.state.TransferPlayerIn;
        let LineupsList = this.state.LineupsList;

        for (let i = 0; i < LineupsList.length; i++) {
            if (Player.player_uid == LineupsList[i].player_uid) {
                let selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                let filterPosFormation = _.filter(selected_formation, function (o) {
                    return o.position == Player.position
                })

                if (this.state.isMakeTransferActive && !Player.is_transfer_player) {
                    TransferPlayerOut.push(Player)
                } else {
                    let idx = _.indexOf(TransferPlayerIn, Player.player_uid);
                    TransferPlayerIn.splice(idx, 1);
                }

                LineupsList[i].is_bench_player = 0;
                LineupsList[i].max_player_per_position = filterPosFormation[0]['max_player_per_position'];
                LineupsList[i] = this._addPlayer(LineupsList[i])

                let Player_short_name = this.playerNameSort(Player.full_name);
                this._handleShowPlayerAlert(Player_short_name, 'error');

                this.setState({ LineupsList: LineupsList });
                sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(LineupsList));
            }
        }
    }
    PositionSelectChange = (SelectedPlayerPosition) => {
        let all_position = this.state.LineupMasterData.all_position
        let fltPosition = _.filter(all_position, function (o) {
            return o.position == SelectedPlayerPosition.position
        })
        this.RosterRef.current.handleSelectChange('SelectedPlayerPosition', fltPosition[0]);
        this.DrawerRosterTrigger(false);
    }

    LineupProccessInit = (delayInms) => {
        this.setState({ FieldLoader: true });
        const { LineupsList, team_info } = this.state;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id" : this.state.contest_id,
            "selected_formation": this.getPosibleFormation(),
            "lineup": LineupsList,
            "team_league_id": '',
            "team_name": '',
            "lineup_master_id": team_info.lineup_master_id
        }
        WSManager.Rest(API.LINEUP_PROCCESS, param, true).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                notify.show(responseJson.message, "success", 5000);
                this.setState({
                    LineupsListClone: _.cloneDeep(this.state.LineupsList),
                    FieldLoader: false,
                    SelectedFormation: this.getPosibleFormation(),
                    isSwapped: false
                });
                sessionStorage.setItem('SelectedFormation', this.getPosibleFormation());
            } else {
                this.setState({
                    FieldLoader: false,
                    // isSwapped: false
                });
                setTimeout(() => {
                    notify.show(responseJson.message, "error", 5000);
                }, delayInms || 0)
            }
        })
    }

    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }
    playerCardModalInit = (Modal, PlayerData, isPlayerCardFieldView) => {
        this.setState({
            [Modal]: true,
            PlayerData: PlayerData || {},
            isPlayerCardFieldView: isPlayerCardFieldView || false
        });
    }
    ConfirmMyteam = () => {
        if (this.state.SelectedBooster != '' && !_.isEqual(this.state.LineupsListClone, this.state.LineupsList)) {
            this.ApplyBooster(900, true);
            this.LineupProccessInit(1500);
        } else if (this.state.SelectedBooster != '' && _.isEqual(this.state.LineupsListClone, this.state.LineupsList)) {
            this.ApplyBooster(900, true);
        } else if (this.state.SelectedBooster == '') {
            this.LineupProccessInit();
        }
    }
    ApplyBooster = (duration, lineupUpdate) => {
        if (this.state.team_info.booster_applied == 0 && this.state.SelectedBooster != '') {
            this.BoosterRef.current.ApplyBooster(duration, lineupUpdate);
            this.setState({
                SelectedBooster: ''
            })
        }
    }
    ApplyBoosterCallBack = (lineupUpdate, reset) => {
        const { contest_id, lineup_master_id, next_week, team_info } = this.state;
        if (lineupUpdate) {
            this.getUserLineupDetail({
                contest_id: contest_id,
                lineup_master_id: lineup_master_id,
                active_week: next_week
            })
        }
        if (reset) {
            this.setState({
                SelectedBooster: ''
            }, () => {
                this.getSelectedBooster(team_info);
            })
        }
    }

    getSelectedBooster = (team_info) => {
        // team_info.bench_booster_status
        _.map(team_info, (item, key) => {
            switch (key) {
                case 'captain_booster_status':
                    if (item.is_applied == 1) {
                        this.setState({
                            SelectedCaptainBooster: 1,
                            BoosterApplied: 1
                        });
                    } else {
                        this.setState({
                            SelectedCaptainBooster: ''
                        });
                    }
                    break;
                case 'bench_booster_status':
                    if (item.is_applied == 1) {
                        this.setState({
                            SelectedBenchBooster: 2,
                            BoosterApplied: 1
                        });
                    } else {
                        this.setState({
                            SelectedBenchBooster: ''
                        });
                    }
                    break;
                case 'free_hit_booster_status':
                    if (item.is_applied == 1) {
                        this.setState({
                            SelectedFreehitBooster: 3,
                            BoosterApplied: 1
                        });
                    } else {
                        this.setState({
                            SelectedFreehitBooster: ''
                        });
                    }
                    break;

                default:
                    break;
            }
            return item;
        })
    }
    SelectBooster = (type, remove, SelectedBooste) => {
        if (this.state.SelectedBooster != '' && remove != '' && SelectedBooste != type) {
            if (!window.confirm(remove.locale.message.MSZ_SELECTED_BOOSTER)) {
                return;
            }
        }
        this.setState({
            SelectedBooster: remove == '' ? remove : type
        });
        switch (type) {
            case 1: // Triple Captain
                this.setState({
                    SelectedCaptainBooster: remove == '' ? remove : type,
                    SelectedBenchBooster: '',
                    SelectedFreehitBooster: '',
                }, () => this.ConfirmMyteam());
                break;
            case 2: // Bench Boost
                this.setState({
                    SelectedBenchBooster: remove == '' ? remove : type,
                    SelectedCaptainBooster: '',
                    SelectedFreehitBooster: '',
                }, () => this.ConfirmMyteam());
                break;
            case 3: // Freehit
                this.setState({
                    SelectedFreehitBooster: remove == '' ? remove : type,
                    SelectedCaptainBooster: '',
                    SelectedBenchBooster: '',
                }, () => this.ConfirmMyteam());
                break;

            default:
                break;
        }
    }
    RemoveSelectBooster = (type) => {
        switch (type) {
            case 1:
                if (this.state.team_info.captain_booster_status.applied_week == this.state.next_week) {
                    this.BoosterRef.current.RemoveBooster(type);
                }
                this.SelectBooster(type, '');
                break;
            case 2:
                if (this.state.team_info.bench_booster_status.applied_week == this.state.next_week) {
                    this.BoosterRef.current.RemoveBooster(type);
                }
                this.SelectBooster(type, '');
                break;
            case 3:
                if (this.state.team_info.free_hit_booster_status.applied_week == this.state.next_week) {
                    this.BoosterRef.current.RemoveBooster(type);
                }
                this.SelectBooster(type, '');
                break;

            default:
                break;
        }
    }
    DrawerRosterTrigger = (DrawerRosterOpen) => {
        this.setState({
            DrawerRosterOpen: !DrawerRosterOpen
        })
    }
    updateDimensions = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }
    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            // Flags
            is_editable: 1,
            show_transfer: 0,
            isMyTeamScreen: true,
            user_join_count: null,
            FieldLoader: false,
            PitchViewLoaderInit: true,
            CreateTeamModalShow: false,
            FixtureModalShow: false,
            ThankyouCreateTeamModalShow: false,
            FreehitModalShow: false,
            TransferUpdateModalShow: false,
            PlayerCardModalShow: false,
            isFullFieldViewMode: false,
            isPlayerCardFieldView: false,
            DrawerRosterOpen: false,
            defaultRosterWidth: 991,
            AllPositionArr: [],

            // Asyn data
            SelectedFormation: _.isNull(sessionStorage.getItem('SelectedFormation')) ? NC.DEFAULT_FORMATION : sessionStorage.getItem('SelectedFormation'),
            LineupFormationList: [],
            LineupsList: [],
            LineupsListClone: [],
            TransferPlayerIn: [],
            TransferPlayerOut: [],
            team_info: [],
            PlayerData: [],
            BoosterRef: [],
            SelectedBooster: '', // 1 (Triple Captain), 2 (Bench Boost), 3 (Free Hit)
            AlreadyLineupCreate: '',

            isSwapped: false,

            isRulesViolates: false,
            isClubChanged: false,
            isClubRemoved: false,
            lineup_lastupdate: new Date().valueOf()
        }
        this.RosterRef = React.createRef();
        this.BoosterRef = React.createRef();
        this.PitchViewFieldRef = React.createRef();

        


    }
    componentWillMount() {

// setTimeout(() => {
    // const tagManagerArgs = {
    //     gtmId: 'GTM-W46V3QD',
    //     events: {'gtm.start': new Date().getTime(), event: 'gtm.js'},
    //     dataLayerName: 'dataLayer',
    //     dataLayer: {
    //         userProject: 'fantasy',
    //         page: 'my-team'
    //     },
    //     dataLayerName: 'dataLayer'
    // }

    // TagManager.initialize(tagManagerArgs)
    // TagManager.dataLayer(tagManagerArgs)
// }, 2000);
        
        Promise.all([
            this.getLineupMasterData(),
        this.isLineupView(),
        this.updateDimensions(),
        ])
      
        let advBanner = WSManager.getAdBanner();
        if(advBanner && advBanner.advertisements != undefined)
        {
            this.callAdvBanner(advBanner);
        }
        
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        
        // setTimeout(() => {
        //     const tagManagerArgs = {
        //         gtmId: 'GTM-W46V3QD',
        //         events: {'gtm.start': new Date().getTime(), event: 'gtm.js'},
        //         dataLayerName: 'dataLayer',
        //         dataLayer: {
        //             userProject: 'fantasy',
        //             page: 'my-team'
        //         },
        //         dataLayerName: 'dataLayer'
        //     }

        //     TagManager.initialize(tagManagerArgs)
        //     TagManager.dataLayer(tagManagerArgs)
        // }, 2000);


        // const script = document.createElement("script");

        // script.src = "./AdScript.js";
        // script.async = true;

        // document.head.appendChild(script);

        
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    /**
     * ADVERSTMENT
     */
    callAdvBanner = (data) => {
                let bannerData = data.advertisements;
                let lowerBanner = [];
                let squareBanner = [];
                let topPtcBanner = [];
                let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 9 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        topPtcBanner.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 10 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        lowerBanner.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 11 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        squareBanner.push(bannerData[k])
                    }
                }
                this.setState({
                    lowerBanner: lowerBanner,
                    squareBanner:squareBanner,
                    topPtcBanner:topPtcBanner
                }, () => {
                    this.getBannerIndex();
                    // console.log(this.state.squareBanner,'sdssdfsquareBanner')
                })
    }

    /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
            this.task(this.state.lowerBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.lowerBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }

    getBannerClick = (from) => {
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }


    render() {
        const {
            TeamList,
            LineupMasterData,
            LineupsList,
            LineupsListClone,
            LineupFormationList,
            SelectedFormation,
            next_week,
            next_week_start_time,
            user_join_count,
            is_editable,
            show_transfer,
            isMyTeamScreen,
            FieldLoader,
            ClubTeamList,
            contest_id,
            CreateTeamModalShow,
            FixtureModalShow,
            TransferUpdateModalShow,
            ThankyouCreateTeamModalShow,
            FreehitModalShow,
            isFullFieldViewMode,
            team_info,
            team_name,
            my_performance,
            SelectedBooster,
            SelectedCaptainBooster,
            SelectedBenchBooster,
            SelectedFreehitBooster,

            PlayerCardModalShow,
            isPlayerCardFieldView,
            PlayerData,
            DrawerRosterOpen,
            PitchViewLoaderInit,

            active_week,
            SelectedWeek,
            AlreadyLineupCreate,


            isSwapped,
            lineup_lastupdate
        } = this.state;

        // Instance Properties
        // ------------------------------
        const RosterProps = {
            TeamList: TeamList,
            LineupsList: LineupsList,
            LineupMasterData: LineupMasterData,
            AddPlayerToLineup: this.AddPlayerToLineup,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            rosterContainsObject: this.rosterContainsObject,
            playerCardModalInit: this.playerCardModalInit,
            AllPositionList :this.state.AllPositionList,
            locale: this.props.locale,
            language: this.props.language, 
            fromWindow : 0,
        }
        const RosterDrawerProps = {
            ...this.props,
            overlayColor: "rgba(0,0,0,0.8)",
            width: 320,
            right: true,
            fadeOut: true,
            open: DrawerRosterOpen,
            noTouchOpen: false
        };
        const FieldViewProps = {
            lineups: LineupsList,
            SelectedFormation: SelectedFormation,
            user_join_count: user_join_count,
            is_editable: is_editable,
            show_transfer: show_transfer,
            isMyTeamScreen: isMyTeamScreen,
            isMyTeam: true,
            active_week: active_week,
            SelectedWeek: SelectedWeek,

            team_info: team_info,
            isFullFieldViewMode: isFullFieldViewMode,
            playerNameSort: this.playerNameSort,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            PositionSelectChange: this.PositionSelectChange,
            displayPosition: this._displayPosition,
            playerCardModalInit: this.playerCardModalInit,
            modalHide: this.modalHide,
            DrawerRosterTrigger: this.DrawerRosterTrigger,
            LineupUpdate: this.LineupUpdate,
            isSwapingEnabled: true,
            lineup_lastupdate: lineup_lastupdate
        }
        const FieldControlsProps = {
            LineupsList: LineupsList,
            LineupsListClone: LineupsListClone,
            user_join_count: user_join_count,
            is_editable: is_editable,
            show_transfer: show_transfer,
            isSelectAnyPlayer: this.isSelectAnyPlayer,
            LineupProccessInit: this.LineupProccessInit,
            modalShow: this.modalShow,
            modalHide: this.modalHide,

            ApplyBooster: this.ApplyBooster,
            ConfirmMyteam: this.ConfirmMyteam,
            team_info: team_info,
            SelectedBooster: SelectedBooster,


            isSwapped: isSwapped
        }
        const CreateTeamProps = {
            ClubTeamList: ClubTeamList,
            LineupsList: LineupsList,
            SelectedFormation: SelectedFormation,
            contest_id: contest_id,
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            LineupFormationList: LineupFormationList,
            CreateTeamModalShow: CreateTeamModalShow,
            AlreadyLineupCreate: AlreadyLineupCreate
        }
        const FixtureModalProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            FixtureModalShow: FixtureModalShow
        }
        const TransferUpdateModalProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            TransferUpdateModalShow: TransferUpdateModalShow,
            LineupsList: LineupsList,
        }
        const ThankyouCreateTeamProps = {
            ...this.props,
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            ThankyouCreateTeamModalShow: ThankyouCreateTeamModalShow
        }
        const FreehitModalProps = {
            ...this.props,
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            FreehitModalShow: FreehitModalShow
        }
        const DeadlineClockProps = {
            position: 'left',
            date: next_week_start_time,
            gameweek: next_week,
            active_week:active_week
        }
        const LineupInfoProps = {
            ...this.props,
            team_info: team_info,
            team_name: team_name,
            my_performance: my_performance,
            DrawerRosterTrigger: this.DrawerRosterTrigger
        }
        const BoosterProps = {
            is_editable: is_editable,
            team_info: team_info,
            next_week: next_week,
            modalShow: this.modalShow,
            SelectedBooster: SelectedBooster,
            active_week: active_week,

            SelectedCaptainBooster: SelectedCaptainBooster,
            SelectedBenchBooster: SelectedBenchBooster,
            SelectedFreehitBooster: SelectedFreehitBooster,

            SelectBooster: this.SelectBooster,
            RemoveSelectBooster: this.RemoveSelectBooster,
            ApplyBoosterCallBack: this.ApplyBoosterCallBack
        }
        const PlayerCardProps = {
            PitchViewFieldRef: this.PitchViewFieldRef,
            modalHide: this.modalHide,
            _displayPosition: this._displayPosition,
            rosterContainsObject: this.rosterContainsObject,
            AddPlayerToLineup: this.AddPlayerToLineup,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            LineupUpdate: this.LineupUpdate,
            PlayerCardModalShow: PlayerCardModalShow,
            PlayerData: PlayerData,
            isPlayerCardFieldView: isPlayerCardFieldView,
            LineupsList: LineupsList,
            user_join_count: user_join_count,
        }

        const RosterView = (
            <div>
                {
                    user_join_count == 0 &&
                    !_.isEmpty(TeamList) &&
                    !_.isEmpty(LineupMasterData) &&
                    <Roster ref={this.RosterRef} {...RosterProps} />
                }
                {
                    user_join_count == 1 &&
                    <LineupInfo {...LineupInfoProps} />
                }
            </div>
        )
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className='container main-container'>
                    {this.state.topPtcBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.topPtcBanner}
                                 duration={durationForBanner}
                             />
                         </div>}
                        <AdsContainer />
                         
                        <Row className='d-block d-sm-block d-md-block d-lg-none ff'>
                            {
                                context.state.language == 'ar' ? RosterDrawerProps.right = false : RosterDrawerProps.right = true
                            }
                            <Col sm={12}>
                                <Drawer className='drawer-roster-wrapper' {...RosterDrawerProps}
                                    onChange={open => this.setState({ DrawerRosterOpen: open })}>
                                    <Alert stack={{ limit: 1 }} position='top-right' offset={15} html={true} effect='flip' />
                                    {
                                        user_join_count == 0 &&
                                        !_.isEmpty(TeamList) &&
                                        !_.isEmpty(LineupMasterData) &&
                                        <div className="drawer-roster-header">
                                            <span className="roster-back-btn icon-previousArrow" onClick={() => this.setState({ DrawerRosterOpen: false })}></span>
                                            {
                                                next_week_start_time &&
                                                <DeadlineClock {...DeadlineClockProps} {...context} />
                                            }
                                            <div className="info-block">
                                                <div className="title">{context.state.locale.MyTeam.player_selected}</div>
                                                <div className="value">{this.isSelectAnyPlayer(LineupsList)}/{NC.MAX_PLAYER}</div>
                                            </div>
                                            <div className="budget-box">
                                           
                                                <div className="title">{context.state.locale.MyTeam.available_budget}</div>
                                                <div className="value">
                                                    {
                                                        context.state.language != 'ar' &&
                                                        <Fragment>
                                                            {context.state.locale.MyTeam.currency_label}
                                                            {" "}
                                                        </Fragment>
                                                    }
                                                    {this._availableBudget(LineupsList) + NC.SALARY_UNIT} </div>
                                                 
                                            </div>
                                        </div>
                                    }
                                    {this.state.defaultRosterWidth > this.state.innerWidth ? RosterView : ''}
                                </Drawer>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={8} >
                                {
                                    user_join_count == 0 ?
                                        <header className="fieldinfo-header">
                                            {
                                                next_week_start_time &&
                                                <DeadlineClock {...DeadlineClockProps} {...context} />
                                            }
                                            <i className="icon-calendar" onClick={() => this.modalShow('FixtureModalShow')}></i>
                                        </header>
                                        :
                                        <header className="fieldinfo-header">
                                            {
                                                next_week_start_time &&
                                                <DeadlineClock {...DeadlineClockProps} position='right'  {...context} />
                                            }
                                            <div className="title">{team_info.team_name}</div>
                                        </header>
                                }
                                <div className={`roster-filter-loader fade ${FieldLoader ? 'in' : null}`}><RosterLoader /></div>

                                {
                                    user_join_count == 0 &&
                                    <div className="field-control-wrap">
                                        <div className="info-block">
                                            <div className="title">{context.state.locale.MyTeam.available_budget}</div>
                                            <div className="value">
                                                {
                                                    context.state.language != 'ar' &&
                                                    <Fragment>
                                                        {context.state.locale.MyTeam.currency_label}
                                                        {" "}
                                                    </Fragment>
                                                }
                                                {this._availableBudget(LineupsList)}{NC.SALARY_UNIT}</div>
                                        </div>

                                        <div className="info-block">
                                            <div className="title">{context.state.locale.MyTeam.player_selected}</div>
                                            <div className="value">{this.isSelectAnyPlayer(LineupsList)}/{NC.MAX_PLAYER}</div>
                                        </div>
                                        <div className="field-control">
                                            {
                                                user_join_count == 0 &&
                                                <span className={`control-btn ${FieldLoader ? 'disabled' : null}`} onClick={!FieldLoader ? (() => this.AutoCompleteLineupCreate()) : null}>
                                                    <i className="icon-automatic"></i>
                                                    <div className="label">{context.state.locale.MyTeam.auto_pick}</div>
                                                </span>
                                            }

                                            {
                                                this.isSelectAnyPlayer(LineupsList) != 0 && user_join_count == 0 &&
                                                <span className='control-btn' onClick={() => this.ResetChanges(LineupFormationList)}>
                                                    <i className="icon-refresh"></i>
                                                    <div className="label">{context.state.locale.MyTeam.reset}</div>
                                                </span>
                                            }

                                        </div>
                                    </div>
                                }

                                

                                {/* {
                                    this.state.isRulesViolates
                                    ?
                                        <div Style = {'display: flex; flex-direction: row; align-items: flex-start; border: red 1px solid; border-radius: 4px; padding: 10px; '}>

                                            <img Style = {'margin-right: 10px;'} src = {Images.transferError} alt = {''}></img>
                                            <div className="subtitle-tranfer-text-error-red">
                                                {context.state.locale.confirm_transfer.violating_team_combination}
                                            </div>
                                        </div>
                                    :
                                        this.state.isClubChanged || this.state.isClubRemoved
                                        ?
                                            <div Style = {'display: flex; flex-direction: row; align-items: flex-start; border: yellow 1px solid; border-radius: 4px; padding: 10px; '}>

                                                <img Style = {'margin-right: 10px;'} src = {Images.transferWarning} alt = {''}></img>
                                                <div className="subtitle-tranfer-text-error-yellow">
                                                    {context.state.locale.confirm_transfer.necessary_changes}
                                                </div>
                                            </div>
                                        :
                                            null
                                } */}

                                {/* FIELDVIEW : START */}
                                <Tab.Container id="LineupFieldView" defaultActiveKey="PitchView">
                                    <Row className={`clearfix ${user_join_count == 1 ? 'm-t-sm' : ''}`}>
                                        <Col sm={12}>
                                            <Nav className="fieldview-tabnav">
                                                <NavItem className='tabnav' eventKey="PitchView">{context.state.locale.MyTeam.pitch}</NavItem>
                                                <NavItem className='tabnav' eventKey="ListView">{context.state.locale.MyTeam.list}</NavItem>
                                            </Nav>
                                            {
                                                !_.isEmpty(LineupsList) && user_join_count == 1 &&
                                                <div className="field-control postion">
                                                    <button className='control-btn' disabled={(SelectedBooster == '' || team_info.booster_applied == 1) ? _.isEqual(LineupsListClone, LineupsList) : false} onClick={() => this.UndoMychanges()}>
                                                        <i className="icon-refresh"></i>
                                                        <div className="label">{context.state.locale.MyTeam.undo}</div>
                                                    </button>
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            <div className="info-notification">
                                                {
                                                    user_join_count == 1 &&
                                                    <span className="notify">{context.state.locale.MyTeam.captain_msz}</span>
                                                }
                                                <div className='d-none d-sm-none d-md-none d-lg-block'>
                                                    <Alert stack={{ limit: 1 }} position='top' offset={15} html={true} effect='flip' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content animation>
                                                <Tab.Pane eventKey="PitchView">
                                                    {
                                                        (_.isEmpty(LineupsList) || PitchViewLoaderInit) &&
                                                        <PitchViewLoader />
                                                    }
                                                    {
                                                        !_.isEmpty(LineupsList) && !PitchViewLoaderInit &&
                                                        <PitchViewField ref={this.PitchViewFieldRef} {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="ListView">
                                                    {
                                                        !_.isEmpty(LineupsList) &&
                                                        <ListViewField {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                                {
                                    !_.isEmpty(team_info) && !_.isEmpty(LineupsList) && user_join_count == 1 &&
                                    <Boosters ref={this.BoosterRef} {...BoosterProps} />
                                }
                                {
                                    !_.isEmpty(LineupsList) &&
                                    <FieldControls {...FieldControlsProps} />
                                }
                                

                                {/* FIELDVIEW : END */}
                            </Col>
                            <Col lg={4}>
                                {
                                    this.state.defaultRosterWidth <= this.state.innerWidth ? RosterView : ''
                                }
                            </Col>
                        </Row>
                       <Row>
                        <Col lg={8} md={12}> {
                             this.state.lowerBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.lowerBanner}
                                 duration={durationForBanner}
                             />
                         </div>
                        }</Col>
                        <Col lg={4} className='hide-sm'>
                            {this.state.squareBanner && <div className='sponsor-logo-bg sq-banner' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.squareBanner}
                                 duration={durationForBanner}
                             />
                         </div>}
                        </Col>
                       </Row>
                        {/* <div id = {'Leaderboard'} className='sponsor-logo-bg '></div> */}
                        
                        {/* <div className=' sponsor-logo-bg'>squareBanner
                            <img style={{ marginTop: '10px' }} src={Images.SPONSER_BANNER} ></img>
                        </div> */}
                        {
                            CreateTeamModalShow &&
                            <CreateTeam {...CreateTeamProps} />
                        }
                        {
                            FixtureModalShow &&
                            <FixtureModal {...FixtureModalProps} {...this.props} />
                        }
                        {
                            TransferUpdateModalShow &&
                            <TransferUpdateModal {...TransferUpdateModalProps} {...this.props} />
                        }
                        {
                            FreehitModalShow &&
                            <FreehitModal {...FreehitModalProps} />
                        }
                        {
                          //  ThankyouCreateTeamModalShow &&
                            <ThankyouCreateTeamModal {...ThankyouCreateTeamProps} />
                        }
                        {
                            PlayerCardModalShow &&
                            <PlayerCard {...PlayerCardProps} locale={context.state.locale} language={context.state.language} />
                        }

                        
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
