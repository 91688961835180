import React, { Component } from 'react'
import { MyContext } from '../context';
import WSManager from "../helper/WSManager";
import { API } from "../helper";
import * as NC from "../helper/NetworkingConstants";
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdsContainer from '../components/AdsContainer';
import _ from "lodash";
import SimpleImageSlider from "ac-react-simple-image-slider";
var durationForBanner = 10;

// const Questionlist = [
//    {
//       id: 1,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute",
//             check_icon:<i className='icn-tick'></i>,
//          }, {
//             id: 2,
//             option1: "40 minute",
//             cross_icon : <i className='icn-icon-cross'></i>
//          }, {
//             id: 3,
//             option1: "40 minute"
//          }, {
//             id: 4,
//             option1: "40 minute"
//          }
//       ]
//    }, {
//       id: 2,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute"
//          }, {
//             id: 2,
//             option1: "40 minute"
//          }
//       ]
//    },
//    {
//       id: 3,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute"
//          }, {
//             id: 2,
//             option1: "40 minute"
//          }, {
//             id: 3,
//             option1: "40 minute"
//          }, {
//             id: 4,
//             option1: "40 minute"
//          }
//       ]
//    },
//    {
//       id: 4,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute"
//          }, {
//             id: 2,
//             option1: "40 minute"
//          }, {
//             id: 3,
//             option1: "40 minute"
//          }, {
//             id: 4,
//             option1: "40 minute"
//          }
//       ]
//    },
//    {
//       id: 5,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute"
//          }, {
//             id: 2,
//             option1: "40 minute"
//          }, {
//             id: 3,
//             option1: "40 minute"
//          }, {
//             id: 4,
//             option1: "40 minute"
//          }
//       ]
//    },
//    {
//       id: 6,
//       question: "How long is a soccer game?",
//       data: [
//          {
//             id: 1,
//             option1: "40 minute"
//          }, {
//             id: 2,
//             option1: "40 minute"
//          }
//       ]
//    }

// ];
export default class PlayTrivia extends Component {
   constructor(props) {
      super(props)

      this.state = {
         selected: 0,
         isActive: false,
         Questionlist: [],
         week: '',
         total: '',
         correct_answer_number: '',
         your_answer_status: '',
         selectedData: '',
         selectedIdx: ''
      }
   }

   getQuestionlist = () => {
      let param = {
         "sports_id": WSManager.getMultiLegData().sports_id,
         "league_id": WSManager.getMultiLegData().league_id,
      }
      WSManager.Rest(API.GET_QUESTION_LIST, param).then((responseJson) => {
         if (responseJson.response_code === NC.successCode) {
            if (!_.isEmpty(responseJson.data)) {
               let advertise = { 'label': "Advertise" }
               let templist = responseJson.data.question_list
               if (templist.length >= 3) {
                  _.map(templist, (it, ix) => {
                     if (ix == 2) {
                        templist.splice(2, 0, advertise)
                     }

                  })
               } else if (templist.length == 2) {
                  _.map(templist, (it, ix) => {

                     if (ix == 1) {
                        templist.push(advertise)
                     }
                  })
               }
               else if (templist.length == 1) {
                  _.map(templist, (it, ix) => {

                     if (ix == 0) {
                        console.log(ix, "ix");
                        templist.push(advertise)
                     }
                  })
               }

               this.setState({
                  total: responseJson.data.total,
                  week: responseJson.data.week,
                  Questionlist: templist
               }, () => {
                  console.log(templist, "templist");
               })
            } else{
               this.setState({
                  Questionlist: responseJson.data.question_list
               })
            }
         }
      })
   }

   componentDidMount() {
      this.getQuestionlist()
   }

   greetUser = (item, pet, index, idx) => {


      this.setState({
         selectedData: item,
         selectedIdx: index
      })
      let param = {
         "sports_id": WSManager.getMultiLegData().sports_id,
         "league_id": WSManager.getMultiLegData().league_id,
         "answer_number": pet.option_number,
         "question_uid": item.question_uid
      }

      WSManager.Rest(API.USER_ANSWER, param).then((responseJson) => {
         if (responseJson.response_code === NC.successCode) {
            // let temp = this.state.Questionlist 
            // temp.splice(idx, 1);
            // console.log(temp, "temptemptemptemptemptemp");
            this.setState({
               correct_answer_number: responseJson.data.correct_answer_number,
               your_answer_status: responseJson.data.your_answer_status,

            }, () => {

               setTimeout(() => {
                  this.getQuestionlist()
                  this.setState({
                     correct_answer_number: '',
                     your_answer_status: '',
                  })
               }, 1500);
            })

         }
      })
   };
   componentWillMount() {
      let advBanner = WSManager.getAdBanner();
      if (advBanner && advBanner.advertisements != undefined) {
          this.callAdvBanner(advBanner);
      }
  }
  callAdvBanner = (data) => {
      let bannerData = data.advertisements;
      let upperBanner = []
      let lowerBanner = [];
      let squareBanner = [];
      let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
      
      for (let k = 0; k < bannerData.length; k++) {
          if (bannerData[k].ad_position_id == 18 && bannerData[k].league_id == sportsLg) {
              bannerData[k]["src"] = bannerData[k].image_adsense
              bannerData[k]["id"] = bannerData[k].ads_unique_id
              upperBanner.push(bannerData[k])
          }
          else if (bannerData[k].ad_position_id == 19 && bannerData[k].league_id == sportsLg ){
              bannerData[k]["src"] = bannerData[k].image_adsense
              bannerData[k]["id"] = bannerData[k].ads_unique_id
              squareBanner.push(bannerData[k])
          }
         
      }
      this.setState({
          lowerBanner: lowerBanner,
          squareBanner: squareBanner,
          upperBanner: upperBanner
      }, () => {
          this.getBannerIndex();
          // console.log(this.state.squareBanner,'sdssdfsquareBanner')
      })
  }
  getBannerIndex = (e) => {
      for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
          this.task(this.state.lowerBanner[i], i);
      }
  }
  task = (data, i) => {
      let a = this.state.lowerBanner.length - 1;
      setTimeout(function () {
          this.setState({
              currentBanner: data.target_url
          })
          if (a == i) {
              setTimeout(function () {
                  this.getBannerIndex();
              }.bind(this), durationForBanner * 1000)

          }
      }.bind(this), durationForBanner * 1000 * i);

  }
  getBannerClick = (from) => {
   if (from == 1) {
       window.open(this.state.currentBannerUpper);
   }
   if (from == 2) {
       window.open(this.state.currentBanner);
   }

}
   render() {
      const { Questionlist, week, total, correct_answer_number, your_answer_status, selectedData, selectedIdx } = this.state
     
      return (
         <MyContext.Consumer>
            {(context) => (
               <div className="container main-container">
                  {
                     this.state.upperBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                        <SimpleImageSlider
                           showArrows={false}
                           showDots={false}
                           data={this.state.upperBanner}
                           duration={durationForBanner}
                        />
                     </div>
                  }
                  <div className="play_trivia_container">
                     <div className="play_trivia_view_div mx-3">
                        <div className="play_trivia_text">
                           Play Trivia & Win
                           {/* <i className='icn-ic-info' /> */}
                        </div>
                        {/* !_.isEmpty(Questionlist) &&  */}
                        {<div className="play_trivia_button" onClick={() => this.props.history.push('/trivia/leaderboard')}>
                           <i className='icn-leaderboard' /> <span> View Leaderboard</span>
                        </div>}
                     </div>
                     {!_.isEmpty(Questionlist) ? <>
                        <div className="round_view_container mx-3">
                           <div className="round_text">{WSManager.getWeekLabel(WSManager.getMultiLegData().week_label[week])}</div>
                           <div className="questions_text">{total} questions</div>
                        </div>

                        <div className="container question_container_view">
                           <Row className=''>
                              {Questionlist.map((item, index) => {
                                 {/* console.log(item,"itemitemitem");
                                   console.log(index, "indexindexindexindex"); */}
                                 return (
                                    <>
                                       {index == 2 && Questionlist.length >= 3 ?
                                          <Col lg={6} key={index} className='p-3'>
                                             {/* <h1 style={{ color: "red" }}>{item.label}</h1> */}
                                            
                                                {
                                                   this.state.squareBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                                      <SimpleImageSlider
                                                         showArrows={false}
                                                         showDots={false}
                                                         data={this.state.squareBanner}
                                                         duration={durationForBanner}
                                                      />
                                                   </div>
                                                }
                                            
                                          </Col>
                                          :
                                          index == 1 && Questionlist.length == 2 ?
                                             <Col lg={6} key={index} className='p-3'>
                                                {/* <h1 style={{ color: "red" }}>{item.label}</h1> */}
                                                <div className="question_container_ads"> {
                                                   this.state.squareBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                                      <SimpleImageSlider
                                                         showArrows={false}
                                                         showDots={false}
                                                         data={this.state.squareBanner}
                                                         duration={durationForBanner}
                                                      />
                                                   </div>
                                                }</div>
                                             </Col>
                                             :
                                             index == 0 && Questionlist.length == 1 ?
                                                <Col lg={6} key={index} className='p-3'>
                                                   {/* <h1 style={{ color: "red" }}>{item.label}</h1> */}
                                                   <div className="question_container_ads"> {
                                                   this.state.squareBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                                      <SimpleImageSlider
                                                         showArrows={false}
                                                         showDots={false}
                                                         data={this.state.squareBanner}
                                                         duration={durationForBanner}
                                                      />
                                                   </div>
                                                }</div>
                                                </Col>
                                                :
                                                <Col lg={6} className='p-3' key={index} >
                                                   <div className="question_container" >
                                                      <div className='question_heading'>{item.question}</div>
                                                      {item.options && <div className="option_container">
                                                         {item.options.map((pet, pindex) => {
                                                            return (
                                                               <div
                                                                  // className={this.state.isActive===true ?"option_box_view" : "option_box"}
                                                                  className={`option_box ${your_answer_status == 1 && selectedData.question_uid == item.question_uid && selectedIdx == pindex ? 'correct_answer': your_answer_status == 0 &&  your_answer_status !== '' && selectedData.question_uid == item.question_uid && selectedIdx == pindex ? 'wrong_answer': ''}`}
                                                                  // "correct_answer" "wrong_answer"

                                                                  onClick={() => this.greetUser(item, pet, pindex, index)}
                                                                  key={pindex}>
                                                                  <div className='options'>{pet.text}
                                                                  {
                                                                     Number(pet.option_number) == Number(correct_answer_number) && your_answer_status == 0 &&
                                                                     <span className='correct-txt'>Correct answer</span>
                                                                  }
                                                                  </div>

                                                                  {(your_answer_status !== '' && selectedData.question_uid == item.question_uid && selectedIdx == pindex) && <div>
                                                                     {(your_answer_status == 1 && Number(pet.option_number) == Number(correct_answer_number)) && (

                                                                        <span className='checkicon'>
                                                                           <i className='icn-tick' />
                                                                        </span>
                                                                     )}
                                                                     {your_answer_status == 0 && (Number(pet.option_number) !== Number(correct_answer_number)) && (
                                                                        <span className='crossicon'>
                                                                           <i className='icn-icon-cross' />
                                                                        </span>
                                                                     )}
                                                                  </div>}


                                                               </div>
                                                            )
                                                         })}
                                                      </div>}
                                                      <div className={`bottom-points  ${your_answer_status == 1 && selectedData.question_uid == item.question_uid ? 'points_added':''}`}>
                                                         { your_answer_status == 1 && selectedData.question_uid == item.question_uid  ?
                                                         <span className='points_added'>+{item.reward_value} pts added</span> :
                                                         <span className='color-yellow'>+{item.reward_value} pts for correct answer</span> 
                                                          }
                                                      </div>
                                                   </div>
                                                </Col>}

                                    </>)

                              })}

                           </Row>
                        </div>
                     </>
                        :
                        <div className="no-data-view-container">
                           <div className="no-data-view-img">
                              <div className='stay-tuned'>Stay<br /> Tuned!</div>
                              <div className='trivia-open'>Trivia questions<br /> will be open soon</div>
                           </div>
                        </div>
                     }
                  </div>


               </div>
            )}
         </MyContext.Consumer>
      )
   }
}
