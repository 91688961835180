/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';
import { Row, Col, Button, Tab, NavItem, Nav, Popover, OverlayTrigger } from 'react-bootstrap';

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { notify } from 'react-notify-toast';
import Alert from 'react-s-alert';
import Drawer from 'react-motion-drawer';

import Images from '../components/images'

import { MyContext } from '../context';

import DeadlineClock from '../views/DeadlineClock';
import PitchViewField from '../views/PitchView';
import PitchViewLoader from '../views/PitchViewLoader';
import ListViewField from '../views/ListViewField';
import Roster from '../views/Roster';

import PlayerCard from '../Modals/PlayerCard';
import RosterLoader from '../views/RosterLoader';
import ConfirmTransfer from '../Modals/ConfirmTransfer';
import TransferUpdateModal from '../Modals/TransferUpdateModal';
// import { Helmet } from "react-helmet";
import SimpleImageSlider from "ac-react-simple-image-slider";

import ReactGA from 'react-ga'; 
import AdsContainer from '../components/AdsContainer';
ReactGA.initialize('UA-177410945-1');
ReactGA.pageview(window.location.pathname + window.location.search);


var durationForBanner = 10;
// var durationForBanner
export default class Transfer extends React.Component {

    // Methods
    // ------------------------------

    // GET_ALL_TEAM
    
    getAllTeam = (type) => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        let s3_param = [
            API.S3_GET_ALL_TEAM,
            // NC.sportsId,
            // NC.leagueId
            WSManager.getMultiLegData().sports_id,
            WSManager.getMultiLegData().league_id,
        ]
        WSManager.https3(WSManager.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                const AllTeam = {
                    "label": this.props.locale.MyTeam.all_teams,
                    "value": ""
                }

                let result = []
                responseJson.data.result.map((data, key) => {
                    if (data.is_eliminate != 1)
                    {
                        data.label = data.team_name;
                        data.value = data.team_id;
                        // return data;
                        result.push(data)
                    }
                    return data
                })

                // const team_list = _.clone(responseJson.data.result);
                // const team_list_club = _.clone(responseJson.data.result);
                const team_list = _.clone(result);
                const team_list_club = _.clone(result);



                this.setState({
                    ClubTeamList: team_list_club
                });


                team_list.unshift(AllTeam);
                this.setState({
                    TeamList: team_list,
                });

            }
        })
    }

    // GET_LINEUP_MASTER_DATA
    getLineupMasterData = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        WSManager.Rest(API.GET_LINEUP_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                
                let AllPosition = {
                    "value": "",
                    "label": this.props.locale.MyTeam.all_players,
                    "max_player_per_position": "15"
                }
                responseJson.data.all_position.map((data, key) => {
                    data.label = data.position_display_name;
                    data.value = data.position;
                    return data;
                })

                responseJson.data.all_position.unshift(AllPosition)
                this.setState({
                    LineupMasterData: responseJson.data,
                    AllPositionList: responseJson.data.all_position,
                    LineupFormationList: responseJson.data.lineup_formation,
                    // AllPositionList: responseJson.data.all_position,
                });
                sessionStorage.setItem('SelectedFormation', responseJson.data.default_formation);
                if (WSManager.loggedIn()) {
                    this.getManageTeamMaster();
                } else {
                    if (_.isNull(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'))) {
                      
                        this.LineupCreate(this.state.LineupFormationList)
                    } else {
                     
                        var lineup = JSON.parse(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'));
                        var selected_formation = responseJson.data.lineup_formation.formation[this.state.SelectedFormation];
                        this.LineupCreate(selected_formation, lineup);
                    }
                    this.setState({ isLoaderShow: false });
                }
            }
        })

    }

    // GET_MANAGE_TEAM_MASTER_DATA
    getManageTeamMaster = () => {
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "is_performance_required": 1,
        }
        WSManager.Rest(API.GET_MANAGE_TEAM_MASTER_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    ManageTeamData: responseJson.data,
                    user_join_count: responseJson.data.user_join_count,
                    active_week: responseJson.data.active_week,
                    next_week: responseJson.data.next_week,
                    next_week_start_time: responseJson.data.next_week_start_time,
                    contest_id: responseJson.data.contest_id,
                    lineup_master_id: responseJson.data.lineup_master_id
                })
                localStorage.setItem('lineup_master_id', responseJson.data.lineup_master_id);
                localStorage.setItem('user_join_count', responseJson.data.user_join_count);
                if (responseJson.data.user_join_count != 0) {
                    this.getUserLineupDetail({
                        contest_id: responseJson.data.contest_id,
                        lineup_master_id: responseJson.data.lineup_master_id,
                        active_week: responseJson.data.next_week
                    })
                } else {
                    if (_.isNull(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'))) {
                        this.LineupCreate(this.state.LineupFormationList)
                    } else {
                        var lineup = JSON.parse(sessionStorage.getItem(WSManager.getMultiLegData().league_abbr+'lineup'));
                        var selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                        this.LineupCreate(selected_formation, lineup);
                    }
                }
            }
        })
    }

    getUserLineupDetail = (options) => {
        const { contest_id, active_week, lineup_master_id} = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_LINEUP_DETAIL, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                var selected_formation = !_.isEmpty(this.state.LineupFormationList) ? this.state.LineupFormationList.formation[data.formation] : {};
                this.setState({
                    MatchList: data.match_list,
                    SelectedFormation: data.formation,
                    team_name: data.team_name,
                    default_formation: data.default_formation,
                    manage_team_name: data.team_name,
                }, () => {
                    sessionStorage.setItem('SelectedFormation', data.formation);
                    _.map(responseJson.data.lineup, (player) => {
                        player.display_position = this._displayPosition(player.position)
                    })
                    console.log("========",selected_formation);
                    
                    this.LineupCreate(selected_formation, data.lineup)
                    this.getUserTeamInfo(options)
                })
            }
        })
    }

    getUserTeamInfo = (options) => {
        const { contest_id, active_week, lineup_master_id } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_TEAM_INFO, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                let data = responseJson.data;
                this.setState({
                    team_info: data.team_info,
                    boosterApplied: data.team_info.booster_applied,
                    booster_applied_in_curr_week: data.team_info.booster_applied_in_curr_week,
                    isBenchBooster: data.team_info.bench_booster,
                    isCaptainBooster: data.team_info.captain_booster,

                    is_editable: data.team_info.is_editable,
                    show_transfer: data.team_info.show_transfer,
                    apply_wildcard: data.team_info.wildcard_status.wildcard_used_in_current_week == 1 ? true : false
                })
            }
        })

    }


    // GET_AUTOFILL_LINEUP_DATA
    AutoCompleteLineupCreate = () => {
        const globalthis = this;
        let filterLineup = _.filter(this.state.LineupsList, function(item) {
            if(item.readonly == 0) {
                globalthis.RemovePlayerFromLineup(item);
            }
            return item.player_uid && _.isUndefined(item.readonly);
        })

        let param = {
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup": filterLineup
        }

        this.setState({
            FieldLoader: true,
            PitchViewLoaderInit: true
        });
        WSManager.Rest(API.GET_AUTOFILL_LINEUP_DATA, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                // let selected_formation = sessionStorage.SelectedFormation == '4-4-2' ? responseJson.data.selected_formation : sessionStorage.SelectedFormation;
                let selected_formation = responseJson.data.selected_formation;
                let selected_formation_Obj = this.state.LineupFormationList.formation[selected_formation];

                _.map(responseJson.data.lineup_player, (player) => {
                    player.display_position = this._displayPosition(player.position);
                    if(!_.includes(filterLineup, player.player_uid)) {
                        player.is_transfer_player = true;
                        player.readonly = 0;
                    }
                })

                /* for auto pick new functionality */
                if (this.state.LineupsList.length > 0) {
                    _.map(responseJson.data.lineup_player, function (item) {
                        globalthis.AddPlayerToLineup(item)
                    });
                } else {
                    this.LineupCreate(selected_formation_Obj, responseJson.data.lineup_player);
                }
                /* ------- */

                this.setState({
                    FieldLoader: false,
                    PitchViewLoaderInit: false,
                    SelectedFormation: selected_formation
                });
                sessionStorage.setItem('SelectedFormation', selected_formation);
            }
        })
    }
    ApplyWildcardAction = (status) => {
        this.setState((state, props) => {
            return {
                apply_wildcard: !status,
                // LineupsList: this.state.LineupsListCopy
            }
        })
        if (status) {
            const { contest_id, lineup_master_id, next_week } = this.state;
            this.getUserLineupDetail({
                contest_id: contest_id,
                lineup_master_id: lineup_master_id,
                active_week: next_week
            })
            this.setState((state, props) => {
                return {
                    TransferPlayerIn: [],
                    TransferPlayerOut: []
                }
            });
        }
    }
    // LINEUP CREATE
    rosterContainsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list[x].player_uid == obj.player_uid) {
                return true;
            }
        }
        return false;
    }
    containsObject = (obj, list) => {
        var x;
        for (x in list) {
            if (list.hasOwnProperty(x) && list[x] === obj) {
                return true;
            }
        }
        return false;
    }
    isLineupView = () => {
        let param_lineup_master_id = this.props.match.params.lineup_master_id;
        let isOwnLineupView = param_lineup_master_id ? localStorage.getItem('lineup_master_id') == param_lineup_master_id : true;
        this.setState({
            param_lineup_master_id: param_lineup_master_id,
            isOwnLineupView: isOwnLineupView
        });
    }
    _availableBudget = (LineupsList) => {
        let Budget = _.sumBy(LineupsList, function (o) { return parseFloat(o.salary, 10); })
        return WSManager.getMultiLegData().salary_cap[this.state.active_week] - Budget;
    }
    AvailableFreeTransfers = (val) => {
       
        let TransferPlayerOut = this.state.TransferPlayerOut;
        // let TransferPlayerIn = this.state.TransferPlayerIn;
        let AvalFreeransfer = 0;

        // console.log('Condition: ',  this.state.next_week,(this.state.team_info.free_hit_booster_status.is_applied == 1 && Number(this.state.team_info.free_hit_booster_status.applied_week) < Number(this.state.next_week)));
        if (this.state.apply_wildcard || (this.state.team_info.free_hit_booster_status.is_applied == 1 && Number(this.state.team_info.free_hit_booster_status.applied_week) == Number(this.state.next_week))) return this.props.locale.MyTeam.unlimited; 

        // console.log('val >= TransferPlayerOut.length', val, TransferPlayerOut.length, TransferPlayerIn.length);
        if (val >= TransferPlayerOut.length) {
            AvalFreeransfer = val - TransferPlayerOut.length
        }
        return AvalFreeransfer;
    }
    TransfersCast = (val) => {
        
        let TotalCast = 0;
        if (this.state.apply_wildcard) return TotalCast;
        let isFreeAvail = this.AvailableFreeTransfers(val)
        let TransferPlayerOut = this.state.TransferPlayerOut;
        if (isFreeAvail == 0) {
            TotalCast = (TransferPlayerOut.length - val) * this.state.team_info.per_transfer_deduct
        }
        return TotalCast;
    }
    isSelectAnyPlayer = (data) => {
        if (_.isEmpty(data)) return 0;
        var result = [];
        result = data.filter(function (obj) {
            return obj.player_uid != undefined;
        })
        return result.length;
    }
    SelectedPositionCount = (data, val) => {
        var result = [];
        if (val.position == undefined) {
            result = data.filter(function (obj) {
                return obj.player_uid != undefined
            })
        } else {
            result = data.filter(function (obj) {
                return obj.position == val.position && obj.player_uid != undefined
            })
        }
        return result.length;
    }
    _displayPosition = (pos, type, CONTEXT, single) => {
        let display_pos = type != 'NAME' ? NC.DISPLAYNAME_GOALKEEPER : NC.DISPLAYNAME_GOALKEEPER_FULL
        switch (pos) {
            case NC.DEFENDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_DEFENDER : NC.DISPLAYNAME_DEFENDER_FULL
                break;
            case NC.MIDFIELDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_MIDFIELDER : NC.DISPLAYNAME_MIDFIELDER_FULL
                break;
            case NC.FORWARDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_FORWARDER : NC.DISPLAYNAME_FORWARDER_FULL
                break;
            default:
                break;
        }
        if (CONTEXT) {
            display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_GOALKEEPER : CONTEXT.DISPLAYNAME_GOALKEEPER_FULL
            switch (pos) {
                case NC.DEFENDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_DEFENDER : CONTEXT.DISPLAYNAME_DEFENDER_FULL
                    break;
                case NC.MIDFIELDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_MIDFIELDER : CONTEXT.DISPLAYNAME_MIDFIELDER_FULL
                    break;
                case NC.FORWARDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_FORWARDER : CONTEXT.DISPLAYNAME_FORWARDER_FULL
                    break;
                default:
                    break;
            }
        }
        if (single) {
            display_pos = display_pos.slice(0, -1);
        }
        return display_pos;
    }
    _getMaxPlayerPosition = (pos) => {
        let max_pos = '';
        switch (pos) {
            case NC.DEFENDER:
                max_pos = NC.DF
                break;
            case NC.MIDFIELDER:
                max_pos = NC.MF
                break;
            case NC.FORWARDER:
                max_pos = NC.FW
                break;
            case NC.GOALKEEPER:
                max_pos = NC.GK
                break;
            default:
                break;
        }
        return max_pos;
    }
    _randomString = (length, chars) => {
        var mask = '';
        if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
        if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (chars.indexOf('#') > -1) mask += '0123456789';
        if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        var result = '';
        for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
        return result;
    }
    _addPlayer = (Obj) => {
        let LineupObj = {
            "player_id": this._randomString(5, '#aA'),
            "position": Obj.position,
            "salary": 0,
            "display_position": this._displayPosition(Obj.position),
            "is_bench_player": Obj.is_bench_player,
            "max_player_per_position": this._getMaxPlayerPosition(Obj.position),
            "player_role": Obj.player_role ? Obj.player_role : 0,
            "bench_order": Obj.bench_order
        }

        console.log('_addPlayer', LineupObj);
        return LineupObj;
    }
    _handleShowPlayerAlert = (playerName, type) => {
        let addedText = this.props.language == 'en' ? 'has been added to your roster' : 'تمت إضافته إلى قائمتك';
        let RemovedText = this.props.language == 'en' ? 'has been removed from your roster' : 'تمت إزالة من قائمة';
        switch (type) {
            case 'success':
                Alert.success('<b>' + playerName + '</b> ' + addedText);
                break;
            case 'error':
                Alert.error('<b>' + playerName + '</b> ' + RemovedText);
                break;
            case 'warning':
                Alert.warning('<b>' + playerName + '</b> ' + RemovedText);
                break;
            default:
                Alert.info('<b>' + playerName + '</b> ' + RemovedText);
                break;
        }
    }
    _handleShowAlert = (message, type) => {
        switch (type) {
            case 'success':
                Alert.success(message);
                break;
            case 'error':
                Alert.error(message);
                break;
            case 'warning':
                Alert.warning(message);
                break;
            default:
                Alert.info(message);
                break;
        }
    }
    playerNameSort = (name) => {
        if (!name) return
        var str = name.split(' ');
        str[0] = str[0].charAt(0)
        return str[0] + '. ' + str[str.length - 1]
    }
    _selectRandomCaptain = (Player, Lineuplist) => {
        var filterArr = Lineuplist.filter(function (item) {
            return item.position == Player.position && Player.is_bench_player == 0
        })
        filterArr[0].player_role = '1';
    }
    LineupCreate = (FormationList, Lineuplist, isFormationProcess) => {
      //  return;
        const Lineup = [];
        if (Lineuplist != undefined) {
            let CurrentFormation = FormationList;
            for (let i = 0; i < CurrentFormation.length; i++) {
                const element = CurrentFormation[i];
                var filterArr = Lineuplist.filter(function (item) {
                    return item.position == element.position
                })
                _.map(filterArr, (Player, idx) => {

                    if (isFormationProcess) {
                        Player.is_bench_player = '0'
                    }

                    if (idx < element.number_of_players) {
                        Lineup.push(Player);
                    } else {
                        if (isFormationProcess) {
                            if (Player.player_role == 1) {
                                this._selectRandomCaptain(Player, Lineup)
                                Player.player_role = '0'
                            }
                            Player.is_bench_player = '1'
                        }
                        Lineup.push(Player);
                    }
                })
            }
        } else {
            let CurrentFormation = FormationList.formation[this.state.SelectedFormation]
            for (let i = 0; i < CurrentFormation.length; i++) {
                const element = CurrentFormation[i];
                element.is_bench_player = '0';
                if (this.state.isFullFieldViewMode) {
                    for (let j = 0; j < CurrentFormation[i].max_player_per_position; j++) {
                        Lineup.push(this._addPlayer(element));
                    }
                } else {
                    for (let j = 0; j < CurrentFormation[i].number_of_players; j++) {
                        Lineup.push(this._addPlayer(element));
                    }
                    for (let j = 0; j < CurrentFormation[i].number_of_bench_player; j++) {
                        element.is_bench_player = '1';
                        Lineup.push(this._addPlayer(element));
                    }
                }
            }
        }
        sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(Lineup));
        this.setState({
            LineupsList: Lineup,
            isLoaderShow: false,
            isRosterViewShow: true,
            PitchViewLoaderInit: false,
            lineup_lastupdate: new Date().valueOf()
        }, () => {
            const { LineupsList } = this.state;
            let RuleViolatePlayer = _.filter(LineupsList, (o) => o.is_rule_violate != 0)
            let ClubChangePlayer = _.filter(LineupsList, (o) => o.is_club_change != 0)
            let ClubRemovePlayer = _.filter(LineupsList, (o) => o.is_club_remove != 0)
            this.setState({
                TransferUpdateModalShow: RuleViolatePlayer.length > 0,
            })

            

            this.setState({
                isRulesViolates: RuleViolatePlayer.length > 0,
                isClubChanged: ClubChangePlayer.length > 0,
                isClubRemoved: ClubRemovePlayer.length > 0
            })
            
        });
        if (_.isEmpty(this.state.LineupsListClone)) {
            this.setState({
                LineupsListClone: _.cloneDeep(Lineup)
            });
        }

        
    }
    LineupUpdate = (lineup) => {
        var selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
        this.LineupCreate(selected_formation, lineup);

        setTimeout(() => {
            
            if (this.state.TransferPlayerIn.length > 0 && !this.state.TransferUpdateModalShow)
            {
                this.modalShow('ConfirmTransferModalShow')
            }
            else if (!this.state.TransferUpdateModalShow) 
            {
                this.LineupProccessInit()
            }
            
        }, 500);
        
    }
    UndoMychanges = () => {
        this.setState({
            PitchViewLoaderInit: true,
            LineupsList: _.cloneDeep(this.state.LineupsListClone),
            TransferPlayerIn: [],
            TransferPlayerOut: [],
        });
        setTimeout(() => {
            this.setState({
                PitchViewLoaderInit: false,
            });
        }, 300);
    }

    AddPlayerToLineup = (Player) => {

        console.log('AddPlayerToLineup', Player);
        
        this.setState({ isPlayerPosting: false });
        let LineupsList = this.state.LineupsList;
        let TransferPlayerIn = this.state.TransferPlayerIn;
        let TransferPlayerOut = this.state.TransferPlayerOut;
        let i = 0;

        if (this._availableBudget(LineupsList) < parseInt(Player.salary, 10)) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_BUDGET_INSUFFICIENT, "warning");
            return false;
        }
        if (this.isSelectAnyPlayer(LineupsList) == LineupsList.length) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_LINEUP_FULL, "warning");
            return false;
        }
        var filterArr = LineupsList.filter(function (item) {
            return item.team_league_id == Player.team_league_id
        })
        if (filterArr.length == this.state.LineupMasterData.max_player_per_team) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_MAX_PLAYER_PER_TEAM, "warning");
            return false;
        }
        if (this.SelectedPositionCount(LineupsList, Player) == NC[Player.position]) {
            this.setState({ isPlayerPosting: true });
            this._handleShowAlert(NC.MSZ_MAX_POSITION_SELECTED, "warning");
            return false;
        }

        while (i < LineupsList.length) {
            if (LineupsList[i].position == Player.position && !LineupsList[i].player_uid) {
                Player.display_position = LineupsList[i].display_position;
                let selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                let filterPosFormation = _.filter(selected_formation, function (o) {
                    return o.position == Player.position
                })
                if (this.SelectedPositionCount(LineupsList, Player) + 1 > filterPosFormation[0]['number_of_players']) {
                    if (this.state.isFullFieldViewMode) {

                    } else {
                        LineupsList[i].is_bench_player = 1
                    }
                }
                Player.is_bench_player = LineupsList[i].is_bench_player;
                Player.player_role = LineupsList[i].player_role;
                Player.bench_order = LineupsList[i].bench_order;
                if (this.containsObject(Player, LineupsList)) return;
                if (this.state.is_editable == 0) {
                    if (this.state.isMakeTransferActive && Player.is_transfer_player && Player.readonly == 0) {
                        TransferPlayerIn.push(Player)
                    } else {
                        _.remove(TransferPlayerOut, function (item) {
                            return item.player_uid === Player.player_uid;
                        });
                    }
                }

                LineupsList[i] = Player;
                let Player_short_name = this.playerNameSort(Player.full_name);
                this._handleShowPlayerAlert(Player_short_name, 'success');
                this.setState({ isPlayerPosting: true });
                break;
            } else {
                this.setState({ isPlayerPosting: true });
            }
            i++;
        }
        this.setState({ LineupsList: LineupsList });
        sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup'
        , JSON.stringify(LineupsList));
    }

    RemovePlayerFromLineup = (Player) => {

        console.log('RemovePlayerFromLineup', Player);
        let TransferPlayerOut = this.state.TransferPlayerOut;
        let TransferPlayerIn = this.state.TransferPlayerIn;
        let LineupsList = this.state.LineupsList;

        console.log('LineupsList.length', LineupsList.length);

        for (let i = 0; i < LineupsList.length; i++) {
            if (Player.player_uid == LineupsList[i].player_uid) {
                console.log('this.state.is_editable', this.state.is_editable);
                if (this.state.is_editable == 0) {
                    console.log('(this.state.isMakeTransferActive && (!Player.is_transfer_player || Player.readonly == 1))', (this.state.isMakeTransferActive && (!Player.is_transfer_player || Player.readonly == 1)));
                    if (this.state.isMakeTransferActive && (!Player.is_transfer_player || Player.readonly == 1)) {
                        TransferPlayerOut.push(Player)
                    } else {
                        
                        _.remove(TransferPlayerIn, function (item) {
                            return item.player_uid === Player.player_uid;
                        });
                    }
                }

                

                let selected_formation = this.state.LineupFormationList.formation[this.state.SelectedFormation];
                let filterPosFormation = _.filter(selected_formation, function (o) {
                    return o.position == Player.position
                })

                LineupsList[i].max_player_per_position = filterPosFormation[0]['max_player_per_position'];

                LineupsList[i] = this._addPlayer(LineupsList[i]);
                this.setState({ LineupsList: LineupsList });
                sessionStorage.setItem(WSManager.getMultiLegData().league_abbr+'lineup', JSON.stringify(LineupsList));

                let Player_short_name = this.playerNameSort(Player.full_name);
                this._handleShowPlayerAlert(Player_short_name, 'error');
            }
        }

        // this.setState({TransferPlayerOut: this.state.TransferPlayerOut, TransferPlayerIn: this.state.TransferPlayerIn})
    }


    PositionSelectChange = (SelectedPlayerPosition) => {
        console.log('PositionSelectChange', SelectedPlayerPosition);
        let all_position = this.state.LineupMasterData.all_position
        let fltPosition = _.filter(all_position, function (o) {
            return o.position == SelectedPlayerPosition.position
        })
        this.RosterRef.current.handleSelectChange('SelectedPlayerPosition', fltPosition[0]);
        this.DrawerRosterTrigger(false);
    }
    LineupProccessInit = () => {
        this.setState({ FieldLoader: true });
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "selected_formation": this.state.SelectedFormation,
            "contest_id" :  WSManager.getMultiLegData().league_id,
            "lineup": this.state.LineupsList,
            "team_league_id": '',
            "team_name": '',
            "lineup_master_id": this.state.team_info.lineup_master_id
        }
        WSManager.Rest(API.LINEUP_PROCCESS, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                notify.show(responseJson.message, "success", 5000);
                this.setState({
                    LineupsListClone: _.cloneDeep(this.state.LineupsList),
                    FieldLoader: false
                });
            } else {
                this.setState({ FieldLoader: false });
            }
        })
    }
    modalShow = (Modal) => {
        this.setState({ [Modal]: true });
    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }
    playerCardModalInit = (Modal, PlayerData, isPlayerCardFieldView) => {
        this.setState({
            [Modal]: true,
            PlayerData: PlayerData || {},
            isPlayerCardFieldView: isPlayerCardFieldView || false
        });
    }
    DrawerRosterTrigger = (DrawerRosterOpen) => {
        this.setState({
            DrawerRosterOpen: !DrawerRosterOpen
        })
    }
    updateDimensions = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }
    // Lifecycle
    // ------------------------------
    constructor(props) {
        super(props);
        this.state = {
            // Flags
            is_editable: 1,
            show_transfer: 0,
            isMyTeamScreen: true,
            user_join_count: '',
            FieldLoader: false,
            isMakeTransferActive: true,
            PitchViewLoaderInit: true,

            allow_wildcard: '0',
            apply_wildcard: false,

            PlayerCardModalShow: false,
            ConfirmTransferModalShow: false,
            TransferUpdateModalShow: false,
            isFullFieldViewMode: true,
            isPlayerCardFieldView: false,
            DrawerRosterOpen: false,
            defaultRosterWidth: 991,

            // Asyn data
            SelectedFormation: _.isNull(sessionStorage.getItem('SelectedFormation')) ? NC.DEFAULT_FORMATION : sessionStorage.getItem('SelectedFormation'),
            LineupFormationList: [],
            LineupsList: [],
            LineupsListClone: [],
            TransferPlayerIn: [],
            TransferPlayerOut: [],
            team_info: [],
            PlayerData: [],
            BoosterRef: [],

            isRulesViolates: false,
            isClubChanged: false,
            isClubRemoved: false,
            lineup_lastupdate: new Date().valueOf()

        }
        this.RosterRef = React.createRef();
        this.BoosterRef = React.createRef();
    }
    componentWillMount() {
        this.getAllTeam();
        this.getLineupMasterData();
        this.isLineupView();
        this.updateDimensions();
        let advBanner = WSManager.getAdBanner();
        if(advBanner && advBanner.advertisements != undefined)
        {
           this.callAdvBanner(advBanner);
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    contextLocalInit = (context) => {
        this.setState({
            contextLocal: context
        });
    }

     /**
     * ADVERSTMENT
     */
    callAdvBanner = (data) => {
        let bannerData = data.advertisements;
        let lowerBanner = [];
                let squareBanner = [];
                let topPtcBanner = [];
                let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
                for (let k = 0; k < bannerData.length; k++) {
                    if (bannerData[k].ad_position_id == 9 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        topPtcBanner.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 10 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        lowerBanner.push(bannerData[k])
                    }
                    if (bannerData[k].ad_position_id == 11 && bannerData[k].league_id == sportsLg) {
                        bannerData[k]["src"] = bannerData[k].image_adsense
                        bannerData[k]["id"] = bannerData[k].ads_unique_id
                        squareBanner.push(bannerData[k])
                    }
                }
                this.setState({
                    lowerBanner: lowerBanner,
                    squareBanner:squareBanner,
                    topPtcBanner:topPtcBanner
                }, () => {
                    this.getBannerIndex();
                    // console.log(this.state.squareBanner,'sdssdfsquareBanner')
                })
}
     /**
     * BANNER CLICK FOR LOWER BANNER 
     */
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
            this.task(this.state.lowerBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.lowerBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }

    getBannerClick = (from) => {
        if (from == 2) {
            window.open(this.state.currentBanner);
        }

    }

    render() {
        const {
            TeamList,
            LineupMasterData,
            LineupsList,
            LineupsListClone,
            SelectedFormation,
            next_week,
            next_week_start_time,
            user_join_count,
            is_editable,
            show_transfer,
            isMyTeamScreen,
            FieldLoader,

            isFullFieldViewMode,
            team_info,
            PlayerCardModalShow,
            isPlayerCardFieldView,
            PlayerData,
            DrawerRosterOpen,
            ManageTeamData,
            isMakeTransferActive,
            PitchViewLoaderInit,
            ConfirmTransferModalShow,
            TransferPlayerIn,
            TransferPlayerOut,
            apply_wildcard,
            TransferUpdateModalShow,

            contextLocal,
            lineup_lastupdate
        } = this.state;

        // Instance Properties
        // ------------------------------
        const RosterProps = {
            TeamList: TeamList,
            LineupsList: LineupsList,
            LineupMasterData: LineupMasterData,
            ManageTeamData: ManageTeamData,
            is_editable: is_editable,
            apply_wildcard: apply_wildcard,
            isMakeTransferActive: isMakeTransferActive,
            AddPlayerToLineup: this.AddPlayerToLineup,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            rosterContainsObject: this.rosterContainsObject,
            playerCardModalInit: this.playerCardModalInit,
            locale: this.props.locale,
            language: this.props.language,
            AllPositionList :this.state.AllPositionList,
            fromWindow : 1,
            
        }
        const RosterDrawerProps = {
            ...this.props,
            overlayColor: "rgba(0,0,0,0.8)",
            width: 320,
            right: true,
            fadeOut: true,
            open: DrawerRosterOpen,
            noTouchOpen: false
        };
        const FieldViewProps = {
            lineups: LineupsList,
            SelectedFormation: SelectedFormation,
            user_join_count: user_join_count,
            is_editable: is_editable,
            show_transfer: show_transfer,
            isMyTeamScreen: isMyTeamScreen,

            team_info: team_info,
            isFullFieldViewMode: isFullFieldViewMode,
            playerNameSort: this.playerNameSort,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            PositionSelectChange: this.PositionSelectChange,
            displayPosition: this._displayPosition,
            playerCardModalInit: this.playerCardModalInit,
            DrawerRosterTrigger: this.DrawerRosterTrigger,
            isSwapingEnabled: false,
            lineup_lastupdate: lineup_lastupdate
        }
        const DeadlineClockProps = {
            position: 'left',
            date: next_week_start_time,
            gameweek: next_week
        }
        const PlayerCardProps = {
            modalHide: this.modalHide,
            _displayPosition: this._displayPosition,
            rosterContainsObject: this.rosterContainsObject,
            AddPlayerToLineup: this.AddPlayerToLineup,
            RemovePlayerFromLineup: this.RemovePlayerFromLineup,
            LineupUpdate: this.LineupUpdate,
            isMakeTransferActive: isMakeTransferActive,
            PlayerCardModalShow: PlayerCardModalShow,
            PlayerData: PlayerData,
            isPlayerCardFieldView: isPlayerCardFieldView,
            LineupsList: LineupsList,
            user_join_count: user_join_count,
            is_editable: is_editable,
        }
        const ConfirmTransferProps = {
            ...this.props,
            modalHide: this.modalHide,
            TransfersCast: this.TransfersCast,
            _availableBudget: this._availableBudget,
            ApplyWildcardAction: this.ApplyWildcardAction,
            LineupsList: LineupsList,
            ConfirmTransferModalShow: ConfirmTransferModalShow,
            apply_wildcard: apply_wildcard,
            TransferPlayerIn: TransferPlayerIn,
            TransferPlayerOut: TransferPlayerOut,
            team_info: team_info,
            next_week_start_time: next_week_start_time,
            next_week: next_week,
        }
        const TransferUpdateModalProps = {
            modalShow: this.modalShow,
            modalHide: this.modalHide,
            TransferUpdateModalShow: TransferUpdateModalShow,
            LineupsList: LineupsList,
        }
        const RosterView = (
            <div className='drawer-roster-view'>
                {
                    user_join_count == 1 &&
                    !_.isEmpty(TeamList) &&
                    !_.isEmpty(LineupMasterData) &&
                    <Roster ref={this.RosterRef} {...RosterProps} availableBudget = {this._availableBudget(LineupsList)}/>
                }
            </div>
        )

        const WildCardPopover = (
            <Popover id="WildCardPopover" className='booster-popover wildcard'>
                <figure className="booster-image wild-card"></figure>
                <div className="booster-title">{contextLocal && contextLocal.state.locale.Booster.wild_card}</div>
                <div className="booster-content">{contextLocal && contextLocal.state.locale.Booster.wild_card_desc}</div>
                {
                    !_.isEmpty(team_info) &&
                    <Button bsStyle='primary' block className='m-t'
                        disabled={false} onClick={() => this.ApplyWildcardAction(apply_wildcard)}>
                        {(apply_wildcard == true || (team_info.wildcard_status.is_applied == 1 && team_info.wildcard_status.applied_week == ManageTeamData.next_week)) ? contextLocal && contextLocal.state.locale.Booster.disable : contextLocal && contextLocal.state.locale.Booster.apply}
                    </Button>
                }
            </Popover>
        )
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className='container main-container'>
                    {this.state.topPtcBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.topPtcBanner}
                                 duration={durationForBanner}
                             />
                         </div>}
                        <AdsContainer />
                        <Row className='d-block d-sm-block d-md-block d-lg-none'>
                            {
                                context.state.language == 'ar' ? RosterDrawerProps.right = false : RosterDrawerProps.right = true
                            }
                            <Col sm={12}>
                                <Drawer className='drawer-roster-wrapper' {...RosterDrawerProps}
                                    onChange={open => this.setState({ DrawerRosterOpen: open })}>
                                    <Alert stack={{ limit: 1 }} position='top-right' offset={15} html={true} effect='flip' />
                                    {
                                        user_join_count == 1 &&
                                        !_.isEmpty(TeamList) &&
                                        !_.isEmpty(LineupMasterData) &&
                                        <div className="drawer-roster-header">
                                            <span className="roster-back-btn icon-previousArrow" onClick={() => this.setState({ DrawerRosterOpen: false })}></span>
                                            {
                                                next_week_start_time &&
                                                <DeadlineClock {...DeadlineClockProps} {...context}  />
                                            }
                                            <div className="info-block">
                                                <div className="title">{context.state.locale.MyTeam.player_selected}</div>
                                                <div className="value">{this.isSelectAnyPlayer(LineupsList)}/{NC.MAX_PLAYER}</div>
                                            </div>
                                            <div className="budget-box">
                                            
                                                <div className="title">{context.state.locale.MyTeam.available_budget}</div>
                                                <div className="value">
                                                {
                                                    context.state.language != 'ar' &&
                                                    <Fragment>
                                                        {context.state.locale.MyTeam.currency_label}
                                                        {" "}
                                                    </Fragment>
                                                }
                                                 {this._availableBudget(LineupsList)}{NC.SALARY_UNIT}</div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.defaultRosterWidth > this.state.innerWidth ? RosterView : ''}
                                </Drawer>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={8} >

                                <header className="fieldinfo-header transfer-info-header">
                                    {
                                        next_week_start_time &&
                                        <span className='pull-right m-t'>
                                            <DeadlineClock {...DeadlineClockProps} {...context} position='right' />
                                        </span>
                                    }
                                    <div className="overflow">
                                        <div className="title text-lg xtext-black">{context.state.locale.MyTeam.hero_title}</div>
                                        <div className="subtitle">{context.state.locale.MyTeam.max_team}{WSManager.getMultiLegData().player_per_team[this.state.active_week]}{context.state.locale.MyTeam.max_team_1}</div>
                                    </div>
                                </header>
                                <div className={`roster-filter-loader fade ${FieldLoader ? 'in' : null}`}><RosterLoader /></div>

                                {
                                    user_join_count == 1 &&
                                    <div className="field-control-wrap transfer">

                                        <div className="info-block">
                                            <div className="title">{context.state.locale.MyTeam.available_budget}</div>
                                            <div className="value">
                                                {
                                                    context.state.language != 'ar' &&
                                                    <Fragment>
                                                        {context.state.locale.MyTeam.currency_label}
                                                        {" "}
                                                    </Fragment>
                                                }
                                            {this._availableBudget(LineupsList) + NC.SALARY_UNIT} </div>
                                        </div>
                                        <span className='d-none d-sm-none d-md-block d-lg-block'>
                                            <div className="info-block">
                                                <div className="title">{context.state.locale.MyTeam.player_selected}</div>
                                                <div className="value">{this.isSelectAnyPlayer(LineupsList)}/{NC.MAX_PLAYER}</div>
                                            </div>

                                            {
                                                is_editable == 0 &&
                                                <div className="info-block">
                                                    <div className="title">{context.state.locale.MyTeam.free_transfers} {this.state.team_info.free_hit_booster_status.is_applied == 0 ? '(' + this.TransfersCast(team_info.available_transfer) + ' ' + context.state.locale.MyTeam.cost + ')' : ''}</div>
                                                    <div className="value teal">{this.AvailableFreeTransfers(team_info.available_transfer)}</div>
                                                </div>
                                            }
                                        </span>

                                        <div className="field-control">
                                            {
                                                // is_editable == 1 &&
                                                // !_.isEqual(LineupsListClone, LineupsList) &&
                                                <span className={`control-btn autopick ${FieldLoader ? 'disabled' : null}`} onClick={!FieldLoader ? (() => this.AutoCompleteLineupCreate()) : null}>
                                                    <i className="icon-automatic"></i>
                                                    <div className="label">{context.state.locale.MyTeam.auto_pick}</div>
                                                </span>
                                            }
                                            {/* {
                                                !_.isNull(this.RosterRef.current) &&
                                                <Fragment>
                                                    {
                                                        is_editable == 0 &&
                                                        <button
                                                            className={`control-btn ${(apply_wildcard == true || (team_info.wildcard_status.is_applied == 1 && team_info.wildcard_status.applied_week == ManageTeamData.next_week)) ? 'wildcard-active' : ''}`}
                                                            disabled={
                                                                (team_info.wildcard_status.applied_week == ManageTeamData.next_week)
                                                                || (team_info.bench_booster_status.is_applied == 1 && team_info.bench_booster_status.applied_week == ManageTeamData.next_week)
                                                                || (team_info.captain_booster_status.is_applied == 1 && team_info.captain_booster_status.applied_week == ManageTeamData.next_week)
                                                                || (team_info.free_hit_booster_status.is_applied == 1 && team_info.free_hit_booster_status.applied_week == ManageTeamData.next_week)
                                                                || this.RosterRef.current.state.allow_wildcard == 0} onClick={() => this.ApplyWildcardAction(apply_wildcard)}>
                                                            <i className="icon-wildcard"></i>
                                                            <div className="label">{context.state.locale.MyTeam.wildcard}</div>
                                                        </button>
                                                    }
                                                </Fragment>
                                            } */}
                                            {
                                                !_.isNull(this.RosterRef.current) &&
                                                <Fragment>
                                                    {
                                                        is_editable == 0 &&
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="bottom"
                                                            overlay={WildCardPopover}>
                                                            <button
                                                                className={`control-btn ${(apply_wildcard == true || (team_info.wildcard_status.is_applied == 1 && team_info.wildcard_status.applied_week == ManageTeamData.next_week)) ? 'wildcard-active' : ''}`}
                                                                disabled={
                                                                    (team_info.wildcard_status.applied_week == ManageTeamData.next_week)
                                                                    || (team_info.bench_booster_status.is_applied == 1 && team_info.bench_booster_status.applied_week == ManageTeamData.next_week)
                                                                    || (team_info.captain_booster_status.is_applied == 1 && team_info.captain_booster_status.applied_week == ManageTeamData.next_week)
                                                                    || (team_info.free_hit_booster_status.is_applied == 1 && team_info.free_hit_booster_status.applied_week == ManageTeamData.next_week)
                                                                    || this.RosterRef.current.state.allow_wildcard == 0} 
                                                                    onClick={() => this.contextLocalInit(context)}>
                                                                <i className="icon-wildcard"></i>
                                                                <div className="label">{context.state.locale.MyTeam.wildcard}</div>
                                                            </button>
                                                        </OverlayTrigger>
                                                    }
                                                </Fragment>
                                            }

                                            

                                            <button className='control-btn' disabled={_.isEqual(LineupsListClone, LineupsList)} onClick={() => this.UndoMychanges()}>
                                                <i className="icon-refresh"></i>
                                                <div className="label">{context.state.locale.MyTeam.undo}</div>
                                            </button>

                                        </div>

                                        <div className='d-block d-sm-block d-md-none d-lg-none clearfix m-t'>
                                            <div className="info-block">
                                                <div className="title">{context.state.locale.MyTeam.player_selected}</div>
                                                <div className="value">{this.isSelectAnyPlayer(LineupsList)}/{NC.MAX_PLAYER}</div>
                                            </div>

                                            {
                                                is_editable == 0 &&
                                                <div className="info-block">
                                                    <div className="title">{context.state.locale.MyTeam.free_transfers} {this.state.team_info.free_hit_booster_status.is_applied == 0 ? '(' + this.TransfersCast(team_info.available_transfer) + ' ' + context.state.locale.MyTeam.cost + ')' : ''}</div>
                                                    <div className="value teal">{this.AvailableFreeTransfers(team_info.available_transfer)}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.isRulesViolates
                                    ?
                                        <div Style = {'display: flex; flex-direction: row; align-items: flex-start; border: red 1px solid; border-radius: 4px; padding: 10px; '}>

                                            <img Style = {'margin-right: 10px;'} src = {Images.transferError} alt = {''}></img>
                                            <div className="subtitle-tranfer-text-error-red">
                                                {context.state.locale.confirm_transfer.violating_team_combination}
                                            </div>
                                        </div>
                                    :
                                        this.state.isClubChanged || this.state.isClubRemoved
                                        ?
                                            <div Style = {'display: flex; flex-direction: row; align-items: flex-start; border: yellow 1px solid; border-radius: 4px; padding: 10px; '}>

                                                <img Style = {'margin-right: 10px;'} src = {Images.transferWarning} alt = {''}></img>
                                                <div className="subtitle-tranfer-text-error-yellow">
                                                    {context.state.locale.confirm_transfer.necessary_changes}
                                                </div>
                                            </div>
                                        :
                                            null
                                }
                                
                                <div className="subtitle-tranfer-text">{context.state.locale.confirm_transfer.transfer_will_take}</div>
                                {/* necessary_changes, violating_team_combination */}

                                {/* FIELDVIEW : START */}
                                <Tab.Container id="LineupFieldView" defaultActiveKey="PitchView">
                                    <Row className={`clearfix ${user_join_count == 1 ? 'm-t-sm' : ''}`}>
                                        <Col sm={12}>
                                            <Nav className="fieldview-tabnav">
                                                <NavItem className='tabnav' eventKey="PitchView">{context.state.locale.MyTeam.pitch}</NavItem>
                                                <NavItem className='tabnav' eventKey="ListView">{context.state.locale.MyTeam.list}</NavItem>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="info-notification">
                                                {
                                                    user_join_count == 1 ?
                                                        <span className="notify">{context.state.locale.MyTeam.captain_msz}</span>
                                                        :
                                                        <span className="notify">{context.state.locale.MyTeam.captain_msz}</span>
                                                }
                                                <div className='d-none d-sm-none d-md-none d-lg-block'>
                                                    <Alert stack={{ limit: 1 }} position='top' offset={15} html={true} effect='flip' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content animation>
                                                <Tab.Pane eventKey="PitchView">
                                                    {
                                                        (_.isEmpty(LineupsList) || PitchViewLoaderInit) &&
                                                        <PitchViewLoader />
                                                    }
                                                    {
                                                        !_.isEmpty(LineupsList) && !PitchViewLoaderInit &&
                                                        <PitchViewField {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="ListView">
                                                    {
                                                        !_.isEmpty(LineupsList) &&
                                                        <ListViewField {...FieldViewProps} />
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>

                                {/* Transfer & Edit screen Control */}
                                {
                                    is_editable == 1 &&
                                    <div className="field-control-footer">
                                        <Button bsStyle="primary" disabled={(this.isSelectAnyPlayer(LineupsList) != LineupsList.length) || _.isEqual(LineupsListClone, LineupsList)} onClick={() => this.LineupProccessInit()}>{context.state.locale.MyTeam.save}</Button>
                                    </div>
                                }
                                {
                                    isMakeTransferActive && is_editable == 0 &&
                                    <div className="field-control-footer">
                                        <div className="control-info">{context.state.locale.MyTeam.select_players_transfer_out}</div>
                                        {/* <Button bsStyle="primary" disabled={(this.isSelectAnyPlayer(LineupsList) != LineupsList.length) || _.isEqual(LineupsListClone, LineupsList)} onClick={() => this.modalShow('ConfirmTransferModalShow')}>{context.state.locale.MyTeam.make_transfers}</Button> */}
                                        {/* <Button bsStyle="primary" disabled={(this.isSelectAnyPlayer(LineupsList) != LineupsList.length) || _.isEqual(LineupsListClone, LineupsList) || ((TransferPlayerOut.length == TransferPlayerIn.length ) && (TransferPlayerOut.length < team_info.available_transfer ))} onClick={() => this.modalShow('ConfirmTransferModalShow')}>{context.state.locale.MyTeam.make_transfers}</Button> */}
                                        <Button bsStyle="primary" disabled={(this.isSelectAnyPlayer(LineupsList) != LineupsList.length) || _.isEqual(LineupsListClone, LineupsList) || (( TransferPlayerIn.length <= 0 ))} onClick={() => this.modalShow('ConfirmTransferModalShow')}>{context.state.locale.MyTeam.make_transfers}</Button>
                                    </div>
                                }

                                {/* FIELDVIEW : END */}
                            </Col>
                            <Col lg={4}>
                                {
                                    this.state.defaultRosterWidth <= this.state.innerWidth ? RosterView : ''
                                }
                            </Col>
                        </Row>
                        <Row>
                        <Col lg={8} md={12}> {
                             this.state.lowerBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.lowerBanner}
                                 duration={durationForBanner}
                             />
                         </div>
                        }</Col>
                        <Col lg={4} className='hide-sm'>
                            {this.state.squareBanner && <div className='sponsor-logo-bg sq-banner' onClick={(e) => { this.getBannerClick('1') }}>
                             <SimpleImageSlider
                                 showArrows={false}
                                 showDots={false}
                                 data={this.state.squareBanner}
                                 duration={durationForBanner}
                             />
                         </div>}
                        </Col>
                       </Row>
                        {/* <div id = {'Leaderboard'} className='sponsor-logo-bg '></div> */}
                        
                        {
                            ConfirmTransferModalShow &&
                            <ConfirmTransfer {...ConfirmTransferProps} locale={context.state.locale} language={context.state.language}/>
                        }
                        {
                            PlayerCardModalShow &&
                            <PlayerCard {...PlayerCardProps} locale={context.state.locale} language={context.state.language}/>
                        }
                        {
                            TransferUpdateModalShow &&
                            <TransferUpdateModal {...TransferUpdateModalProps} {...this.props} />
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}