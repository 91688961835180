/* eslint-disable eqeqeq, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, no-sequences */



import React from 'react';
import {
    Dropdown,
    Col,
    Row,
    MenuItem,
    Tab,
    Nav,
    NavItem
} from 'react-bootstrap';
import Img from '../components/images'

import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";


import InfiniteScroll from 'react-infinite-scroll-component';
import RosterLoader from '../views/RosterLoader';
import PitchViewField from '../views/PitchView';
import PitchViewLoader from '../views/PitchViewLoader';
import ListViewField from '../views/ListViewField';
import PlayerCard from '../Modals/PlayerCard';
import Drawer from 'react-motion-drawer';

import Slider from "react-slick";

import { notify } from 'react-notify-toast';

import SimpleImageSlider from "ac-react-simple-image-slider";
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
var durationForBanner = 10;

export default class LeagueDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            hasMore: false,
            is_owner: 0,
            isLoaderShow: true,
            PitchViewLoaderInit: true,
            PlayerCardModalShow: false,

            DrawerRosterOpen: false,
            defaultRosterWidth: 991,

            SelectedWeek: '',
            SelectedLeaderboard: {},
            SelectedLeaderboardItem: {},
            SelectedScoringMonths: {},
            OwnLeaderboard: {},
            LeaderboardList: [],
            LineupsList: [],
            inviteData: '',
            context: '',
            LeaderboardCurrentOffset: NC.CURRENT_OFFSET,
            week_info:  JSON.parse(localStorage.getItem('week_info')),
            AllWeek: []
        }
    }
    // API Fatch
    getUserLeaderboardList = () => {
        this.setState({ isLoaderShow: true })
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }
        const { state }  = this.props.location;

        const ExecuteLeaderboardList = function(responseJson, _this) {
            _.map(responseJson.global_league, (item, i) => {
                item.league_type = 'global_league';
                item.module = i;
                return item
            })
            _.map(responseJson.other_league, (item, i) => {
                item.league_type = 'other_league';
                return item.module = i
            })
            _this.setState({
                GlobalLeagueList: responseJson.global_league,
                OtherLeagueList: responseJson.other_league,
                SelectedLeaderboard: !_.isUndefined(_this.props.location.state) ? _this.props.location.state : responseJson.global_league['overall_standing']
            }, () => {
                _this.isPrivateLeague()
                _this.getLeaderboardMasterData(_this.state.SelectedLeaderboard);
            })
        }

        if(_.isUndefined(state)) {
            WSManager.Rest(API.GET_USER_LEADERBOARD_LIST, param).then((responseJson) => {
                this.setState({ isLoaderShow: false })
                if (responseJson.response_code === NC.successCode) {

                    ExecuteLeaderboardList(responseJson.data, this)
    
                }
            })
        } else {
            ExecuteLeaderboardList(state.LeaderboardList, this)
        }
        
    }

    getLeaderboardMasterData = (data) => {
        this.setState({ isLoaderShow: true });
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id": data.contest_id,
        }
        WSManager.Rest(API.CONTEST_LEADERBOARD_MASTER_DATA, param).then((responseJson) => {
            this.setState({ isLoaderShow: false });
            if (responseJson.response_code === NC.successCode) {
                this.setState({
                    LeaderboardMasterData: responseJson.data.master_data,
                    is_owner: responseJson.data.master_data.is_owner,
                    SelectedScoringMonths: responseJson.data.master_data.scoring_months[0]
                })
                this.getUserLeaderboardDetails(data, responseJson.data.master_data.scoring_months[0], NC.CURRENT_OFFSET);
            }
        })
    }

    getUserLeaderboard = (Arr) => {
        return _.filter(Arr, (item, i) => {
            item.isPrivate = true;
            return item.lineup_master_id == this.state.LeaderboardMasterData.lineup_master_id
        })[0]
    }

    isPrivateLeague = () => {
        const { SelectedLeaderboard } = this.state;
        return SelectedLeaderboard.league_type == 'other_league'
    }

    getUserLeaderboardDetails = (data, filter, LeaderboardCurrentOffset) => {
        this.setState({
            isLoaderShow: true,
        });
        // data.league_type = data.league_type == 'completed_monthly_league' ? 'active_monthly_league' : data.league_type;
        
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "league_type": data.league_type,
            "module": data.league_type == 'other_league' ? 'overall_standing' : ((data.league_type == 'completed_monthly_league' || data.league_type ==  "active_monthly_league") ? 'monthly_standing' : data.module),

            "module_id": data.league_type == 'other_league' ? '0' : data.module_id,
            "contest_id": data.contest_id,
            "month": filter.value,
            "offset": LeaderboardCurrentOffset,
            "limit": NC.ITEMS_PERPAGE_LG
        }
        
        WSManager.Rest(API.GET_USER_LEADERBOARD_DETAILS, param).then((responseJson) => {
            this.setState({
                isLoaderShow: false,
            });
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;
                
                if (!_.isUndefined(responseJson.own_leaderboard)) {
                    var LeaderboardList = this.state.LeaderboardList
                    if (LeaderboardCurrentOffset == NC.CURRENT_OFFSET) {
                        LeaderboardList = [];
                        this.setState({
                            LeaderboardList: LeaderboardList,
                            OwnLeaderboard: responseJson.own_leaderboard || this.getUserLeaderboard(responseJson.leaderboard),
                            SelectedLeaderboardItem: responseJson.own_leaderboard || responseJson.leaderboard[0],
                            active_week: responseJson.game_week
                        });
                    }
                    this.setState({
                        LeaderboardList: _.concat(LeaderboardList, responseJson.leaderboard),
                        hasMore: responseJson.load_more
                    })
                    if (LeaderboardCurrentOffset == NC.CURRENT_OFFSET) {
                        this.getUserLineupDetail({
                            contest_id: data.contest_id, 
                            lineup_master_id: this.state.LeaderboardMasterData.lineup_master_id, 
                            active_week: responseJson.game_week, 
                            autoSlide: true
                        })
                    }
                } else {
                    
                    if(this.state.SelectedLeaderboard.league_type == "other_league") {
                        let LeaderboardList = this.state.LeaderboardList
                        if (LeaderboardCurrentOffset == NC.CURRENT_OFFSET) {
                            LeaderboardList = [];
                            this.setState({
                                LeaderboardList: LeaderboardList,
                                SelectedLeaderboardItem: responseJson.leaderboard[0],
                                active_week: responseJson.game_week,
                                OwnLeaderboard: {}
                            });
                        }
                        this.setState({
                            LeaderboardList: _.concat(LeaderboardList, responseJson.leaderboard),
                            hasMore: responseJson.load_more
                        })
                        if (LeaderboardCurrentOffset == NC.CURRENT_OFFSET) {
                            this.getUserLineupDetail({
                                contest_id: data.contest_id, 
                                lineup_master_id: this.state.LeaderboardMasterData.lineup_master_id, 
                                active_week: responseJson.game_week, 
                                autoSlide: true
                            })
                        }

                    } else {
                        let opps_msz = !_.isUndefined(this.props.location.state) ? this.props.location.state.oops_msz : this.state.context.state.locale.Leagues.oops_msz;
                        notify.show(opps_msz, "success", 5000);
                        setTimeout(() => {
                          this.props.history.push('/leagues')
                        }, 5000)
                    }

                    
                    // this.setState({
                    //     SelectedLeaderboard: this.state.GlobalLeagueList['overall_standing']
                    // }, () => {
                    //     this.getLeaderboardMasterData(this.state.SelectedLeaderboard);
                    // })
                }
            }
        })
    }

    getUserLineupDetail = (options) => {
        const { contest_id, active_week, lineup_master_id} = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_LINEUP_DETAIL, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;

                this.setState({
                    team_name: responseJson.team_name,
                    default_formation: responseJson.default_formation,


                }, () => {
                    _.map(responseJson.lineup, (player) => {
                        player.display_position = this._displayPosition(player.position)
                    })
                    
                    if (responseJson.lineup.length > 0) {
                        this.setState({
                            LineupsList: responseJson.lineup,
                            isLoaderShow: false,
                            PitchViewLoaderInit: false
                        });
                    } else {
                        this.setState({
                            LineupsList: [],
                            PitchViewLoaderInit: false
                        });
                    }
                    this.getUserTeamInfo(options)
                })
            }
        })
    }

    getUserTeamInfo = (options) => {
        const { contest_id, active_week, lineup_master_id, autoSlide } = options;
        let param = {
            // "league_id": NC.leagueId,
            // "sports_id": NC.sportsId,
            "sports_id": WSManager.getMultiLegData().sports_id,
            "league_id": WSManager.getMultiLegData().league_id,
            "lineup_master_id": lineup_master_id,
            "contest_id": contest_id,
            "week": active_week // Live week (Prev Scoring week)
        }
        WSManager.Rest(API.GET_USER_TEAM_INFO, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                responseJson = responseJson.data;

                this.setState({
                    team_info: responseJson.team_info,
                    next_week: responseJson.team_info.scoring_weeks.length > 0 ? _.last(responseJson.team_info.scoring_weeks).week : ''
                }, () => {
                    if (responseJson.team_info.scoring_weeks.length > 0) {
                        var tmpAllWeek = [];
                        var tmpSelectedWeek = ''

                        _.map(responseJson.team_info.scoring_weeks, (week) => {
                            tmpAllWeek.push(week);
                            if (this.state.SelectedWeek == '' && active_week == week.week) {
                                tmpSelectedWeek = week;
                            } else if (week.week == this.state.SelectedWeek.week) {
                                tmpSelectedWeek = week;
                            }
                        })
                        this.setState({
                            AllWeek: tmpAllWeek,
                            SelectedWeek: tmpSelectedWeek
                        })
                        if(autoSlide) {
                            setTimeout(() => {
                                if (!_.isUndefined(this.slider)) {
                                    this.slider.slickGoTo(this.state.SelectedWeek.week)
                                }
                            }, 50);
                        }
                    }
                    this._isFullFieldViewMode();
                })
            }
        })
    }


    getInviteCode(contest_id) {
        let param = {
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id": contest_id
        }
        WSManager.Rest(API.GET_INVITE_CODE, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ inviteData: responseJson.data })
            }
        })
    }
    LeaveLeague = () => {
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id": this.state.SelectedLeaderboard.contest_id
        }
        WSManager.Rest(API.LEAVE_LEAGUE, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.props.history.push({ pathname: '/leagues' })
            }
        })
    }

    // Utility
    fetchMoreData = () => {
        let LeaderboardCurrentOffset = this.state.LeaderboardCurrentOffset + NC.ITEMS_PERPAGE_LG;
        this.setState({ LeaderboardCurrentOffset: LeaderboardCurrentOffset });
        this.getUserLeaderboardDetails(this.state.SelectedLeaderboard, this.state.SelectedScoringMonths, LeaderboardCurrentOffset);
    }
    _isFullFieldViewMode = () => {
        this.setState({
            isFullFieldViewMode: false
        })
    }
    _displayPosition = (pos, type, CONTEXT, single) => {
        let display_pos = type != 'NAME' ? NC.DISPLAYNAME_GOALKEEPER : NC.DISPLAYNAME_GOALKEEPER_FULL
        switch (pos) {
            case NC.DEFENDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_DEFENDER : NC.DISPLAYNAME_DEFENDER_FULL
                break;
            case NC.MIDFIELDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_MIDFIELDER : NC.DISPLAYNAME_MIDFIELDER_FULL
                break;
            case NC.FORWARDER:
                display_pos = type != 'NAME' ? NC.DISPLAYNAME_FORWARDER : NC.DISPLAYNAME_FORWARDER_FULL
                break;
            default:
                break;
        }
        if (CONTEXT) {
            display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_GOALKEEPER : CONTEXT.DISPLAYNAME_GOALKEEPER_FULL
            switch (pos) {
                case NC.DEFENDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_DEFENDER : CONTEXT.DISPLAYNAME_DEFENDER_FULL
                    break;
                case NC.MIDFIELDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_MIDFIELDER : CONTEXT.DISPLAYNAME_MIDFIELDER_FULL
                    break;
                case NC.FORWARDER:
                    display_pos = type != 'NAME' ? CONTEXT.DISPLAYNAME_FORWARDER : CONTEXT.DISPLAYNAME_FORWARDER_FULL
                    break;
                default:
                    break;
            }
        }
        if (single) {
            display_pos = display_pos.slice(0, -1);
        }
        return display_pos;
    }
    playerNameSort = (name) => {
        if (!name) return
        var str = name.split(' ');
        str[0] = str[0].charAt(0)
        return str[0] + '. ' + str[str.length - 1]
    }
    SelectLeagueInit = (item, context) => {
        if (item.contest_info.joined_week <= context.state.week_info.active_week) {
            this.setState({
                context: context,
                SelectedLeaderboard: item,
            })
            this.getLeaderboardMasterData(item)
        } else {
            notify.show(context.state.locale.Leagues.oops_msz, "success", 1500);
        }
    }

    SelectedLeaderboardItemInit = (item) => {
        this.setState({
            SelectedWeek: '',
            SelectedLeaderboardItem: item,
            PitchViewLoaderInit: true,
            LineupsList: []
        })
        this.getUserLineupDetail({
            contest_id: this.state.SelectedLeaderboard.contest_id, 
            lineup_master_id: item.lineup_master_id, 
            active_week: this.state.active_week, 
            autoSlide: true
        })
    }
    handlerChangeWeek = (item) => {
        this.setState({
            SelectedWeek: item,
            PitchViewLoaderInit: true,
            LineupsList: []
        })

        this.getUserLineupDetail({
            contest_id: this.state.SelectedLeaderboard.contest_id, 
            lineup_master_id: this.state.SelectedLeaderboardItem.lineup_master_id, 
            active_week: item.week,
            autoSlide: false
        })
    }
    playerCardModalInit = (Modal, PlayerData, isPlayerCardFieldView) => {
        this.setState({
            [Modal]: true,
            PlayerData: PlayerData || {},
            isPlayerCardFieldView: isPlayerCardFieldView || false
        });
    }
    modalShow = (Modal) => {
        this.setState({ [Modal]: true });

    }
    modalHide = (Modal) => {
        this.setState({ [Modal]: false });
    }
    componentDidMount() {
        this.getUserLeaderboardList();
        window.addEventListener("resize", this.updateDimensions);
        //window.location.reload(true);
    }
    componentWillMount() {
        this.updateDimensions();
        let advBanner = WSManager.getAdBanner();
        if (advBanner && advBanner.advertisements != undefined) {
            this.callAdvBanner(advBanner);
        }
    }
    callAdvBanner = (data) => {
        let bannerData = data.advertisements;
        let upperBanner = []
        let lowerBanner = [];
        let squareBanner = [];
        let sportsLg = JSON.parse(localStorage.getItem('legData')).league_id
        for (let k = 0; k < bannerData.length; k++) {
            if (bannerData[k].ad_position_id == 14 && bannerData[k].league_id == sportsLg) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                upperBanner.push(bannerData[k])
            }
            else if (bannerData[k].ad_position_id == 1) {
                bannerData[k]["src"] = bannerData[k].image_adsense
                bannerData[k]["id"] = bannerData[k].ads_unique_id
                squareBanner.push(bannerData[k])
            }
        }
        this.setState({
            lowerBanner: lowerBanner,
            squareBanner: squareBanner,
            upperBanner: upperBanner
        }, () => {
            this.getBannerIndex();
            // console.log(this.state.squareBanner,'sdssdfsquareBanner')
        })
    }
    getBannerIndex = (e) => {
        for (let i = 0; i <= this.state.lowerBanner.length - 1; i++) {
            this.task(this.state.lowerBanner[i], i);
        }
    }
    task = (data, i) => {
        let a = this.state.lowerBanner.length - 1;
        setTimeout(function () {
            this.setState({
                currentBanner: data.target_url
            })
            if (a == i) {
                setTimeout(function () {
                    this.getBannerIndex();
                }.bind(this), durationForBanner * 1000)

            }
        }.bind(this), durationForBanner * 1000 * i);

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }
    DrawerRosterTrigger = (DrawerRosterOpen) => {
        this.setState({
            DrawerRosterOpen: !DrawerRosterOpen
        })
    }
    InviteModalToggle = (context) => {
        const { SelectedLeaderboard, inviteData, LeaderboardMasterData } = this.state;
        let param = {
            "league_id" : WSManager.getMultiLegData().league_id,
            "contest_id": SelectedLeaderboard.contest_id
        }
        if(_.isEmpty(inviteData)) {
            WSManager.Rest(API.GET_INVITE_CODE, param).then((responseJson) => {
                if (responseJson.response_code === NC.successCode) {
                    this.setState({ inviteData: responseJson.data }, () => {
                        context.modalShow('InviteFriendShow', {
                            leagueName: LeaderboardMasterData.contest_name, 
                            inviteData: responseJson.data 
                        })
                    })
                }
            })
        } else {
            context.modalShow('InviteFriendShow', {
                leagueName: LeaderboardMasterData.contest_name, 
                inviteData: inviteData 
            })
        }

    }
    goForTheChat = (e)=>{
        let data = this.props.location.state;
        this.props.history.push({ pathname: '/leagues/details/group-chat', data})
    }
    render() {
        var settings = {
            rtl: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 3,
            centerMode: false,
            variableWidth: false,
            centerPadding: '0px',
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        const {
            PitchViewLoaderInit,
            SelectedWeek,

            GlobalLeagueList,
            OtherLeagueList,
            SelectedLeaderboard,
            SelectedLeaderboardItem,

            LeaderboardList,
            OwnLeaderboard,
            isLoaderShow,
            hasMore,
            is_owner,

            AllWeek,
            team_info,
            LineupsList,
            isFullFieldViewMode,
            active_week,
            next_week,
            PlayerCardModalShow,
            PlayerData,
            DrawerRosterOpen,
            week_info

        } = this.state;

        const FieldViewProps = {
            lineups: LineupsList,
            SelectedFormation: {},
            user_join_count: 1,
            is_editable: 0,
            show_transfer: 0,
            isMyTeamScreen: false,

            team_info: team_info,
            isFullFieldViewMode: isFullFieldViewMode,
            playerNameSort: this.playerNameSort,
            RemovePlayerFromLineup: function () { },
            PositionSelectChange: function () { },
            displayPosition: this._displayPosition,
            playerCardModalInit: this.playerCardModalInit,
            DrawerRosterTrigger: this.DrawerRosterTrigger,
            isSwapingEnabled: false,
            next_week: next_week,
            active_week: active_week,
            isPointScreen: true,
            SelectedWeek: SelectedWeek.week
        }
        const PlayerCardProps = {
            modalHide: this.modalHide,
            _displayPosition: this._displayPosition,
            PlayerCardModalShow: PlayerCardModalShow,
            PlayerData: PlayerData,
            isPlayerCardFieldView: false,
            rosterContainsObject: function () { },
            AddPlayerToLineup: function () { },
            RemovePlayerFromLineup: function () { },
            LineupUpdate: function () { },
            LineupsList: LineupsList,
            user_join_count: 1,
        }
        const RosterDrawerProps = {
            ...this.props,
            overlayColor: "rgba(0,0,0,0.8)",
            width: "90%",
            right: true,
            fadeOut: true,
            open: DrawerRosterOpen,
            noTouchOpen: true,
            noTouchClose: true,
        };
        const LeaderboardTitleView = (
            <MyContext.Consumer>
                {(context) => (
                    <div className="leaderboard-title clearfix">
                        <Dropdown id="LeaderboardDropDown" className="leaderboard-dropdown">
                            <Dropdown.Toggle noCaret >
                                <div className="leaderboard-dropdown-title">
                                    {!_.isUndefined(SelectedLeaderboard.contest_info) ? (SelectedLeaderboard.category_name || SelectedLeaderboard.contest_info.contest_name) : ''}
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu leaderboard-dropdown-menu">
                                <MenuItem className="item head">
                                    <div className="cell">{context.state.locale.LeagueDetails.rank}</div>
                                    <div className="cell">{context.state.locale.LeagueDetails.league}</div>
                                </MenuItem>
                                {
                                    _.map(GlobalLeagueList, (item, idx) => {
                                        return (
                                            <MenuItem
                                                key={idx}
                                                className={`item ${item.is_country_set == 0 ? 'country-not-set' : ''} ${item.category_name == SelectedLeaderboard.category_name ? 'selected' : ''}`}
                                                data-line={item.is_country_set == 0 ? context.state.locale.Leagues.see_your_country_rank : ''}
                                                onClick={() => item.is_country_set == 0 ? this.props.history.push({ pathname: '/profile', state: item }) : this.SelectLeagueInit(item, context)}
                                            >
                                                <div className="cell">{item.rank}</div>
                                                <div className="cell">{item.category_name}</div>
                                            </MenuItem>
                                        )
                                    })
                                }
                                {
                                    _.map(OtherLeagueList, (item, idx) => {
                                        return (
                                            <MenuItem className={`item ${item.contest_info.contest_unique_id == SelectedLeaderboard.contest_info.contest_unique_id ? 'selected' : ''}`} key={idx} onClick={() => this.SelectLeagueInit(item, context)}>
                                                <div className="cell">{item.rank}</div>
                                                <div className="cell">{item.contest_info.contest_name}</div>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            SelectedLeaderboard.league_type == 'other_league' &&
                            is_owner == 1 &&
                            <a className="icon-share" onClick={() => this.InviteModalToggle(context)}></a>
                        }

                        {
                            SelectedLeaderboard.league_type == 'other_league' &&
                            <div className="leaderboard-member">
                                ({SelectedLeaderboard.total_user} {context.state.locale.LeagueDetails.members})
                                                    {
                                    SelectedLeaderboard.league_type == 'other_league' &&
                                    is_owner != 1 &&
                                    <a className='leave-league-btn' onClick={() => this.LeaveLeague()}>{context.state.locale.LeagueDetails.leave_league}</a>
                                }
                            </div>
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
        const LeaderboardUserListView = (
            <MyContext.Consumer>
                {(context) => (
                    <div className='leaderboard-user-list'>
                        {LeaderboardTitleView}
                        <div className="user-item head">
                            <div className="cell">{context.state.locale.LeagueDetails.rank}</div>
                            <div className="cell">{context.state.locale.LeagueDetails.name}</div>
                            <div className="cell">{context.state.locale.LeagueDetails.pts}</div>
                        </div>
                            {
                                !_.isEmpty(OwnLeaderboard) && _.isUndefined(OwnLeaderboard.isPrivate) &&
                                <ul className="user-item-list owner">
                                        <div className={`user-item-loader fade  ${isLoaderShow ? 'in' : ''}`}><RosterLoader /></div>

                                        <li className="user-item owner" onClick={() => (this.SelectedLeaderboardItemInit(OwnLeaderboard), this.DrawerRosterTrigger(DrawerRosterOpen))}>
                                            <div className={`cell rank-size-${OwnLeaderboard.position.length}`}>{OwnLeaderboard.position}</div>
                                            <div className="cell user-profile-item">
                                                <figure className="thumb">
                                                    <img src={OwnLeaderboard.user_image} alt="" />
                                                </figure>
                                                <div className="overflow">
                                                    <div className="username">{OwnLeaderboard.user_name}</div>
                                                    <div className="name">{OwnLeaderboard.first_name + ' ' + OwnLeaderboard.last_name}</div>
                                                </div>
                                            </div>
                                            <div className="cell">{OwnLeaderboard.total_score}</div>
                                        </li>
                                    </ul>
                            }
                        <InfiniteScroll
                            dataLength={LeaderboardList.length}
                            next={this.fetchMoreData}
                            hasMore={hasMore}
                            scrollableTarget='scrollbar'>
                            <ul className="user-item-list scroll UserItemListScroll" id="scrollbar">
                                {
                                    !(!_.isEmpty(OwnLeaderboard) && _.isUndefined(OwnLeaderboard.isPrivate)) &&
                                    <div className={`user-item-loader fade  ${isLoaderShow ? 'in' : ''}`}><RosterLoader /></div>
                                }
                                {
                                    _.map(LeaderboardList, (item, idx) => {
                                        return (
                                            <li className={`user-item  ${!_.isUndefined(OwnLeaderboard) && OwnLeaderboard.lineup_master_id == item.lineup_master_id ? 'owner' : ''} ${SelectedLeaderboardItem && Number(SelectedLeaderboardItem.lineup_master_id) == Number(item.lineup_master_id) ? 'selected' : ''}`} key={idx} onClick={() => (this.SelectedLeaderboardItemInit(item), this.DrawerRosterTrigger(DrawerRosterOpen))}>
                                                <div className="cell">{item.position}</div>
                                                <div className="cell user-profile-item">
                                                    <figure className="thumb">
                                                        <img src={item.user_image} alt="" />
                                                    </figure>
                                                    <div className="overflow">
                                                        <div className="username">{item.user_name}</div>
                                                        <div className="name">{item.first_name + ' ' + item.last_name}</div>
                                                    </div>
                                                </div>
                                                <div className="cell">{item.total_score == '' ? '--' : item.total_score}</div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </InfiniteScroll>
                    </div>
                )}
            </MyContext.Consumer>
        )

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="container main-container">
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.leaguesdetail_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.leaguesdetail_description} />
                        </Helmet>
                        <Row className='d-block d-sm-block d-md-block d-lg-none'>
                            <Col sm={12}>
                                {
                                    context.state.language == 'ar' ? RosterDrawerProps.right = false : RosterDrawerProps.right = true
                                }
                                <Drawer className='drawer-roster-wrapper' {...RosterDrawerProps}
                                    onChange={open => this.setState({ DrawerRosterOpen: open })}>
                                    {this.state.defaultRosterWidth > this.state.innerWidth ? LeaderboardUserListView : ''}
                                </Drawer>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>

                                <a className="back-btn-league" onClick={() => this.props.history.push({ pathname: '/leagues' })}>
                                    <i className="icon-previousArrow"></i>
                                    <span>{context.state.locale.LeagueDetails.back}</span>
                                </a>
                                {
                                    !_.isEmpty(SelectedLeaderboard) &&
                                    LeaderboardTitleView
                                }
                                 {
                                    this.props.location.state != undefined && this.props.location.state.league_type == "other_league" ?
                                    <div className='group-chat-div' onClick={()=>{this.goForTheChat()}}>
                                <span>{context.state.locale.LeagueDetails.group_chat}</span>
                                {/* <img src={Img.OVAL} alt="" className='ml10'/> */}
                                <div className="form_button">
                                            <img
                                                src={Img.CHAT_BUBBLE}
                                                alt=""
                                                className="s-18"
                                               // onClick={this.handleSend.bind(this)}
                                            />
                                            </div>
                                </div> : ""
                                }
                            </Col>
                        </Row>
                        <Row className='m-t-20'>
                            <Col lg={4}>
                                {
                                    this.state.defaultRosterWidth <= this.state.innerWidth ? LeaderboardUserListView : ''
                                }
                            </Col>
                            <Col md={12} lg={8}>
                                <div className='leaderboard-fieldview'>
                                    {
                                        !_.isEmpty(AllWeek) ?
                                        <div className="weeklist-slider-wrap">
                                            {
                                                !_.isEmpty(team_info) &&
                                                <Slider className="weeklist-slider" ref={slider => (this.slider = slider)} {...settings}>
                                                    {
                                                        _.map(AllWeek, (week, idx) => {
                                                            return (
                                                                week.week <= week_info.active_week &&
                                                                <div className={`weeklist-slider-item ${week.week == SelectedWeek.week ? 'selected' : ''}`} key={idx} onClick={() => this.handlerChangeWeek(week)}>
                                                                    <div>{WSManager.getWeekLabel( WSManager.getMultiLegData().week_label[week.week])}</div>
                                                                    <div className='rank-label'>{week.week_rank} {context.state.locale.LeagueDetails.rank}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            }
                                        </div>
                                        :
                                        <div className='alert alert-warning text-center'>
                                            {context.state.locale.LeagueDetails.league_standing_msz}
                                        </div>
                                    }
                                    <div className="leaderboard-user-details">
                                        {
                                            !_.isEmpty(team_info) && !_.isEmpty(SelectedLeaderboardItem) &&
                                            <div className="leaderboard-flex">
                                                <div className="cell user-profile-item">
                                                    <figure className="thumb">
                                                        <img src={SelectedLeaderboardItem.user_image} alt="" />
                                                    </figure>
                                                    <div className="overflow">
                                                        <div className="username">{SelectedLeaderboardItem.user_name}</div>
                                                        <div className="name">{SelectedLeaderboardItem.first_name + ' ' + SelectedLeaderboardItem.last_name}</div>
                                                    </div>
                                                </div>

                                                <div className="cell final-points flexwidth">
                                                    <div className="val">{SelectedWeek.total_score || '--'}</div>
                                                    <div className="label">{context.state.locale.LeagueDetails.final_points}</div>
                                                </div>

                                                <div className="cell info-block">
                                                    <div className="val">{SelectedWeek.avg_score || '--'}</div>
                                                    <div className="label">{context.state.locale.LeagueDetails.average_points}</div>
                                                </div>
                                                <div className="cell info-block">
                                                    <div className="val">{SelectedWeek.high_score || '--'}</div>
                                                    <div className="label">{context.state.locale.LeagueDetails.highest_points}</div>
                                                </div>
                                                <div className="cell final-points fullwidth">
                                                    <div className="val">{SelectedWeek.total_score || '--'}</div>
                                                    <div className="label">{context.state.locale.LeagueDetails.final_points}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        {/* {console.log(this.state.upperBanner,'upperBannerupperBanner')} */}
                                        {
                                            this.state.upperBanner && <div className='sponsor-logo-bg' onClick={(e) => { this.getBannerClick('2') }} id="slider">
                                                <SimpleImageSlider
                                                    showArrows={false}
                                                    showDots={false}
                                                    data={this.state.upperBanner}
                                                    duration={durationForBanner}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {/* FIELDVIEW : START */}
                                    <Tab.Container id="LineupFieldView" defaultActiveKey="PitchView">
                                        <Row className='fieldfiew-tab-container'>
                                            <Col sm={12}>
                                                <Nav className="fieldview-tabnav">
                                                    <NavItem className='tabnav' eventKey="PitchView">{context.state.locale.MyTeam.pitch}</NavItem>
                                                    <NavItem className='tabnav' eventKey="ListView">{context.state.locale.MyTeam.list}</NavItem>
                                                </Nav>
                                            </Col>


                                            <Col sm={12} className='m-t'>
                                                <Tab.Content animation>
                                                    <Tab.Pane eventKey="PitchView">
                                                        {
                                                            (_.isEmpty(LineupsList) && !PitchViewLoaderInit) &&
                                                            <div className="pitch-view">
                                                                <div className="singlefield">
                                                                    <i className="sponsor-space"></i>
                                                                    <i className="sponsor-space right"></i>
                                                                    <i className="goal"></i>
                                                                    <img src={Img.PADLOCK} alt="" className='pitchview-lock'/>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (_.isEmpty(LineupsList) && PitchViewLoaderInit) &&
                                                            <PitchViewLoader />
                                                        }
                                                        {
                                                            !_.isEmpty(LineupsList) && !PitchViewLoaderInit &&
                                                            <PitchViewField {...FieldViewProps} />
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="ListView">

                                                        <div className={`user-item-loader fade  ${(_.isEmpty(LineupsList) || PitchViewLoaderInit) ? 'in' : ''}`}><RosterLoader /></div>

                                                        {
                                                            !_.isEmpty(LineupsList) &&
                                                            <ListViewField {...FieldViewProps} />
                                                        }
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                    {/* FIELDVIEW : END */}



                                </div>
                            </Col>
                        </Row>
                        {
                            PlayerCardModalShow &&
                            <PlayerCard {...PlayerCardProps} locale={context.state.locale} language={context.state.language} />
                        }
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
