/* eslint-disable eqeqeq */
import * as NC from "./NetworkingConstants";
import { notify } from 'react-notify-toast';
import { Auth } from '../helper';
import config from '../config';

export default class WSManager {
    constructor() {
      this.login = this.login.bind(this)
      this.clearSession = this.clearSession.bind(this)
      this.getToken = this.getToken.bind(this)
    }
    // API 
    static getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static Rest(url, param = {}, mszHide){
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept-Language': this.getCookie('lang') === '' ? 'ar' : this.getCookie('lang'), //localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
                session_key: Auth.getAuth() || "",
            },
            body: JSON.stringify(param)
        })
        .then((response) => {
            return response.json()
        })
        .then(responseJson => {
            if (responseJson.response_code != NC.successCode) {
                var errorMsg = responseJson.message != '' ? responseJson.message : responseJson.global_error

                if (errorMsg == '') {
                    for (var key in responseJson.error) {
                        errorMsg = responseJson.error[key];
                    }
                }
                if (responseJson.response_code == NC.sessionExpireCode) {
                    this.sessionExpire(errorMsg)
                } else {
                    if (!mszHide) {
                        notify.show(errorMsg, "error", 5000);
                    }
                }
            }
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });

    }

    static https3 (s3url, url, body = {}, errorNotifyHide = false) {

        console.log('https3', s3url, '\n', url);
        const auth = Auth.getAuth();
        const token = (!!auth && auth) || null;
        const settings =
            token !== null
                ? {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        'Accept-Language': this.getCookie('lang') === '' ? 'ar' : this.getCookie('lang'), //localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
                        session_key: token
                    }
                }
                : {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        'Accept-Language': this.getCookie('lang') === '' ? 'ar' : this.getCookie('lang'), //localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
                    }
                };

        const s3Setting = {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'text/plain',
                'Access-Control-Allow-Methods': 'PUT, POST, DELETE, GET',
                'Access-Control-Allow-Origin': '*'
            }
        }
        const RestAPI = function(){
            return fetch(url, settings)
                .then((response) => {
                    return response.json()
                })
                .then(responseJson => {
                    if (responseJson.response_code != NC.successCode) {
                        var errorMsg = responseJson.message != '' ? responseJson.message : responseJson.global_error
        
                        if (errorMsg == '') {
                            for (var key in responseJson.error) {
                                errorMsg = responseJson.error[key];
                            }
                        }
                        if (responseJson.response_code == NC.sessionExpireCode) {
                            this.sessionExpire(errorMsg)
                        } else {
                            if (!errorNotifyHide) {
                                notify.show(errorMsg, "error", 5000);
                            }
                        }
                    }
                    return responseJson;
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if(config.s3.BUCKET_STATIC_DATA_ALLOWED == 1) {
            return fetch(s3url, s3Setting)
                .then((response) => {
                    return response.json()
                })
                
                .then(response => {
                    return {data: response, response_code: NC.successCode};
                })
                .catch((error) => {
                    return RestAPI()
                });
        } else {
           return RestAPI()
        }
    }

    static RestGet(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
                session_key: Auth.getAuth() || "",
            }
        })
            .then((response) => response.json())
            .then(responseJson => {
                if (responseJson.response_code != NC.successCode) {
                    var errorMsg = responseJson.message != '' ? responseJson.message : responseJson.global_error

                    if (errorMsg == '') {
                        for (var key in responseJson.error) {
                            errorMsg = responseJson.error[key];
                        }
                    }
                    if (responseJson.response_code == NC.sessionExpireCode) {
                        this.sessionExpire(errorMsg)
                    } else {
                        notify.show(errorMsg, "error", 5000);
                    }
                }
                return responseJson;
            })
            .catch((error) => {
                console.error(error);
            });

    }
    // Check Auth

    static loggedIn() {
        return localStorage.getItem('auth') !== null;
    }

    static sessionExpire(errorMsg) {
        notify.show(errorMsg, "error", 2500);
        localStorage.clear()
        window.location.reload()
    }
    static setMultiLeg(leg) {
        localStorage.setItem('leg',leg)
    }
    static getMultiLeg() {
        const leg = localStorage.getItem('leg')
        return leg ? JSON.parse(localStorage.leg) : {}
    }
    static setMultiLegData(legData) {
        localStorage.setItem('legData', JSON.stringify(legData))
    }
    static getMultiLegData() {
        const leg = localStorage.getItem('legData')
        return leg ? JSON.parse(localStorage.legData) : {}
    }

    static setProfile(profile) {
        // Saves profile data to localStorage
        localStorage.setItem('profile', JSON.stringify(profile))
    }

    static setWheelData(data) {
        // Saves wheeldata data to localStorage
        localStorage.setItem('wheeldata', JSON.stringify(data))
    }

    static getWheelData() {
        const data = localStorage.getItem('wheeldata')
        return data ? JSON.parse(localStorage.wheeldata) : {}
    }


    static getProfile() {
        // Retrieves the profile data from localStorage
        const profile = localStorage.getItem('profile')
        return profile ? JSON.parse(localStorage.profile) : {}
    }

    static setAdBanner(banner) {
        localStorage.setItem('banner', JSON.stringify(banner))
    }

    static getAdBanner() {
        const banner = localStorage.getItem('banner')
        return banner ? JSON.parse(localStorage.banner) : {}
    }

    static setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('auth', idToken);
    }

    static getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('auth')
    }

    static logout() {
        sessionStorage.clear();
        localStorage.clear();
        WSManager.setMultiLeg(false)
        WSManager.setMultiLegData(null);
    }
    static s3UrlConvert(param) {
        let s3 = config.s3;
        let url = [
            s3.BUCKET,
            s3.BUCKET_DATA_PREFIX,
            this.getCookie('lang'),
            ...param
        ]
        url = url.join('_')
        url = url.replace("/_","/")
        return url+'.json'
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    static getWeekLabel(week){
        if (!week) return '';
        return week
       // return week.replace(/[0-9]/g, '');
    }


}