/* eslint-disable react/no-direct-mutation-state, eqeqeq */
import React from 'react';
import { Button, Col, Row, FormGroup, Form } from 'react-bootstrap';
import FloatingLabel from 'floating-label-react';
import WSManager from "../helper/WSManager";
import {API} from "../helper";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import { inputStyle } from '../helper/input-style';
import Select from 'react-select';
import {Helmet} from "react-helmet";
import { MyContext } from '../context';
export default class CreateLeagues extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scoringStart: 0,
            gameWeekList: [],
            leagueName: '',
            minTeam: '',
            maxTeam: '',
            contestId: '',
            formValid: false,
            lineup_master_id: localStorage.getItem('lineup_master_id')

        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    getValidationState(type, value) {
        let isValidate = null;
        if (!_.isEmpty(value)) {
            switch (type) {
                case 'leagueName':
                    isValidate = value.length > 2 && value.length <= 30 ? 'success' : 'error';
                    break;

                case 'minTeam':
                    value = Number(value)
                    let maxTeam = Number(this.state.maxTeam)
                    if(maxTeam != 0 || value < 2) {
                        isValidate = value > 1 && value <= maxTeam ? 'success' : 'error';
                    }
                    break;

                case 'maxTeam':
                    value = Number(value)
                    let minTeam = Number(this.state.minTeam)
                    if(minTeam != 0 || value < 3) {
                        isValidate = value > 1 && value >= minTeam ? 'success' : 'error';
                    }
                    break;

                case 'scoringStart':
                    isValidate = !_.isEmpty(this.state.scoringStart) ? 'success' : 'error';
                    break;

                default:
                    break;
            }
        }
        return isValidate;
    }
    validateForm() {
        this.setState({
            formValid:
                this.getValidationState('leagueName', this.state.leagueName) == 'success'
                && this.getValidationState('minTeam', this.state.minTeam) == 'success'
                && this.getValidationState('maxTeam', this.state.maxTeam) == 'success'
                && this.getValidationState('scoringStart', this.state.scoringStart) == 'success'
        });
    }
    componentWillMount() {
        let weeks = JSON.parse(localStorage.getItem('week_info'))
        let param = {
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
        }

        WSManager.Rest(API.WEEK_BY_LEAGUE, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                // console.log(responseJson,'responseJsonresponseJson',
                // WSManager.getMultiLegData().week_label[weeks.active_week])
                const gameWeekListTmp = [];
                responseJson.data.map((item, key) => {
                    gameWeekListTmp.push({ value: item.week, label: `${'Gameweek'}   ${item.week}` })
                    return '';
                })
                this.state.gameWeekList = gameWeekListTmp
                this.setState({ gameWeekList: gameWeekListTmp })
            }
        })
    }


    onSubmit(context) {
        this.setState({
            posting: true
        })
     
        
        let param = {
            "game_name": this.state.leagueName,
            "minimum_size": this.state.minTeam,
            "size": this.state.maxTeam,
            // "sports_id": NC.sportsId,
            // "league_id": NC.leagueId,
            "sports_id" : WSManager.getMultiLegData().sports_id,
            "league_id" : WSManager.getMultiLegData().league_id,
            "start_week": this.state.scoringStart.value
        }

        WSManager.Rest(API.CREATE_PRIVATE_LEAGUE, param).then((responseJson) => {
            if (responseJson.response_code === NC.successCode) {
                this.setState({ contestId: responseJson.data.contest_id })
                let joinParam = {
                    // "league_id": NC.leagueId,
                    // "sports_id": NC.sportsId,
                    "sports_id" : WSManager.getMultiLegData().sports_id,
                    "league_id" : WSManager.getMultiLegData().league_id,
                    "contest_id": this.state.contestId,
                    "lineup_master_id": this.state.lineup_master_id,
                }

                WSManager.Rest(API.JOIN_CONTEST, joinParam).then((res) => {
                    if (res.response_code === NC.successCode) {
                        this.setState({
                            posting: false,
                            leagueName: responseJson.data.contest_detail.contest_name,
                            inviteData: responseJson.data.contest_detail
                        })
                        let GET_INVITE_CODE_PARAM = {
                            "league_id" : WSManager.getMultiLegData().league_id,
                            "contest_id": responseJson.data.contest_id
                        }
                        WSManager.Rest(API.GET_INVITE_CODE, GET_INVITE_CODE_PARAM).then((response) => {
                            if (response.response_code === NC.successCode) {
                                context.modalShow('InviteFriendShow', { leagueName: responseJson.data.contest_detail.contest_name, inviteData: response.data });
                                this.redirectToTarget()
                            } else {
                                this.redirectToTarget()
                            }
                        })
                    } else {
                        this.redirectToTarget()
                    }
                })
            } else {
                this.redirectToTarget()
            }
        })
    }

    redirectToTarget = () => {
        this.props.history.push('/leagues')
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, this.validateForm)
    }

    handleWeekChange = (selectedOption) => {
        this.setState({ scoringStart: selectedOption }, this.validateForm)
    }


    render() {
        const {
            gameWeekList,
            leagueName,
            minTeam,
            maxTeam,
            scoringStart,
            formValid,
            posting,
            // contextLocal
        } = this.state;
        return (
            <MyContext.Consumer>
                {(context) => (

                    <div className="container main-container">
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.createleague_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.createleague_description} />
                        </Helmet>
                        <Row className="m">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading">
                                    <div className="title">{context.state.locale.CreateLeagues.hero_title}</div>
                                    <div className="subtitle">{context.state.locale.CreateLeagues.description}</div>
                                </div>
                                <div className="create-league-form custom-bg">
                                    <Form>
                                        <div className="row">
                                            <div className="col-sm-12 p-sm">
                                                <FormGroup className='input-label-center label-left'
                                                    validationState={this.getValidationState('leagueName', leagueName)}>
                                                    <FloatingLabel id='leagueName'
                                                        name='leagueName'
                                                        placeholder={context.state.locale.CreateLeagues.league_name}
                                                        type='text'
                                                        maxLength={30}
                                                        required
                                                        value={leagueName}
                                                        onChange={this.onChange}
                                                        styles={inputStyle}
                                                    />
                                                    <span className="maxCharacter">{context.state.locale.CreateLeagues.max_characters}</span>
                                                </FormGroup>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 p-sm">
                                                <FormGroup
                                                    className='input-label-center label-left createLeagueSelect-field'
                                                    validationState={this.getValidationState('scoringStart', scoringStart)}>
                                                    <label className="createLeague-select-label">{context.state.locale.CreateLeagues.scoring_starts}</label>
                                                    <Select
                                                        className='select-field-transparent'
                                                        classNamePrefix='select'
                                                        placeholder={context.state.locale.CreateLeagues.select_placeholder}
                                                        isSearchable={false}
                                                        value={scoringStart}
                                                        onChange={this.handleWeekChange}
                                                        options={gameWeekList}>
                                                    </Select>
                                                </FormGroup>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 p-sm">
                                                <FormGroup className='input-label-center label-left'
                                                    validationState={this.getValidationState('minTeam', minTeam)}>
                                                    <FloatingLabel id='minTeam'
                                                        name='minTeam'
                                                        placeholder={context.state.locale.CreateLeagues.minimum_teams}
                                                        type='number'
                                                        required
                                                        value={minTeam}
                                                        min='0'
                                                        onChange={this.onChange}
                                                        styles={inputStyle}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 p-sm">
                                                <FormGroup className='input-label-center label-left'
                                                    validationState={this.getValidationState('maxTeam', maxTeam)}>
                                                    <FloatingLabel id='maxTeam'
                                                        name='maxTeam'
                                                        placeholder={context.state.locale.CreateLeagues.maximum_teams}
                                                        type='number'
                                                        required
                                                        min='0'
                                                        value={maxTeam}
                                                        onChange={this.onChange}
                                                        styles={inputStyle}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 text-center">
                                                <Button block bsStyle="primary" type="button" disabled={!formValid || posting} onClick={() => this.onSubmit(context)}>{context.state.locale.CreateLeagues.btn}</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>

                            </Col>
                        </Row>
                    </div>

                )}
            </MyContext.Consumer>
        )
    }
}