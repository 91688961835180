/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CollapsibleComponent, CollapsibleHead, CollapsibleContent } from 'react-collapsible-component'
import { Helmet } from "react-helmet";
import { MyContext } from '../context';
import * as qs from 'query-string';
export default class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        const { location } = this.props;
        const parsed = qs.parse(location.search);
        this.setState((state, props) => {
            return {
                isMobile: parsed.isMobile
            }
        });
    }
    render() {
        const {
            isMobile
        } = this.state;

        return (
            <MyContext.Consumer>
                {(context) => (
                    context.state.language == 'en' ? <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                        <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                            <title>{context.state.locale.MetaTag.faq_title}</title>
                            <meta name="description" content={context.state.locale.MetaTag.faq_description} />
                        </Helmet>
                        <Row className="">
                            <Col lg={9} className="auto-margin">
                                <div className="league-heading m">
                                    <div className="title">FREQUENTLY ASKED QUESTIONS</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} className="auto-margin">
                                <div className="static-page-wrap transparent no-padding">
                                    <CollapsibleComponent>
                                        {/* Start */}
                                        {/* <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            How can I create the AL Harrif league account?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Some users with Hotmail/Outlook addresses have reported issues with receiving emails due to them being blocked by spam filters. To resolve this you need to set the Premier League as a safe sender in your settings. To do this complete the following steps:</p>
                                            <p>Outlook.com (Formerly Hotmail)</p>
                                            <ul>
                                                <li>Open your Outlook mailbox.</li>
                                                <li>Select Options from the top right (next to the question mark).</li>
                                                <li>Select More options > Safe and blocked senders (under Preventing junk email) > Safe senders.</li>
                                                
                                                // <li>In the space provided, enter the address - noreply@mailout.users.premierleague.com</li>

                                                <li>Select Add to list.</li>
                                                <li>Ensure the safe mailing lists box has the address you entered, and select OK.</li>
                                            </ul>
                                            <p>Once this has been completed, please go back to the AL Harrif league site and follow the 'Forgot your password?' steps again</p>
                                            <p>You should find that the email is then sent to your inbox. Please note that this can take up to 10 minutes to come through.</p>
                                        </CollapsibleContent> */}
                                        {/* END */}

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            I can’t sign in, What do I need to do?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Please ensure that you are using your registered e-mail address and the correct password. Note that the password is case sensitive.</p>

                                            <p>If you’ve registered for the first time this season, you will need to activate your account before playing Kooora Fantasy Fantasy. An email will be sent to the email address you’ve registered with containing a link that you’ll need to click on in order to activate your account. If you haven’t received an email, please check your spam or junk mail folders.</p>

                                            <p>Try resetting your password. A temporary password will be sent to your mailbox. Please update your password once logged in.</p>


                                        </CollapsibleContent>


                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Can I have more than 1 team?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>In the interest of fair play each person may only enter one team. You may enter this team in multiple leagues and compete against different groups of friends.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Can I make changes in my squad after creating my team ?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Yes, unlimited free transfers can be made before your first deadline.</p>
                                        </CollapsibleContent>


                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            In What formation I can play?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>You can play in any formation, providing that 1 goalkeeper, at least 3 defenders and at least 1 forward are selected at all times.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            How do I change my captain?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>On the My Team page, use the menu which appears when clicking on a player's shirt.</p>
                                        </CollapsibleContent>


                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            How often the boosters can be used ?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>The Bench Boost and Triple Captain chips can each be used once a season and are played when saving your team on the my team page. They can be cancelled at any time before the Gameweek deadline.</p>

                                            <p>The Free Hit Chip can be used once a season and can’t be cancelled once played.</p>

                                            <p>The Wildcard chip can be used twice a season, once in the first half of the season and once in the second half of the season. The Wildcard chip is played when confirming transfers that cost points and can't be cancelled once played.</p>

                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Can I use more than 1 booster in the Gameweek?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>No, only one chip may be active in a Gameweek. For example, it is not possible to make transfers with your Wildcard chip and then use your Bench Boost chip in the same Gameweek.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            What happens to my triple captain if my captain doesn’t play ?                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>The triple points bonus will be passed to your vice-captain. If your vice-captain doesn't play either then the bonus is lost, the chip isn't returned.</p>

                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            How can I join the a private league?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Once you have a team, make sure that you have the correct private league code then click on Leagues, join private league and enter the code.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            How can I view other teams in my league ?                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Other teams can only be viewed after their first Gameweek has started, to allow managers to keep their team tactics secret.</p>

                                            <p>To view a team, simply click on their team name in the league table.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Is there any limit on the number of teams in the private league ?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>No, the more the merrier. However, only 50 teams will be shown at once, so you will have to use the previous/next links to scroll through your league table.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            If I don’t use the free transfers, do I get 2 in the next week ?                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>If you do not use your free transfers, you won’t be granted any additional free transfers in the next gameweek.</p>

                                        </CollapsibleContent>


                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Can I cancel my transfers ?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>No. Once you have confirmed your transfers, they are final and can’t be reversed under any circumstances.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            Transfer doesn’t seems costing me any points?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Transfer points are deducted at the start of the next Gameweek.</p>

                                            <p>Transfers are free of charge before the start of the season. If you join the game after the start of the season, transfers are free until your first deadline.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            What is the Wildcard?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>A wildcard allows you to make unlimited free transfers throughout a Gameweek. Playing a wildcard will remove any points deductions from transfers already made in the same Gameweek.</p>
                                            <p>You play a wildcard when you want to make multiple changes to your team, for example Gameweeks when teams are playing more than once, or when your team is underperforming or has a lot of injuries.</p>
                                            <p>When using a wildcard, you must remain within your current budget. There is no unlimited budget when using a wildcard.</p>
                                        </CollapsibleContent>

                                        <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            What happen if a team does not have a fixture in a gameweek ?
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>Any players you have from that team will score 0 for that Gameweek. Players will play twice in a future Gameweek, when the match is rescheduled.</p>
                                            <p>Points are only awarded to teams who have players selected in their team in the Gameweek the fixture is actually played, irrespective of if you had them selected for the original fixture.</p>
                                        </CollapsibleContent>
                                    </CollapsibleComponent>
                                </div>
                            </Col>
                        </Row>
                    </div>
                        :
                        <div className={`container main-container ${isMobile ? 'no-margin-t' : ''}`}>
                            <Helmet titleTemplate={`%s | ${context.state.locale.MetaTag.metatag_template}`}>
                                <title>{context.state.locale.MetaTag.faq_title}</title>
                                <meta name="description" content={context.state.locale.MetaTag.faq_description} />
                            </Helmet>
                            <Row className="">
                                <Col lg={9} className="auto-margin">
                                    <div className="league-heading m">
                                        <div className="title">أسئلة متكررة</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={9} className="auto-margin">
                                    <div className="static-page-wrap transparent no-padding">
                                        <CollapsibleComponent>
                                            {/* Start */}
                                            {/* <CollapsibleHead className="collapsible-head">
                                            <i className="icon-nextArrow"></i>
                                            1.	كيف يمكنني إنشاء حساب للدوري السعودي؟
                                        </CollapsibleHead>
                                        <CollapsibleContent className="collapsible-content">
                                            <p>قد أبلغنا بعض المستخدمين الذين لديهم عنوان بريد على هوتميل/آوتلوك بمشاكل في استقبال البريد الإلكتروني حيث أنه يتم اعتراضهم عن طريق فلتر البريد المزعج (السبام). لكي تحل هذه المشكلة، سوف تحتاج لإضافة 'الدوري الممتاز' إلى قائمة المرسلين الموثوق بهم في إعداداتك. لكي تقوم بذلك، اتبع هذه الخطوات:</p>
                                            <p>آوتلوك (هوتميل سابقًا)</p>
                                            <ul>
                                                <li>افتح صندوق البريد الخاص بك على آوتلوك.</li>
                                                <li>انقر على 'الإعدادات' في الأعلى على اليمين (بجانب علامة الاستفهام).</li>
                                                <li>انقر على 'المزيد من الإعدادات' > 'قائمة المرسلين الموثوق بهم والممنوعين' (تحت بند منع البريد</li>

                                                // <li>In the space provided, enter the address - noreply@mailout.users.premierleague.com</li>
                                               
                                                <li>•	تأكد من وجود العنوان الذي أدخلته في صندوق قائمة المرسلين الموثوق بهم ثم انقر على ‘التأكيد'.</li>
                                            </ul>
                                            <p>فور إتمام ذلك، من فضلك عد إلى موقع الدوري السعودي واتبع خطوات 'نسيت كلمة المرور؟' مجددًا.</p>
                                            <p>سوف تجد أن البريد الإلكتروني قد وصل الآن إلى علبة الوارد الخاصة بك. من فضلك لاحظ أنه من الممكن أن يستغرق ذلك 10 دقائق.</p>
                                        </CollapsibleContent> */}
                                            {/* END */}

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                1. لا يمكنني تسجيل الدخول؟
                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>من فضلك تأكد من استخدامك لعنوان البريد إلكتروني وكلمة المرور الصحيحة. ان كلمة المرور حساسة لحالة الحرف.</p>

                                                <p>إذا قمت بالتسجيل لأول مرة هذا الموسم ستحتاج إلى تفعيل حسابك قبل إدخال فريق في دوري كووورة فانتازي. سيتم إرسال بريد الكتروني إلى العنوان الذي سجلته وسيحتوي على رابط لتعفيل حسابك. إذا لم تستقبل بريد إلكتروني، من فضلك تحقق من وجوده في البريد المزعج (Spam) أو البريد الإلكتروني العشوائي. (Junk)</p>

                                                <p>حاول إعادة ضبط كلمة المرور. سوف يتم ارسال كلمة مرور مؤقتة إلى بريدك الالكتروني. من فضلك قم بتحديث كلمة المرور فور تسجيل الدخول.</p>

                                                <p>إذا لم تتلقى تذكرة لكلمة المرور، من فضلك تحقق من البريد الالكتروني العشوائي في الوارد الخاصة بك.</p>
                                            </CollapsibleContent>


                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                2. هل يمكن أن يكون لديَ أكثر من فريق واحد؟                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>لدواعي اللعب العادل، كل شخص يحق له تقديم فريق واحد فقط. يمكنك تقديم هذا الفريق في دوريات مختلفة والمنافسة مع مجموعات مختلفة من الأصدقاء.</p>                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                3. هل يمكنني أن أقوم بتعديلات في فريقي بعد انشاء فريقي ؟                                     </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>نعم، هناك عدد غير محدود من الانتقالات المتاحة لك قبل الموعد النهائي لجولتك الاولى. </p>                                            </CollapsibleContent>


                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                4. أي تشكيلة يمكنني أن ألعب بها؟                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>يمكنك اللعب بالتشكيلة التي تفضلها طالما هناك حارس واحد، وثلاثة مدافعين ومهاجم واحد كحد أدنى في كل الأوقات.</p>                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                5. كيف يمكنني تغيير قائد الفريق؟                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>على صفحة 'فريقي'، استخدم القائمة التي تظهر عندما تنقر على قميص اللاعب.</p>                                            </CollapsibleContent>


                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                6. كم مرة يمكنني استخدام 'بطاقة تعزيز'؟ </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>يمكن استخدام بطاقة 'الاوراق الرابحة' أو 'تريبل كابتن' مرة واحدة فقط في الموسم، ويمكنك استخدام هذه البطاقات عندما تقوم بتسجيل فريقك في صفحة 'فريقي'. ويمكن أن يتم إلغائهم في أي وقت قبل الموعد النهائي للجولة.</p>
                                                <p>بطاقة 'اضرب بكل قوتك!' يمكن استخدامها مرة واحدة فقط، وهي تلعب عند تأكيد الانتقالات ولا يمكن أن تلغى بعد التأكيد.</p>
                                                <p>يمكنك استخدام بطاقة 'وايلد كارد' مرتان في الموسم الواحد، مرة في النصف الأول من الموسم، ومرة أخرى في النصف الثاني. تلعب 'وايلد كارد' عند تأكيد الانتقالات التي تكلف نقاط ولا يمكن أن تلغى بعد التأكيد.</p>
                                                {/* <ul className="no-list">
                                                    <li>	•	هل يمكنني استخدام أكثر من 'بطاقة تعزيز' في نفس الجولة؟</li>
                                                </ul>
                                                <p>يمكنك استخدام بطاقة واحدة فقط في الجولة. على سبيل المثال، لا يمكنك إجراء انتقالات باستخدام 'وايلد كارد' ثم استخدام بطاقة 'الاوراق الرابحة' في نفس الجولة</p>
                                                <ul className="no-list">
                                                    <li>	•	ما هي بطاقة "وايلد كارد"؟</li>
                                                    <li>" وايلد كارد" تتيح لك انتقالات غير محدودة خلال الجولة. استخدام بطاقة " وايلد كارد" سوف تلغي أي نقاط مستقطعة من الانتقالات في نفس الجولة.</li>
                                                </ul>
                                                <p>يمكنك لعب بطاقة " وايلد كارد"  عندما ترغب في القيام بتغييرات مختلفة في فريقك، على سبيل المثال، الجولات التي يلعب فيها الفرق أكثر من مرة، أو عندما تجد أن فريقك لا يؤدي بشكل جيد</p>
                                                <p>عند استخدام بطاقة " وايلد كارد"، يجب عليك الالتزام بميزانيتك الحالية. لا توجد ميزانية غير محدودة عند استخدام بطاقة " وايلد كارد".</p> */}
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                7. هل يمكنني استخدام أكثر من 'بطاقة تعزيز' في نفس الجولة؟
                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>يمكنك استخدام بطاقة واحدة فقط في الجولة. على سبيل المثال، لا يمكنك إجراء انتقالات باستخدام 'وايلد كارد' ثم استخدام بطاقة 'الاوراق الرابحة' في نفس الجولة.</p>                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                8. ماذا يحصل لنقاط قائد الفريق اذا لم يلعب في الجولة؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>اذا لم يشارك قائد الفريق في الجولة تحتسب النقاط المضاعفة لنائب القائد. واذا لم يلعب نائب القائد لا يتم احتساب أي نقاط.</p>
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                9. كيف يمكنني الانضمام الى دوري خاص؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>يمكنك الانضمام الى دوري خاص من خلال صفحة الدوريات. اضغط على "الانضمام الى دوري خاص" وادخل رمز الدوري وتمتع بمنافسة اصدقائك.</p>   </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                10. كيف يمكنني رؤية الفرق الأخرى في الدوري الخاص ؟
                                        </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>لا يمكن رؤية الفرق الأخرى قبل بداية الجولة الأولى حتى نسمح بالحفاظ على سرية تكتيكات الفريق.</p>
                                                <p>لرؤية الفريق، فقط انقر على اسم الفريق في جدول الدوري بعد بداية الجولة الاولى.</p>
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                11. هل هناك حد لعدد الفرق في الدوري الخاص؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>لا.، ولكن لن يتم اظهار إلا ٥٠ فريق في نفس الوقت، ولذا ستحتاج إلى استخدام روابط السابق او التالي حتى ترى بقية جدول الدوري.</p>
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                12. إذا لم أستخدم الانتقالات المجانية، هل سوف أحصل على  انتقالت إضافية في الجولة القادمة؟                                      </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>إذا لم تستخدم الانتقالات المجانية، فانك لن تحصل على انتقالات مجانية إضافية في الجولة القادمة.</p>
                                            </CollapsibleContent>


                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                13. هل يمكنني إلغاء انتقالاتي؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>لا، تصبح الإنتقالات نهائية فور تأكيدها ولا يمكن الرجوع فيها تحت أي ظرف.</p>
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                14. لا يبدو أن الانتقالات تكلفني أي نقاط؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p>نقاط الإنتقالات يتم استقطاعها في بداية الجولة التالية. الإنتقالات مجانية قبل بداية الموسم. إذا انضممت إلى اللعبة بعد بداية الموسم، ستكون كل الإنتقالات مجانية حتى أول موعد نهائي يمر عليك.</p>
                                            </CollapsibleContent>
                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                15. ما هي بطاقة "وايلد كارد"؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">
                                                <p> " وايلد كارد" تتيح لك انتقالات غير محدودة خلال الجولة. استخدام بطاقة " وايلد كارد" سوف تلغي أي نقاط مستقطعة من الانتقالات في نفس الجولة</p>
                                                <p>
                                                    يمكنك لعب بطاقة " وايلد كارد"  عندما ترغب في القيام بتغييرات مختلفة في فريقك، على سبيل المثال، الجولات التي يلعب فيها الفرق أكثر من مرة، أو عندما تجد أن فريقك لا يؤدي بشكل جيد.</p>
                                                <p>
                                                    عند استخدام بطاقة " وايلد كارد"، يجب عليك الالتزام بميزانيتك الحالية. لا توجد ميزانية غير محدودة عند استخدام بطاقة " وايلد كارد".</p>
                                            </CollapsibleContent>

                                            <CollapsibleHead className="collapsible-head">
                                                <i className="icon-nextArrow"></i>
                                                16. ماذا يحدث عندما لا يلعب فريق في جولة معينة؟
                                            </CollapsibleHead>
                                            <CollapsibleContent className="collapsible-content">

                                                <b>أي لاعب لا يشارك في الجولة سوف لن  يحصل على نقاط، ولكن سيتم احتساب النقاط عندما يشارك اللاعب في مباراتان في جولة مستقبلية عندما يتم إعادة جدولة المباراة.</b>
                                                <b>يتم إعطاء النقاط فقط للفرق التي تملك لاعبين شاركوا في الجولة التي تم إعادة جدولة المباراة فيها، وليس بحسب إذا كان فريقك يحتوي على هؤلاء اللاعبين وقت الجولة الأصلية.</b>
                                            </CollapsibleContent>

                                        </CollapsibleComponent>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                )}
            </MyContext.Consumer>
        )
    }
}